import React from 'react';

import SectionHeader from 'shared/components/SectionHeader';

import styles from './SecuritySettings.module.scss';

export default function SecuritySettings() {
    const deletionText = 'Memories should be forever, so should rewwinds.';

    return (
        <div className={styles.settingsContainer}>
            <SectionHeader componentTag='h3' title='Security' />
            <div
                className={styles.settingsItem}
                style={{ borderTop: '1px solid #1a2133', marginTop: '20px' }}
            >
                <div className={styles.settingsControls}>
                    <h4>Delete account</h4>
                </div>
                <span className='notice notice__left'>{deletionText}</span>
            </div>
        </div>
    );
}
