import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { GiEmptyMetalBucketHandle } from 'react-icons/gi';

import { selectWords, thunkGetWords } from 'redux/slices/words';
import { thunkPageReset } from 'redux/slices/app';
import {
    selectCollectionsLoading,
    selectCollections,
    thunkGetCollections
} from 'redux/slices/collections';

import CollectionsList from './CollectionsList';
import Collection from './Collection';
import Loader from 'shared/components/Loader';
import NoData from 'shared/components/NoData';
import Header from 'components/Header';

import './Collections.scss';

const Collections = () => {
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectCollectionsLoading);
    const collections = useSelector(selectCollections);
    const words = useSelector(selectWords);

    useEffect(() => {
        dispatch(thunkPageReset('collections'));

        async function getCollections() {
            await dispatch(thunkGetWords());
            await dispatch(thunkGetCollections());
        }

        getCollections();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            <div className='collectionsContainer'>
                {isLoading ? (
                    <div className='collectionsLoaderContainer'>
                        <Loader />
                    </div>
                ) : (
                    <Switch>
                        <Route exact path={path}>
                            {collections.length > 0 ? (
                                <CollectionsList
                                    collections={collections}
                                    words={words}
                                />
                            ) : (
                                <NoData
                                    title='No collections added'
                                    subTitle='To get started, click the plus button at the top right corner'
                                    icon={<GiEmptyMetalBucketHandle />}
                                />
                            )}
                        </Route>
                        <Route path={`${path}/:collectionID`}>
                            <Collection
                                collections={collections}
                                words={words}
                            />
                        </Route>
                    </Switch>
                )}
            </div>
        </>
    );
};

export default Collections;
