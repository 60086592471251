import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { FiUser, FiBookmark, FiActivity, FiSearch } from 'react-icons/fi';
import { CgNotes } from 'react-icons/cg';
import { HiOutlineCollection } from 'react-icons/hi';
import { TbNotes } from 'react-icons/tb';
// import { GiLinkedRings } from 'react-icons/gi';
import {
    MdOutlineNewReleases,
    MdOutlineInfo,
    MdOutlineComment
} from 'react-icons/md';

import { selectAuthUser, selectUserInfo } from 'redux/slices/users';
import { selectWords } from 'redux/slices/words';
import { selectNotes } from 'redux/slices/notes';
import { selectCollections } from 'redux/slices/collections';
import * as ROUTES from 'constants/routes';

import SignOut from 'views/Authentication/SignOut';
import Tooltip from 'shared/components/Tooltip';
import Logo from 'shared/components/Logo';

import './Navigation.scss';

type LabelProps = {
    count?: number;
    navPath: string;
};

const Label = ({ count, navPath }: LabelProps) => {
    const { pathname } = useLocation();

    return (
        <>
            {count && count > 0 && pathname.includes(navPath) ? (
                <span className='navigationCount'>{count}</span>
            ) : null}
        </>
    );
};

const Navigation = () => {
    const userInfo = useSelector(selectUserInfo);
    const authUser = useSelector(selectAuthUser);
    const words = useSelector(selectWords);
    const notes = useSelector(selectNotes);
    const collections = useSelector(selectCollections);

    const [imageError, setImageError] = useState<boolean>(false);
    const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);
    const profileMenuRef = useRef<HTMLDivElement | null>(null);
    const profileImageRef = useRef<HTMLAnchorElement | null>(null);
    const handleImageError = () => {
        setImageError(true);
    };

    // TODO: Fix any
    const handleClickOutside = (e: any) => {
        if (
            profileImageRef.current &&
            profileImageRef.current.contains(e.target)
        ) {
            // do nothing and let onCLick handler take care of it
            return;
        } else {
            if (
                profileMenuRef.current &&
                profileMenuRef.current.contains(e.target)
            ) {
                // inside click
                return;
            }

            // outside click
            setShowProfileMenu(false);
        }
    };
    const imageToShow = () => {
        if (authUser?.photoURL !== null) {
            return userInfo?.profileImage !== null
                ? userInfo?.profileImage.imageURL
                : authUser?.photoURL;
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <nav className='navContainer'>
            <Logo size='small' />
            <ul className='mainNav'>
                <li>
                    <NavLink activeClassName='active' to={ROUTES.SEARCH}>
                        <FiSearch className='icon actionIcon' />
                        <Label navPath={ROUTES.SEARCH} />
                        <span className='navText'>Search</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName='active' to={ROUTES.ACTIVITY}>
                        <FiActivity className='icon actionIcon' />
                        <span className='newIndicator'>new</span>
                        <span className='navText'>Activity</span>
                    </NavLink>
                </li>
                {/* <li>
        <NavLink activeClassName='active' to={ROUTES.COMMUNITY}>
          <GiLinkedRings className='icon actionIcon' />
          <span className='newIndicator'>new</span>
        <span className='navText'>Community</span>
        </NavLink>
    </li> */}
                <li>
                    <NavLink activeClassName='active' to={ROUTES.WORDS}>
                        <FiBookmark className='icon actionIcon' />
                        <Label count={words.length} navPath={ROUTES.WORDS} />
                        <span className='navText'>Words</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName='active' to={ROUTES.COLLECTIONS}>
                        <HiOutlineCollection className='icon actionIcon' />
                        <Label
                            count={collections.length}
                            navPath={ROUTES.COLLECTIONS}
                        />
                        <span className='navText'>Collections</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName='active' to={ROUTES.NOTES}>
                        <TbNotes className='icon actionIcon' />
                        <Label count={notes.length} navPath={ROUTES.NOTES} />
                        <span className='navText'>Notes</span>
                    </NavLink>
                </li>
            </ul>
            <ul className='bottomNav'>
                <li>
                    <a
                        onClick={() => setShowProfileMenu(!showProfileMenu)}
                        ref={profileImageRef}
                    >
                        {imageError || authUser?.photoURL === null ? (
                            <FiUser className='icon actionIcon' />
                        ) : (
                            <img
                                src={imageToShow()}
                                onError={handleImageError}
                                className='profilePicture'
                                alt='Profile picture'
                            />
                        )}
                    </a>
                    {showProfileMenu && (
                        <div className='profileMenu' ref={profileMenuRef}>
                            <ul>
                                <li
                                    onClick={() =>
                                        setShowProfileMenu(!showProfileMenu)
                                    }
                                >
                                    <NavLink
                                        activeClassName='active'
                                        to={ROUTES.ACCOUNT}
                                    >
                                        <span>
                                            {imageError ||
                                            authUser?.photoURL === null ? (
                                                <FiUser className='icon actionIcon' />
                                            ) : (
                                                <img
                                                    src={imageToShow()}
                                                    onError={handleImageError}
                                                    className='profilePicture__small'
                                                    alt='Profile picture'
                                                />
                                            )}
                                            My Account
                                        </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <a
                                        href='https://www.rewwind.co'
                                        target='_blank'
                                        rel='noreferrer'
                                        onClick={() =>
                                            setShowProfileMenu(!showProfileMenu)
                                        }
                                    >
                                        <span>
                                            <MdOutlineInfo className='icon actionIcon' />
                                            About Rewwind
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        // href='https://docs.google.com/forms/d/e/1FAIpQLSfQnzyEoxx8vUH6Uu-KOqeVDxoiDENNOhwFnL5pvIzDLJrt4Q/viewform'
                                        href='https://feedback.rewwind.co/feature-requests'
                                        target='_blank'
                                        rel='noreferrer'
                                        onClick={() =>
                                            setShowProfileMenu(!showProfileMenu)
                                        }
                                    >
                                        <span>
                                            <MdOutlineComment className='icon actionIcon' />
                                            Feedback
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://feedback.rewwind.co/changelog'
                                        target='_blank'
                                        rel='noreferrer'
                                        onClick={() =>
                                            setShowProfileMenu(!showProfileMenu)
                                        }
                                    >
                                        <span>
                                            <MdOutlineNewReleases className='icon actionIcon' />
                                            Whats&apos;s new?
                                        </span>
                                    </a>
                                </li>
                            </ul>
                            <span className='profileMenu__footer'>
                                &copy; 2023{' '}
                                <a
                                    href='https://www.linkedin.com/in/ayomide-onalaja-5a1469113/'
                                    target='_blank'
                                    rel='noreferrer'
                                    onClick={() =>
                                        setShowProfileMenu(!showProfileMenu)
                                    }
                                >
                                    Ayomide Onalaja
                                </a>
                                &nbsp;
                                <span className='emoji'>
                                    &#127475;&#127468;
                                </span>
                            </span>
                        </div>
                    )}
                </li>
                <li>
                    <SignOut />
                </li>
            </ul>
        </nav>
    );
};

export default Navigation;
