import 'firebase/auth';
import 'firebase/firestore';
import { toast } from 'react-toastify';
import {
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject
} from 'firebase/storage';
import { gql } from '@apollo/client';

import { storage } from '../index';

export async function apiUpdateCollectionImage(
    userID: string,
    newImageFile: any,
    collectionID: string,
    oldImageFileName = ''
) {
    return new Promise((resolve, reject) => {
        const storageRef = ref(storage);
        const userRef = ref(storageRef, userID);
        const userCollectionRef = ref(userRef, 'collections');
        const userCollectionIDRef = ref(
            userCollectionRef,
            `${collectionID}/${newImageFile.name}`
        );
        const uploadTask = uploadBytesResumable(
            userCollectionIDRef,
            newImageFile
        );

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        // console.log('Upload is paused');
                        break;
                    case 'running':
                        // console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                reject(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref)
                    .then((downloadURL) => {
                        resolve(downloadURL);
                    })
                    .then(() => {
                        if (oldImageFileName !== '') {
                            const oldCollectionImageRef = ref(
                                userCollectionRef,
                                `${collectionID}/${oldImageFileName}`
                            );

                            deleteObject(oldCollectionImageRef)
                                .then(() => resolve(true))
                                .catch((error) => {
                                    reject(error);
                                    throw new Error(error);
                                });
                        }
                    })
                    .catch((err) => {
                        toast.error('Error uploading image');
                        reject(err);
                    });
            }
        );
    });
}

export async function apiDeleteUserCollectionImage(
    userID: string,
    collectionID: string,
    imageName: any
) {
    return new Promise((resolve, reject) => {
        const storageRef = ref(storage);
        const userRef = ref(storageRef, userID);
        const userCollectionRef = ref(userRef, 'collections');
        const oldCollectionImageRef = ref(
            userCollectionRef,
            `${collectionID}/${imageName}`
        );

        deleteObject(oldCollectionImageRef)
            .then(() => resolve(true))
            .catch((error) => {
                reject(error);
            });
    });
}

export const MUTATION_ADD_USER_COLLECTION = gql`
    mutation addUserCollection($collection: CollectionInput!, $userID: ID!) {
        addUserCollection(collection: $collection, userID: $userID)
    }
`;

export const QUERY_GET_USER_COLLECTIONS = gql`
    query getUserCollections($userID: ID!) {
        getUserCollections(userID: $userID) {
            collectionID
            name
            description
            words
            archived
            collectionImage {
                name
                size
                type
                imageURL
            }
            dateAdded
        }
    }
`;

export const MUTATION_UPDATE_USER_COLLECTION = gql`
    mutation updateUserCollection(
        $updatedCollection: CollectionInput!
        $userID: ID!
    ) {
        updateUserCollection(
            updatedCollection: $updatedCollection
            userID: $userID
        )
    }
`;

export const MUTATION_DELETE_USER_COLLECTION = gql`
    mutation deleteUserCollection(
        $deletedCollection: CollectionInput!
        $userID: ID!
    ) {
        deleteUserCollection(
            deletedCollection: $deletedCollection
            userID: $userID
        )
    }
`;
