import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GiBookmarklet } from 'react-icons/gi';
import { IoIosArrowDown } from 'react-icons/io';
import { BiSelectMultiple } from 'react-icons/bi';
import { FiCornerUpLeft, FiTrash2, FiX, FiCheck } from 'react-icons/fi';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { VscArchive } from 'react-icons/vsc';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import { thunkUpdateUserWords, thunkDeleteUserWords } from 'redux/slices/words';

import WordListItem from '../WordListItem';
import WordSort from '../WordSort';
import Loader from 'shared/components/Loader';
import SmallLoader from 'shared/components/SmallLoader';
import NoData from 'shared/components/NoData';
import Dropdown from 'shared/components/Dropdown';
import Modal from 'shared/components/Modal';
import Button from 'shared/components/Button';

import { sortWordsHelper } from 'helpers/wordsHelper';

import { DropdownOption } from 'shared/components/Dropdown/Dropdown';

import { WordSortType } from '../WordSort/WordSort';
import { Word } from 'types/word';
import { Collection } from 'types/collection';

import styles from './WordList.module.scss';

type Props = {
    words: Word[];
    section?: string;
    collection?: Collection;
    isLoading?: boolean;
};

const WordList = ({ words, isLoading, section, collection }: Props) => {
    const dispatch = useDispatch();
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const { pathname } = useLocation();
    const [sortedWords, setSortedWords] = useState<Word[]>([]);
    const [currentSortType, setCurrentSortType] =
        useState<WordSortType>('dateAsc');
    const [wordsLimit, setWordsLimit] = useState<number>(20);
    const [showArchives, setShowArchives] = useState<boolean>(false);
    const [isMultiSelectEnabled, setIsMultiSelectEnabled] =
        useState<boolean>(false);
    const [selectedWords, setSelectedWords] = useState<Word[]>([]);
    const [showSelectedOptions, setShowSelectedOptions] =
        useState<boolean>(false);
    const [isWordsLoading, setIsWordsLoading] = useState<boolean>(false);
    const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' });

    const hasOnlyArchives = words.every((word) => word.archived === true);

    const handleSort = (type: WordSortType) => {
        setCurrentSortType(type);
    };

    const handleShowArchives = (shouldShow: boolean) => {
        setShowArchives(shouldShow);
        setSelectedWords([]);
        setShowSelectedOptions(false);
    };

    const handleSeeMore = () => {
        setIsWordsLoading(true);

        const timer = setTimeout(() => {
            setWordsLimit((state) => state + 20);
            setIsWordsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    };

    const isCollectionsPage = pathname.includes('/collections');

    const hasWords = words.length > 0;

    const wordsCount = isMobile
        ? `${
              sortedWords.length > wordsLimit ? wordsLimit : sortedWords.length
          } of ${words.length}`
        : `Showing ${
              sortedWords.length > wordsLimit ? wordsLimit : sortedWords.length
          } of ${words.length}`;

    const handleMultiselectClick = () => {
        setIsMultiSelectEnabled(!isMultiSelectEnabled);
        setSelectedWords([]);
        setShowSelectedOptions(false);
    };

    const handleOnSelect = (word: Word) => {
        const selectedWordIndex = selectedWords.findIndex(
            (item: Word) => item.word === word.word
        );

        if (selectedWordIndex >= 0) {
            setSelectedWords((state) =>
                state.filter((item: Word) => item.word !== word.word)
            );
        } else {
            setSelectedWords((state) => [...state, word]);
        }
    };
    // const handleAddToCollection = () => {};
    // const handleRemoveFromCollection = () => {};

    const handleUpdateWord = async (key: keyof Word, value: boolean) => {
        isModalOpen && setIsModalOpen(false);
        setIsOptionsLoading(true);

        const wordsToUpdate = selectedWords.map((word) => ({
            ...word,
            [key]: value
        }));

        await dispatch(thunkUpdateUserWords(wordsToUpdate));

        setSelectedWords([]);
        setShowSelectedOptions(false);
        setIsOptionsLoading(false);
    };

    const handleDelete = async () => {
        setIsOptionsLoading(true);
        const wordsToDelete = selectedWords.map((item: Word) => item.word);

        await dispatch(thunkDeleteUserWords(wordsToDelete));

        setSelectedWords([]);
        setShowSelectedOptions(false);
        setIsOptionsLoading(false);
        setIsModalOpen(false);
    };

    const areWordsArchived = selectedWords.every((item: Word) => item.archived);

    const areWordsFavorites = selectedWords.every(
        (item: Word) => item.favorite
    );

    const selectedOptions: DropdownOption[] = [
        // TODO: Add functionality for bulk ADDING of words to collections
        // {
        //     name: isCollectionsPage ? '' : '',
        //     icon: isCollectionsPage ? (
        //         <BsFolderMinus className='icon actionIcon' />
        //     ) : (
        //         <BsFolderPlus className='icon actionIcon' />
        //     ),
        //     onClick: isCollectionsPage
        //         ? handleRemoveFromCollection
        //         : handleAddToCollection
        // },
        {
            name: areWordsFavorites ? 'Unfavorite' : 'Favorite',
            icon: areWordsFavorites ? (
                <AiFillStar
                    className='icon actionIcon__small'
                    style={{ fill: '#ffc683' }}
                />
            ) : (
                <AiOutlineStar className='icon actionIcon__small' />
            ),
            onClick: areWordsFavorites
                ? () => handleUpdateWord('favorite', false)
                : () => handleUpdateWord('favorite', true)
        },
        {
            name: areWordsArchived ? 'Unarchive' : 'Archive',
            icon: areWordsArchived ? (
                <FiCornerUpLeft className='icon actionIcon__small' />
            ) : (
                <VscArchive className='icon actionIcon__small' />
            ),
            onClick: areWordsArchived
                ? () => handleUpdateWord('archived', false)
                : () => handleUpdateWord('archived', true)
        },
        {
            name: 'Delete',
            icon: <FiTrash2 className='icon actionIcon__small dangerIcon' />,
            onClick: () => {
                setIsModalOpen(true);
            }
        }
    ];
    const sortItemActiveClass = classNames('sortItem', {
        sortItem__active: isMultiSelectEnabled
    });

    const sortTypeClass = classNames('sortType', {
        sortType__active: isMultiSelectEnabled
    });

    const renderWords = () => {
        return hasOnlyArchives && !showArchives ? (
            <NoData
                title='All your words are archived'
                subTitle='Click the archive icon above to view them'
                icon={<VscArchive />}
            />
        ) : (
            <div className={styles.wordsList}>
                {sortedWords.slice(0, wordsLimit).map((word) => (
                    <WordListItem
                        key={word.word}
                        word={word}
                        section={section}
                        activeCollection={collection}
                        isMultiSelectEnabled={isMultiSelectEnabled}
                        onSelect={handleOnSelect}
                        isSelected={selectedWords.some(
                            (item) => item.word === word.word
                        )}
                        showDetails
                    />
                ))}
            </div>
        );
    };
    const handleModalClose = () => {
        setIsOptionsLoading(false);
        setIsModalOpen(false);
    };

    useEffect(() => {
        let newWords;
        setCurrentSortType(currentSortType);

        if (showArchives) {
            const archivedWords = words.filter((word) => word.archived);

            if (archivedWords.length > 0) {
                newWords = sortWordsHelper(currentSortType, archivedWords);
            } else {
                const nonArchivedWords = words.filter((word) => !word.archived);
                newWords = sortWordsHelper(currentSortType, nonArchivedWords);
                setShowArchives(false);
            }
        } else {
            const nonArchivedWords = words.filter((word) => !word.archived);
            newWords = sortWordsHelper(currentSortType, nonArchivedWords);
        }

        setSortedWords(newWords);
    }, [words, currentSortType, showArchives]);

    useEffect(() => {
        if (selectedWords.length > 0) {
            !showSelectedOptions && setShowSelectedOptions(true);
        } else {
            setShowSelectedOptions(false);
        }
    }, [selectedWords, showSelectedOptions]);

    if (isLoading) {
        return (
            <div className={styles.wordsLoaderContainer}>
                <Loader />
            </div>
        );
    }

    if (hasWords) {
        return (
            <>
                <div className={styles.wordsListControls}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative'
                        }}
                    >
                        <WordSort
                            words={words}
                            onShowArchivedNotes={handleShowArchives}
                            onSort={handleSort}
                        />
                        <div className='sortContainer'>
                            <div
                                className={
                                    isMultiSelectEnabled
                                        ? sortTypeClass
                                        : 'sortType'
                                }
                            >
                                <div
                                    className={
                                        isMultiSelectEnabled
                                            ? sortItemActiveClass
                                            : 'sortItem'
                                    }
                                    onClick={handleMultiselectClick}
                                >
                                    {isOptionsLoading ? (
                                        <SmallLoader />
                                    ) : (
                                        <BiSelectMultiple />
                                    )}
                                </div>
                                {/* {selectedWords.length > 0 && (
                                    <>
                                        {!showSelectedOptions ? (
                                        <IoIosArrowDown
                                            className='icon actionIcon'
                                            style={{ marginLeft: '5px' }}
                                            onClick={() =>
                                            setShowSelectedOptions(!showSelectedOptions)
                                            }
                                        />
                                        ) : (
                                        <IoIosArrowUp
                                            className='icon actionIcon'
                                            style={{ marginLeft: '5px' }}
                                            onClick={() =>
                                            setShowSelectedOptions(!showSelectedOptions)
                                            }
                                        />
                                        )}
                                    </>
                                )} */}
                                {isMultiSelectEnabled && (
                                    <span className={styles.selectedIndicator}>
                                        {selectedWords.length}
                                    </span>
                                )}
                            </div>
                            {showSelectedOptions && (
                                <div
                                    style={{
                                        position: 'relative',
                                        marginTop: '-15px'
                                    }}
                                >
                                    <Dropdown
                                        options={selectedOptions}
                                        ref={dropdownRef}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <span className={styles.wordsCount}>{wordsCount}</span>
                </div>
                {renderWords()}
                {wordsLimit < sortedWords.length && (
                    <div className={styles.viewMoreContainer}>
                        {isWordsLoading ? (
                            <SmallLoader />
                        ) : (
                            <p onClick={handleSeeMore}>
                                View more <br />
                                <IoIosArrowDown className='icon' />
                            </p>
                        )}
                    </div>
                )}
                <Modal
                    show={isModalOpen}
                    onClose={handleModalClose}
                    title='Delete Words'
                >
                    <h4>Are you sure you want to delete these words?</h4>
                    <p>
                        Once deleted, you won&apos;t be able to retrieve them
                        and the words will have to be re-added.
                    </p>
                    <p>
                        All rewwinds associated with these words will also be
                        deleted.
                    </p>
                    {!areWordsArchived && (
                        <p>
                            You can also{' '}
                            <a
                                onClick={() =>
                                    handleUpdateWord('archived', true)
                                }
                            >
                                archive
                            </a>{' '}
                            these words.{' '}
                        </p>
                    )}
                    <div className='modalFooter'>
                        <Button
                            buttonCategory='icon'
                            buttonDisabled={isOptionsLoading}
                        >
                            {isOptionsLoading ? (
                                <SmallLoader />
                            ) : (
                                <FiCheck
                                    className='icon buttonIcon'
                                    onClick={handleDelete}
                                />
                            )}
                        </Button>
                        <Button
                            buttonCategory='iconAlternate'
                            onClick={handleModalClose}
                            buttonDisabled={isOptionsLoading}
                        >
                            <FiX className='icon buttonIcon' />
                        </Button>
                    </div>
                </Modal>
            </>
        );
    } else {
        return (
            <NoData
                title='No words added'
                subTitle='Search for a word to add one'
                icon={<GiBookmarklet />}
            />
        );
    }
};

export default WordList;
