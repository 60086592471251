import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiCheck, FiInfo } from 'react-icons/fi';
import { isEqual } from 'lodash';

import { thunkUpdateSettings, selectUserSettings } from 'redux/slices/users';
import {
    VOICES_GB,
    VOICES_US,
    LANGUAGES,
    WORD_SHARE_PERMISSIONS
} from 'constants/wordsConstants';

import Picker from 'shared/components/Picker';
import Slider from 'shared/components/Slider';
import WordOptions from 'views/Words/WordOptions';
import Button from 'shared/components/Button';
import SectionHeader from 'shared/components/SectionHeader';
import Tooltip from 'shared/components/Tooltip';

import { UserSettings } from 'types/user';
import {
    LanguageType,
    WordPermissionsType,
    PermissionOptions,
    DefaultVoiceType
} from 'types/settings';
import { Word } from 'types/word';

import styles from './WordsSettings.module.scss';

function WordsSettings() {
    const dispatch = useDispatch();
    const { defaultVoice, language, speechSpeed, wordPermissions } =
        useSelector(selectUserSettings);
    const [newSettings, setNewSettings] = useState({
        defaultVoice,
        language,
        speechSpeed,
        wordPermissions
    });
    const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

    // LANGUAGE
    // https://dev.to/jorik/country-code-to-flag-emoji-a21
    const getFlagEmoji = (countryCode: string) => {
        const codePoints = countryCode
            .toUpperCase()
            .split('')
            .map((char: string) => 127397 + char.charCodeAt(0));
        return String.fromCodePoint(...codePoints);
    };
    const languagePickerItems = LANGUAGES.map((item: LanguageType) => {
        return {
            ...item,
            display: getFlagEmoji(item.language)
        };
    });
    const handleLanguageChange = useCallback((value: LanguageType) => {
        const newVoice = value.code === 'en-us' ? VOICES_US[0] : VOICES_GB[0];

        setNewSettings((state) => ({
            ...state,
            defaultVoice: { id: newVoice.toLowerCase(), name: newVoice },
            language: { code: value.code, language: value.language }
        }));
    }, []);

    // VOICE
    const determineVoices = () => {
        return newSettings.language?.code === 'en-us' ? VOICES_US : VOICES_GB;
    };
    const voicePickerItems = determineVoices().map((voice: string) => {
        return {
            id: voice.toLowerCase() as string,
            name: voice,
            display: voice
        };
    });
    const handleVoiceChange = useCallback((value: DefaultVoiceType) => {
        setNewSettings((state) => ({
            ...state,
            defaultVoice: { id: value.id, name: value.name }
        }));
    }, []);

    // SPEECH SPEED
    const handleSpeechChange = (value: number) => {
        setNewSettings((state) => ({
            ...state,
            speechSpeed: { speed: value }
        }));
    };

    // WORD PERMISSIONS
    const wordPermissionsPickerItems = WORD_SHARE_PERMISSIONS.map(
        (permission: string) => {
            return {
                id: permission.toLowerCase() as PermissionOptions,
                display: permission
            };
        }
    );
    const handleWordPermissionsChange = (value: WordPermissionsType) => {
        setNewSettings((state) => ({
            ...state,
            wordPermissions: { id: value.id }
        }));
    };

    // SAVE
    const isSaveDisabled = () => {
        return (
            isEqual(
                { defaultVoice, language, speechSpeed, wordPermissions },
                newSettings
            ) || isSaveLoading
        );
    };
    const handleSave = async () => {
        setIsSaveLoading(true);
        await dispatch(thunkUpdateSettings(newSettings as UserSettings, true));
        setIsSaveLoading(false);
    };

    useEffect(() => {
        setNewSettings({
            defaultVoice,
            language,
            speechSpeed,
            wordPermissions
        });
    }, [defaultVoice, language, speechSpeed, wordPermissions]);

    return (
        <div className={styles.settingsContainer}>
            <SectionHeader
                componentTag='h3'
                title='Words'
                renderRight={() => (
                    <Button
                        buttonDisabled={isSaveDisabled()}
                        buttonCategory='icon'
                        buttonSize='small'
                        isLoading={isSaveLoading}
                        onClick={handleSave}
                    >
                        <FiCheck className='icon buttonIcon' />
                    </Button>
                )}
            />
            <div
                className={styles.settingsItem}
                style={{ borderTop: '1px solid #1a2133', marginTop: '20px' }}
            >
                <div className={styles.settingsControls}>
                    <h4>Speech language</h4>
                    <div>
                        <Picker
                            pickerItems={languagePickerItems}
                            selectedItem={newSettings.language}
                            idProperty='code'
                            isEmoji={true}
                            onChange={handleLanguageChange}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.settingsItem}>
                <div className={styles.settingsControls}>
                    <h4>Default voice</h4>
                    <div className={styles.voicePicker}>
                        <WordOptions
                            word={{ word: 'Rewind' } as Word}
                            showSearch={false}
                            voice={newSettings.defaultVoice?.name}
                            speed={newSettings.speechSpeed?.speed}
                            lang={newSettings.language?.code}
                        />
                        <Picker
                            pickerItems={voicePickerItems}
                            selectedItem={newSettings.defaultVoice}
                            onChange={handleVoiceChange}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.settingsItem}>
                <div className={styles.settingsControls}>
                    <h4>Speech speed</h4>
                    <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: '5px' }}>
                            <WordOptions
                                word={{ word: 'Rewind' } as Word}
                                showSearch={false}
                                voice={newSettings.defaultVoice?.name}
                                speed={newSettings.speechSpeed?.speed}
                                lang={newSettings.language?.code}
                            />
                        </span>
                        <Slider
                            min={-10}
                            max={10}
                            value={newSettings.speechSpeed?.speed}
                            onChange={handleSpeechChange}
                        />
                    </div>
                </div>
                <span className='notice notice__left'>
                    Set how fast or slow you want your speech.
                </span>
                <span className='notice notice__left'>
                    The range is from -10 to 10. With -10 being the slowest and
                    10 the fastest.
                </span>
            </div>
            <div className={styles.settingsItem}>
                <div className={styles.settingsControls}>
                    <h4>
                        Community sharing{' '}
                        <span className={styles.newIndicator}>coming soon</span>
                    </h4>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Picker
                            pickerItems={wordPermissionsPickerItems}
                            selectedItem={newSettings.wordPermissions}
                            onChange={handleWordPermissionsChange}
                            isDisabled
                        >
                            <Tooltip
                                content='coming soon'
                                placement='top'
                                arrow
                            >
                                <span style={{ marginLeft: '5px' }}>
                                    <FiInfo className='icon identifierIcon' />
                                </span>
                            </Tooltip>
                        </Picker>
                    </div>
                </div>
                <span className='notice notice__left'>
                    This will set your default sharing permissions.
                </span>
                <span className='notice notice__left'>
                    &apos;Public&apos; will let you share your rewwinds with
                    others. You can always change this per rewwind.
                </span>
            </div>
        </div>
    );
}

export default React.memo(WordsSettings);
