import React from 'react';

import Pill from 'shared/components/Pill';
import Modal from 'shared/components/Modal';
import Tabs from 'shared/components/Tabs';

import { Stat } from '../ActivityStats';

type Props = {
    isModalOpen?: boolean;
    onClose?: () => void;
    referencesCount: Stat;
};

export default function ReferencesModal({
    isModalOpen,
    onClose,
    referencesCount
}: Props) {
    const { wordsWith, wordsWithout } = referencesCount;
    const onPillClick = () => {
        onClose && onClose();
    };
    const tabItems = [
        {
            name: 'withRewwinds',
            display: 'Words with Rewwinds',
            Component: (
                <div>
                    {wordsWith?.map((word) => (
                        <Pill
                            key={word.word}
                            item={word.word}
                            type='word'
                            onClick={onPillClick}
                        />
                    ))}
                </div>
            )
        },
        {
            name: 'withoutRewwinds',
            display: 'Words without Rewwinds',
            Component: (
                <div>
                    {wordsWithout?.map((word) => (
                        <Pill
                            key={word.word}
                            item={word.word}
                            type='word'
                            onClick={onPillClick}
                        />
                    ))}
                </div>
            )
        }
    ];

    return (
        <Modal
            show={isModalOpen}
            onClose={onClose}
            title='Rewwinds'
            subTitle={`${wordsWith?.length} with rewwinds, ${wordsWithout?.length} without rewwinds`}
        >
            <Tabs tabItems={tabItems} />
        </Modal>
    );
}
