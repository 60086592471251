import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import uniqid from 'uniqid';
import { FilePond, registerPlugin } from 'react-filepond';
import { FilePondFile } from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import Button from 'shared/components/Button';
import NotVerified from 'shared/components/NotVerified';

import { thunkNewCollection } from 'redux/slices/collections';
import { selectUserInfo, selectUserVerified } from 'redux/slices/users';
import { setShowSideBar } from 'redux/slices/app';
import { apiUpdateCollectionImage } from 'api/collections';

import './NewCollection.scss';

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

type FormValues = {
    name: string;
    description: string;
};

const NewCollection = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(selectUserInfo);
    const userVerified = useSelector(selectUserVerified);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [imageFile, setImageFile] = useState([]);
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm<FormValues>({ mode: 'onChange' });
    const watchRequiredFields = watch(['name', 'description']);
    const buttonDisabled = () => {
        const haveValues = watchRequiredFields.every(
            (value) => value && value !== ''
        );
        return !haveValues || buttonLoading || imageFile.length === 0;
    };
    const onSubmit = async (data: FormValues) => {
        setButtonLoading(true);

        const { name, description } = data;
        const collectionID = uniqid();

        if (userInfo?.userID) {
            try {
                const imageURL = await apiUpdateCollectionImage(
                    userInfo?.userID,
                    imageFile,
                    collectionID
                );

                if (typeof imageURL === 'string') {
                    const collectionToAdd = {
                        name,
                        description,
                        dateAdded: new Date().toISOString(),
                        words: [],
                        archived: false,
                        collectionID,
                        collectionImage: {
                            name: (imageFile as any).name,
                            size: (imageFile as any).size,
                            type: (imageFile as any).type,
                            imageURL
                        }
                    };

                    await dispatch(thunkNewCollection(collectionToAdd));

                    dispatch(setShowSideBar({ show: false, type: null }));
                }
            } catch (err) {
                // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                throw new Error(err);
            } finally {
                setButtonLoading(false);
                reset({ name: '', description: '' });
            }
        }
    };
    const handleFileUpdate = (newFile: any) => {
        if (newFile.length > 0) {
            const { file } = newFile[0];
            setImageFile(file);
        } else {
            setImageFile([]);
        }
    };

    if (!userVerified)
        return <NotVerified userInfo={userInfo} isLinkOnly={false} />;

    return (
        <form
            className='newCollectionContainer'
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className='newCollectionSection'>
                <input
                    {...register('name', {
                        required: {
                            value: true,
                            message: 'A name is required'
                        }
                    })}
                    placeholder='Collection name'
                    type='text'
                ></input>
                <span className='notice'>required</span>
            </div>
            {errors.name && (
                <div className='errorMessage'>{errors.name.message}</div>
            )}
            <div className='newCollectionSection'>
                <textarea
                    {...register('description', {
                        required: {
                            value: true,
                            message: 'Description is required'
                        },
                        maxLength: {
                            value: 140,
                            message: 'Cannot be more than 140 characters'
                        }
                    })}
                    placeholder='Collection description'
                    rows={7}
                ></textarea>
                <span className='notice'>required</span>
            </div>
            {errors.description && (
                <div className='errorMessage'>{errors.description.message}</div>
            )}
            <div className='imageContainer'>
                <FilePond
                    allowMultiple={false}
                    allowImagePreview={true}
                    allowFileTypeValidation={true}
                    acceptedFileTypes={[
                        'image/png',
                        'image/jpeg',
                        'image/gif',
                        'image/jpg'
                    ]}
                    labelIdle="Drag & drop collection image or <span class='filepond--label-action'>Browse</span>"
                    onupdatefiles={handleFileUpdate}
                />
                <span className='notice'>required</span>
            </div>
            <Button
                buttonType='submit'
                buttonDisabled={buttonDisabled()}
                isLoading={buttonLoading}
            >
                Create Collection
            </Button>
        </form>
    );
};

export default NewCollection;
