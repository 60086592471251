import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { FiEdit, FiPlus, FiTrash2, FiX, FiCheck } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { thunkGetWords } from 'redux/slices/words';
import { thunkShowSideBar } from 'redux/slices/app';
import {
    setActiveCollection,
    thunkDeleteCollection
} from 'redux/slices/collections';
import { formatDate } from 'helpers/helpers';

import * as ROUTES from 'constants/routes';

import Loader from 'shared/components/Loader';
import MoreOptions from 'shared/components/MoreOptions';
import WordList from 'views/Words/WordList';
import Button from 'shared/components/Button';
import Modal from 'shared/components/Modal';
import SmallLoader from 'shared/components/SmallLoader';

import { DropdownOption } from 'shared/components/Dropdown/Dropdown';
import { Word } from 'types/word';
import { Collection as CollectionType } from 'types/collection';

import './Collection.scss';

type Props = {
    collections: CollectionType[];
    words: Word[];
};

const Collection = ({ collections, words }: Props) => {
    const dispatch = useDispatch();
    const { collectionID } = useParams<{ collectionID: string }>();
    const [collectionLoading, setCollectionLoading] = useState<boolean>(true);
    const [isContentShowing, setIsContentShowing] = useState<boolean>(false);
    const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(false);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [wordsToShow, setWordsToShow] = useState<Word[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const activeCollection = collections.find(
        (item) => item.collectionID === collectionID
    )!;
    const handleShowMoreClick = () => {
        setShowOptions(!showOptions);
    };
    const handleClose = () => {
        setShowOptions(false);
    };
    const handleEditClick = async () => {
        dispatch(thunkShowSideBar(null, 'editCollection', activeCollection));
        setShowOptions(false);
    };
    const handleAddClick = async () => {
        dispatch(
            thunkShowSideBar(
                null,
                'addWordToCollection',
                activeCollection?.collectionID
            )
        );
        setShowOptions(false);
    };
    const handleDeleteClick = async () => {
        setIsOptionsLoading(true);
        await dispatch(thunkDeleteCollection(activeCollection));
        setIsOptionsLoading(false);
    };
    const handleModalClose = () => {
        setIsOptionsLoading(false);
        setIsModalOpen(false);
    };
    const options: DropdownOption[] = [
        {
            name: 'Edit',
            icon: <FiEdit className='icon' />,
            onClick: handleEditClick
        },
        {
            name: 'Add words',
            icon: <FiPlus className='icon' />,
            onClick: handleAddClick
        },
        {
            name: 'Delete',
            icon: <FiTrash2 className='icon dangerIcon' />,
            onClick: () => {
                setIsModalOpen(true);
                setShowOptions(false);
            },
            isDanger: true
        }
    ];
    const renderCollectionsWords = () => {
        const unArchivedWords = wordsToShow
            .filter((item) => !item.archived)
            .map((item) => item.word);

        return (
            <div className='detailItem'>
                <label>
                    <span>{unArchivedWords.length} </span>
                    {unArchivedWords.length > 1 ? 'words' : 'word'}
                </label>
            </div>
        );
    };

    useEffect(() => {
        async function getWords() {
            await dispatch(thunkGetWords());
            setCollectionLoading(false);
            dispatch(setActiveCollection(activeCollection));
        }

        getWords();
    }, [activeCollection, dispatch]);

    useEffect(() => {
        if (activeCollection) {
            const collectionWords: Word[] = [];

            activeCollection.words.forEach((activeCollectionWord) => {
                collectionWords.push(
                    ...words.filter(
                        (word) => word.word === activeCollectionWord
                    )
                );
            });

            setWordsToShow(collectionWords);
        }
    }, [collections, words, activeCollection]);

    if (!activeCollection) {
        return <Redirect to={{ pathname: ROUTES.COLLECTIONS }} />;
    }

    if (collectionLoading) {
        return (
            <div className='collectionsLoaderContainer'>
                <Loader />
            </div>
        );
    }

    return (
        <div className='collectionContainer'>
            <div className='collectionDetails'>
                {activeCollection.collectionImage && (
                    <>
                        <div
                            className='collectionImageContainer'
                            style={{
                                backgroundImage: `url(${activeCollection.collectionImage.imageURL})`
                            }}
                        ></div>
                        <div className='collectionMoreOptions'>
                            <MoreOptions
                                onClick={handleShowMoreClick}
                                options={options}
                                showDropdown={showOptions}
                                isLoading={isOptionsLoading}
                                buttonSize='small'
                                onClose={handleClose}
                            />
                        </div>
                    </>
                )}
                <div className='detailItem'>
                    <label>collection name</label>
                    <h3 title={activeCollection.name}>
                        {activeCollection.name}
                    </h3>
                </div>
                {isContentShowing && (
                    <div className='collectionContent'>
                        <div className='detailItem'>
                            <label>collection description</label>
                            <p>{activeCollection.description}</p>
                        </div>
                        <div className='detailItem'>
                            <label>Date created</label>
                            <p>
                                {formatDate(
                                    activeCollection.dateAdded,
                                    'dateAndTime'
                                )}
                            </p>
                        </div>
                        <>{renderCollectionsWords()}</>
                    </div>
                )}
                <div className='viewMoreContainer'>
                    {!isContentShowing ? (
                        <IoIosArrowDown
                            className='icon actionIcon'
                            onClick={() =>
                                setIsContentShowing(!isContentShowing)
                            }
                        />
                    ) : (
                        <IoIosArrowUp
                            className='icon actionIcon'
                            onClick={() =>
                                setIsContentShowing(!isContentShowing)
                            }
                        />
                    )}
                </div>
            </div>
            <div className='collectionWords'>
                <WordList
                    words={wordsToShow}
                    section='collections'
                    collection={activeCollection}
                />
            </div>
            <Modal
                show={isModalOpen}
                onClose={handleModalClose}
                title='Delete Collection'
            >
                <h4>Are you sure you want to delete this collection?</h4>
                <p>
                    Once deleted, you won&apos;t be able to retrieve it and the
                    collection will have to be re-created.
                </p>
                <div className='modalFooter'>
                    <Button
                        buttonCategory='icon'
                        buttonDisabled={isOptionsLoading}
                        onClick={handleDeleteClick}
                    >
                        {isOptionsLoading ? (
                            <SmallLoader />
                        ) : (
                            <FiCheck className='icon buttonIcon' />
                        )}
                    </Button>
                    <Button
                        buttonCategory='iconAlternate'
                        onClick={handleModalClose}
                        buttonDisabled={isOptionsLoading}
                    >
                        <FiX className='icon buttonIcon' />
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default Collection;
