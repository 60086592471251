import { gql } from '@apollo/client';

export const MUTATION_ADD_USER_NOTE = gql`
    mutation addUserNote($note: NoteInput!, $userID: ID!) {
        addUserNote(note: $note, userID: $userID)
    }
`;

export const QUERY_GET_USER_NOTES = gql`
    query getUserNotes($userID: ID!) {
        getUserNotes(userID: $userID) {
            noteID
            title
            content
            dateAdded
            wordCount
            archived
            pinned
            locked
        }
    }
`;

export const MUTATION_DELETE_USER_NOTE = gql`
    mutation deleteUserNote($noteID: ID!, $userID: ID!) {
        deleteUserNote(noteID: $noteID, userID: $userID)
    }
`;

export const MUTATION_UPDATE_USER_NOTE = gql`
    mutation updateUserNote($updatedNote: NoteInput!, $userID: ID!) {
        updateUserNote(updatedNote: $updatedNote, userID: $userID)
    }
`;
