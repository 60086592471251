import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import {
    FiTrash2,
    FiVolume1,
    FiVolume2,
    FiCornerUpLeft,
    FiX,
    FiCheck
} from 'react-icons/fi';
import { VscArchive } from 'react-icons/vsc';
import { BsFolderPlus, BsFolderMinus } from 'react-icons/bs';
import { AiOutlineSearch, AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { toast } from 'react-toastify';

import { client } from 'index';

import {
    thunkDeleteUserWord,
    thunkSearchWord,
    thunkUpdateUserWord
} from 'redux/slices/words';
import { thunkShowSideBar, setShowSideBar } from 'redux/slices/app';
import {
    thunkUpdateCollection,
    selectActiveCollection
} from 'redux/slices/collections';
import { selectUserSettings } from 'redux/slices/users';
import { QUERY_GET_WORD_PRONUNCIATION } from 'api/words';

import SmallLoader from 'shared/components/SmallLoader';
import Tooltip from 'shared/components/Tooltip';
import MoreOptions from 'shared/components/MoreOptions';
import Button from 'shared/components/Button';
import Modal from 'shared/components/Modal';

import * as ROUTES from 'constants/routes';

import { DropdownOption } from 'shared/components/Dropdown/Dropdown';

import { WordPronunciation, Word } from 'types/word';
import { Collection } from 'types/collection';

import './WordOptions.scss';

type Props = {
    word: Word;
    voice?: string;
    speed?: number;
    lang?: string;
    showSearch?: boolean;
    showAddToCollection?: boolean;
    showRemoveFromCollection?: boolean;
    showDelete?: boolean;
    showArchive?: boolean;
    showMoreOptions?: boolean;
};

const WordOptions = ({
    word,
    voice,
    speed,
    lang,
    showSearch,
    showAddToCollection,
    showRemoveFromCollection,
    showDelete,
    showArchive,
    showMoreOptions
}: Props) => {
    let audio: HTMLAudioElement;
    const history = useHistory();
    const dispatch = useDispatch();
    const { defaultVoice, speechSpeed, language } =
        useSelector(selectUserSettings);
    const activeCollection = useSelector(selectActiveCollection);
    const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
    const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(false);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handlePlay = async (e?: React.MouseEvent) => {
        e?.stopPropagation();

        try {
            const { data, error } = await client.query<{
                getWordPronunciation: WordPronunciation;
            }>({
                query: QUERY_GET_WORD_PRONUNCIATION,

                variables: {
                    word: word.word,
                    voice: voice || defaultVoice?.name,
                    speechSpeed: speed || speechSpeed?.speed,
                    language: lang || language?.code
                }
            });

            if (error) {
                toast.error(error.message);
            }

            if (data && data.getWordPronunciation) {
                const pronunciation = data.getWordPronunciation;

                audio = new Audio(pronunciation.data);
                audio.onended = () => {
                    setIsSpeaking(false);
                };
                audio.play();

                setIsSpeaking(true);
            }
        } catch (err) {
            setIsSpeaking(false);
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

    const handleSearch = async () => {
        history.push(ROUTES.SEARCH);
        dispatch(thunkSearchWord(word.word));
        dispatch(
            setShowSideBar({
                show: false,
                type: null,
                data: { showVariations: true }
            })
        );
    };

    const handleAddToCollection = async () => {
        dispatch(thunkShowSideBar(word, 'addToCollection'));
    };

    const handleRemoveFromCollection = async (e?: React.MouseEvent) => {
        e?.stopPropagation();

        setIsOptionsLoading(true);

        const updatedCollectionWords = activeCollection?.words.filter(
            (item) => item !== word.word
        );
        const updatedCollection = {
            ...activeCollection,
            words: updatedCollectionWords
        } as Collection;

        await dispatch(thunkUpdateCollection(updatedCollection));
        setIsOptionsLoading(false);
    };

    const handleDelete = async () => {
        setIsOptionsLoading(true);

        await dispatch(thunkDeleteUserWord(word.word));
        setIsOptionsLoading(false);
    };
    const handleUpdateWord = async (value: Record<string, boolean>) => {
        setIsModalOpen(false);
        setIsOptionsLoading(true);

        const newWord = {
            ...word,
            ...value
        };

        // DB does not take a details property. That's only for the UI
        newWord.details && delete newWord.details;

        await dispatch(thunkUpdateUserWord(newWord));
        setIsOptionsLoading(false);
        setShowOptions(false);
    };
    const handleModalClose = () => {
        setIsOptionsLoading(false);
        setIsModalOpen(false);
    };
    const options: DropdownOption[] = [
        {
            name: 'Speak',
            icon: (
                <FiVolume1 className='icon actionIcon__small' title='Speak' />
            ),
            onClick: handlePlay
        },
        {
            name: 'Search',
            icon: (
                <AiOutlineSearch
                    className='icon actionIcon__small'
                    title={`Search for '${word.word}'`}
                />
            ),
            onClick: handleSearch
        },
        {
            name: word.favorite ? 'Unfavorite' : 'Favorite',
            icon: word.favorite ? (
                <AiFillStar
                    className='icon actionIcon__small'
                    title={`Unfavorite '${word.word}'`}
                    style={{ fill: '#ffc683' }}
                />
            ) : (
                <AiOutlineStar
                    className='icon actionIcon__small'
                    title={`Favorite '${word.word}'`}
                />
            ),
            onClick: word.favorite
                ? () => handleUpdateWord({ favorite: false })
                : () => handleUpdateWord({ favorite: true })
        },
        {
            name: 'Collect',
            icon: (
                <BsFolderPlus
                    className='icon actionIcon'
                    title={`Add '${word.word}' to a collection`}
                />
            ),
            onClick: handleAddToCollection
        },
        {
            name: word.archived ? 'Unarchive' : 'Archive',
            icon: word.archived ? (
                <FiCornerUpLeft
                    className='icon actionIcon__small'
                    title={`Unarchive '${word.word}'`}
                />
            ) : (
                <VscArchive
                    className='icon actionIcon__small'
                    title={`Archive '${word.word}'`}
                />
            ),
            onClick: word.archived
                ? () => handleUpdateWord({ archived: false })
                : () => handleUpdateWord({ archived: true })
        },
        {
            name: 'Delete',
            icon: (
                <FiTrash2
                    className='icon actionIcon__small dangerIcon'
                    title={`Delete '${word.word}'`}
                />
            ),
            onClick: () => {
                setIsModalOpen(true);
                setShowOptions(false);
            }
        }
    ];
    const handleShowMoreClick = () => {
        setShowOptions(!showOptions);
    };
    const handleClose = () => {
        setShowOptions(false);
    };

    useEffect(() => {
        setIsSpeaking(false);
    }, []);

    if (isOptionsLoading) {
        return <SmallLoader />;
    }

    return (
        <>
            <span className='wordOptions'>
                {showMoreOptions ? (
                    <MoreOptions
                        options={options}
                        onClick={handleShowMoreClick}
                        showDropdown={showOptions}
                        isLoading={isOptionsLoading}
                        selfAlign={true}
                        buttonSize='small'
                        onClose={handleClose}
                    />
                ) : (
                    <>
                        {isSpeaking ? (
                            <span>
                                <FiVolume2 className='icon actionIcon' />
                            </span>
                        ) : (
                            <Tooltip content='Speak' arrow={true}>
                                <span>
                                    <FiVolume1
                                        className='icon actionIcon'
                                        onClick={(e) => handlePlay(e)}
                                        title='Speak'
                                    />
                                </span>
                            </Tooltip>
                        )}
                        {showSearch === true && (
                            <Tooltip
                                content={`Search for '${word.word}'`}
                                arrow={true}
                            >
                                <span>
                                    <AiOutlineSearch
                                        className='icon actionIcon'
                                        onClick={handleSearch}
                                        title={`Search for '${word.word}'`}
                                    />
                                </span>
                            </Tooltip>
                        )}
                        {showAddToCollection === true && (
                            <Tooltip
                                content={`Add '${word.word}' to a collection`}
                                arrow={true}
                            >
                                <span>
                                    <BsFolderPlus
                                        className='icon actionIcon'
                                        onClick={handleAddToCollection}
                                        title={`Add '${word.word}' to a collection`}
                                    />
                                </span>
                            </Tooltip>
                        )}
                        {showRemoveFromCollection === true && (
                            <Tooltip
                                content={`Remove '${word.word}' from this collection`}
                                arrow={true}
                            >
                                <span>
                                    <BsFolderMinus
                                        className='icon actionIcon'
                                        onClick={handleRemoveFromCollection}
                                        title={`Remove '${word.word}' from this collection`}
                                    />
                                </span>
                            </Tooltip>
                        )}
                        {showArchive === true && (
                            <>
                                {word.archived ? (
                                    <Tooltip
                                        content={`Unarchive '${word.word}'`}
                                        arrow={true}
                                    >
                                        <span>
                                            <FiCornerUpLeft
                                                className='icon actionIcon'
                                                onClick={() =>
                                                    handleUpdateWord({
                                                        archived: false
                                                    })
                                                }
                                                title={`Unarchive '${word.word}'`}
                                            />
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        content={`Archive '${word.word}'`}
                                        arrow={true}
                                    >
                                        <span>
                                            <VscArchive
                                                className='icon actionIcon'
                                                onClick={() =>
                                                    handleUpdateWord({
                                                        archived: true
                                                    })
                                                }
                                                title={`Archive '${word.word}'`}
                                            />
                                        </span>
                                    </Tooltip>
                                )}
                            </>
                        )}
                        {showDelete === true && (
                            <Tooltip
                                content={`Delete '${word.word}'`}
                                arrow={true}
                            >
                                <span>
                                    <FiTrash2
                                        className='icon actionIcon dangerIcon'
                                        onClick={handleDelete}
                                        title={`Delete '${word.word}'`}
                                    />
                                </span>
                            </Tooltip>
                        )}
                    </>
                )}
            </span>
            <Modal
                show={isModalOpen}
                onClose={handleModalClose}
                title='Delete Word'
            >
                <h4>Are you sure you want to delete this word?</h4>
                <p>
                    Once deleted, you won&apos;t be able to retrieve it and the
                    word will have to be re-added.
                </p>
                <p>
                    All rewwinds associated with this word will also be deleted.
                </p>
                {!word.archived && (
                    <p>
                        You can also{' '}
                        <a onClick={() => handleUpdateWord({ archived: true })}>
                            archive
                        </a>{' '}
                        this word.{' '}
                    </p>
                )}
                <div className='modalFooter'>
                    <Button
                        buttonCategory='icon'
                        buttonDisabled={isOptionsLoading}
                        onClick={handleDelete}
                    >
                        {isOptionsLoading ? (
                            <SmallLoader />
                        ) : (
                            <FiCheck className='icon buttonIcon' />
                        )}
                    </Button>
                    <Button
                        buttonCategory='iconAlternate'
                        onClick={handleModalClose}
                        buttonDisabled={isOptionsLoading}
                    >
                        <FiX className='icon buttonIcon' />
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default WordOptions;
