import React from 'react';

import { detectLink } from 'helpers/helpers';

import Pill from 'shared/components/Pill';

import styles from './ActivityGraphsDetails.module.scss';

// TODO: Add ActivityGraph and AvtivityRange types
type WordsGraphDetailsProps = {
    activeGraph: any;
    activeGraphRange: string;
    onPillClick: () => void;
};

const WordsGraphDetails = ({
    activeGraph,
    activeGraphRange,
    onPillClick
}: WordsGraphDetailsProps) => {
    return (
        <div className={styles.wordGraphDetails}>
            <h4>
                {activeGraphRange === 'month'
                    ? activeGraph[activeGraphRange].period
                    : activeGraph[activeGraphRange]}
            </h4>
            <div className={styles.graphDetailsSummary}>
                <div className={styles.summaryColumn}>
                    <div>
                        <p>words</p>
                        <span className={styles.summaryValue}>
                            {activeGraph.wordsCount}
                        </span>
                    </div>
                </div>
                <div className={styles.summaryColumn}>
                    <div>
                        <p>words added</p>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {activeGraph.words.map((item: any) => (
                                <Pill
                                    key={item}
                                    item={item}
                                    type='word'
                                    onClick={onPillClick}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// TODO: Add ActivityGraph and AvtivityRange types
type RewwindsGraphDetailsProps = {
    activeGraph?: any;
    activeGraphRange?: any;
    onPillClick?: () => void;
};

const RewwindsGraphDetails = ({
    activeGraph,
    activeGraphRange,
    onPillClick
}: RewwindsGraphDetailsProps) => {
    return (
        <div className={styles.wordGraphDetails}>
            <h4>
                {activeGraphRange === 'month'
                    ? activeGraph[activeGraphRange].period
                    : activeGraph[activeGraphRange]}
            </h4>
            <div className={styles.graphDetailsSummary}>
                <div className={styles.summaryColumn}>
                    <div>
                        <p>rewwinds</p>
                        <span className={styles.summaryValue}>
                            {activeGraph.referencesCount}
                        </span>
                    </div>
                    <div>
                        <p>words</p>
                        <span className={styles.summaryValue}>
                            {activeGraph.wordsCount}
                        </span>
                    </div>
                </div>
                <div className={styles.summaryColumn}>
                    <div>
                        <p>rewwinds added</p>
                        <div>
                            {activeGraph.references.map((item: any) => (
                                <p key={item} className={styles.rewwindItem}>
                                    {detectLink(item)}
                                </p>
                            ))}
                        </div>
                    </div>
                    <div>
                        <p>words added</p>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {activeGraph.words.map((item: any) => (
                                <Pill
                                    key={item}
                                    item={item}
                                    type='word'
                                    onClick={onPillClick}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

type ActivityGraphsDetailsProps = {
    children?: React.ReactNode;
    activeGraph: any;
    activeGraphRange: string;
    onClose?: () => void;
};

export default function ActivityGraphsDetails({
    children,
    ...rest
}: ActivityGraphsDetailsProps) {
    const { activeGraph, activeGraphRange, onClose } = rest;
    const handleClose = () => {
        onClose && onClose();
    };

    return (
        <div className={styles.activityGraphsDetails}>
            {children}
            <div className={styles.activityTabsContainer}>
                {activeGraph.name === 'wordsCount' ? (
                    <WordsGraphDetails
                        activeGraph={activeGraph.payload}
                        activeGraphRange={activeGraphRange}
                        onPillClick={handleClose}
                    />
                ) : (
                    <RewwindsGraphDetails
                        activeGraph={activeGraph.payload}
                        activeGraphRange={activeGraphRange}
                        onPillClick={handleClose}
                    />
                )}
            </div>
        </div>
    );
}
