import { createSlice } from '@reduxjs/toolkit';
import { useMutation } from '@apollo/client';

import {
    // apiAddCommunityReference,
    // apiUpdateCommunityReference,
    // apiDeleteCommunityReference,
    MUTATION_ADD_COMMUNITY_RFERENCE,
    MUTATION_DELETE_COMMUNITY_REFERENCE,
    MUTATION_UPDATE_COMMUNITY_REFERENCE
} from 'api/community';
import { throwError } from 'api/utilities';

import { Word } from 'types/word';
import { Reference, WordReference } from 'types/reference';

// type InitialState = {
//   community: Community[];
//   isLoading: boolean;
// };

// type CommunitySlice = {
//   community: InitialState;
// };

export const communitySlice = createSlice({
    name: 'community',
    initialState: {
        community: [],
        isLoading: true
    },
    reducers: {
        setIsCommunityLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        addCommunityWord: (state, action) => {
            // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
            state.community.push(action.payload);
        }
        // addCommunityReference: (state, action) => {},
    }
});

export const {
    setIsCommunityLoading,
    addCommunityWord,
    // @ts-expect-error TS(2339): Property 'addCommunityReference' does not exist on... Remove this comment to see the full error message
    addCommunityReference
} = communitySlice.actions;

//THUNKS
export const thunkAddCommunityReference =
    (word: Word, reference: WordReference) =>
    async (_dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            }
        } = state;

        const communityReference = {
            userID,
            referenceID: reference.references[0].referenceID,
            word: word.word,
            dateCreated: reference.references[0].dateAdded,
            dateUpdated: reference.references[0].dateAdded,
            isPublic: reference.references[0].isPublic
        };
        const communityWord = {
            word: word.word,
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString()
            // addedManually: word.addedManually
        };
        const communityUser = {
            userID
        };

        try {
            const [addCommunityReference, { data, error }] = useMutation<{
                addCommunityReference: boolean;
            }>(MUTATION_ADD_COMMUNITY_RFERENCE, {
                variables: {
                    word: communityWord,
                    reference: communityReference,
                    user: communityUser
                }
            });

            addCommunityReference();

            if (error) {
                throwError(error);
            }

            if (data && data.addCommunityReference) {
                // do something
            }

            //   const { data, errors } = await apiAddCommunityReference(
            //       communityWord,
            //       communityReference,
            //       communityUser
            //   );

            //   if (errors) {
            //     throwError(errors);
            // }

            // if (data && data.addCommunityReference) {
            //     // do something
            // }
        } catch (err) {
            // console.log(err);
        }
    };

export const thunkUpdateCommunityReference =
    (word: Word, updatedReference: Reference) =>
    async (_dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            }
        } = state;

        const communityUser = {
            userID
        };

        // re-initializing because api doesn't accept dateAdded
        const communityReference = {
            referenceID: updatedReference.referenceID,
            isPublic: updatedReference.isPublic,
            dateUpdated: new Date().toISOString()
        };

        try {
            const [updateCommunityReference, { data, error }] = useMutation<{
                updateCommunityReference: boolean;
            }>(MUTATION_UPDATE_COMMUNITY_REFERENCE, {
                variables: {
                    word,
                    updatedReference: communityReference,
                    user: communityUser
                }
            });

            updateCommunityReference();

            if (error) {
                throwError(error);
            }

            if (data && data.updateCommunityReference) {
                // console.log('community reference updated');
            }

            // const { data, errors } = await apiUpdateCommunityReference(
            //     word,
            //     communityReference,
            //     communityUser
            // );

            // if (errors) {
            //     throwError(errors);
            // }

            // if (data && data.addCommunityReference) {
            //     // console.log('community reference updated');
            // }
        } catch (err) {
            // console.log(err);
        }
    };

export const thunkDeleteCommunityReference =
    (word: Word, reference: Reference) =>
    async (_dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            }
        } = state;

        const communityUser = {
            userID
        };

        // re-initializing because api doesn't accept dateAdded
        const communityReference = {
            referenceID: reference.referenceID,
            isPublic: reference.isPublic,
            dateUpdated: new Date().toISOString()
        };

        try {
            const [deleteCommunityReference, { data, error }] = useMutation<{
                deleteCommunityReference: boolean;
            }>(MUTATION_DELETE_COMMUNITY_REFERENCE, {
                variables: {
                    word,
                    reference: communityReference,
                    user: communityUser
                }
            });

            deleteCommunityReference();

            if (error) {
                throwError(error);
            }

            if (data && data.deleteCommunityReference) {
                // console.log('community reference deleted');
            }

            // const { data, errors } = await apiDeleteCommunityReference(
            //         word,
            //         communityReference,
            //         communityUser
            //     );

            //     if (errors) {
            //         throwError(errors);
            //     }

            //     if (data && data.addCommunityReference) {
            //         // console.log('community reference deleted');
            //     }
        } catch (err) {
            // console.log(err);
        }
    };

export const resetCommunitySlice = () => (dispatch: React.Dispatch<any>) => {
    dispatch(setIsCommunityLoading(true));
};

//SELECTORS
export const selectCommunityLoading = (state: any) => state.community.isLoading;

export default communitySlice.reducer;
