import React, { useState, useEffect } from 'react';
import { FiEdit, FiTrash2, FiPlus, FiX, FiCheck } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';

import { thunkShowSideBar } from 'redux/slices/app';
import { thunkDeleteCollection } from 'redux/slices/collections';

import MoreOptions from 'shared/components/MoreOptions';
import Pill from 'shared/components/Pill';
import Button from 'shared/components/Button';
import SmallLoader from 'shared/components/SmallLoader';
import Modal from 'shared/components/Modal';

import { DropdownOption } from 'shared/components/Dropdown/Dropdown';

import { Word } from 'types/word';
import { Collection } from 'types/collection';

import './CollectionsListItem.scss';

type Props = {
    collection: Collection;
    words: Word[];
};

const CollectionsListItem = ({ collection, words }: Props) => {
    const { url } = useRouteMatch();
    const dispatch = useDispatch();
    const [showOptionsButton, setShowOptionsButton] = useState<boolean>(false);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const handleShowMoreClick = () => {
        setShowOptions(!showOptions);
    };
    const handleEditClick = async () => {
        dispatch(thunkShowSideBar(null, 'editCollection', collection));
    };
    const handleAddClick = async () => {
        dispatch(
            thunkShowSideBar(
                null,
                'addWordToCollection',
                collection.collectionID
            )
        );
        setShowOptions(false);
    };
    const handleDeleteClick = async () => {
        setIsOptionsLoading(true);
        await dispatch(thunkDeleteCollection(collection));
        setIsOptionsLoading(false);
    };
    const handleMouseLeave = () => {
        setShowOptionsButton(false);
        setShowOptions(false);
    };
    const handleModalClose = () => {
        setIsOptionsLoading(false);
        setIsModalOpen(false);
    };
    const options: DropdownOption[] = [
        {
            name: 'Edit',
            icon: <FiEdit className='icon' />,
            onClick: handleEditClick
        },
        {
            name: 'Add words',
            icon: <FiPlus className='icon' />,
            onClick: handleAddClick
        },
        {
            name: 'Delete',
            icon: <FiTrash2 className='icon dangerIcon' />,
            onClick: () => {
                setIsModalOpen(true);
                setShowOptions(false);
                setShowOptionsButton(false);
            },
            isDanger: true
        }
    ];
    const hasWords = collection.words.length > 0;
    const onlyWords = words
        .filter((item) => !item.archived)
        .map((item) => item.word);
    const wordsToShow = collection.words.filter((item) => {
        if (onlyWords.includes(item)) {
            return item;
        }
    });
    const collectionLimit = () => {
        if (wordsToShow.length > 6) {
            return `+${wordsToShow.length - 6}`;
        }

        return null;
    };
    const renderCollectionsWords = (): JSX.Element => {
        return (
            <>
                {hasWords ? (
                    <>
                        <label>
                            {wordsToShow.length}{' '}
                            {wordsToShow.length > 1 ? 'words' : 'word'}
                        </label>
                        <div className='words'>
                            {wordsToShow.slice(0, 6).map((word) => (
                                <Pill key={word} item={word} type='word' />
                            ))}
                            <span>{collectionLimit()}</span>
                        </div>
                    </>
                ) : (
                    <div className='words'>
                        <p className='noWords'>No words</p>
                    </div>
                )}
            </>
        );
    };

    const renderCollectionDetails = (): JSX.Element => {
        return (
            <Link to={`${url}/${collection.collectionID}`}>
                <div className='collectionDetails'>
                    <div className='collectionHeader'>
                        <div className='headerContent'>
                            <div>
                                <label>name</label>
                                <h3 title={collection.name}>
                                    {collection.name}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className='collectionContent'>
                        <div className='description'>
                            <div>
                                <label>description</label>
                                <p>{collection.description}</p>
                            </div>
                        </div>
                        <>{renderCollectionsWords()}</>
                    </div>
                </div>
            </Link>
        );
    };

    useEffect(() => {
        setIsOptionsLoading(false);
    }, []);

    return (
        <>
            <div
                className='collectionItem'
                onMouseEnter={() => setShowOptionsButton(true)}
                onMouseLeave={handleMouseLeave}
            >
                {collection.collectionImage && (
                    <div
                        className='collectionImageContainer'
                        style={{
                            backgroundImage: `url(${collection.collectionImage.imageURL})`,
                            opacity: `${showOptionsButton ? 0.45 : 0.15}`,
                            filter: `blur(${showOptionsButton ? 0.5 : 1}px)`
                        }}
                    ></div>
                )}
                {renderCollectionDetails()}
                {showOptionsButton && (
                    <MoreOptions
                        onClick={handleShowMoreClick}
                        showDropdown={showOptions}
                        options={options}
                        selfAlign={true}
                        isLoading={isOptionsLoading}
                        buttonSize='small'
                    />
                )}
            </div>
            <Modal
                show={isModalOpen}
                onClose={handleModalClose}
                title='Delete Collection'
            >
                <h4>Are you sure you want to delete this collection?</h4>
                <p>
                    Once deleted, you won&apos;t be able to retrieve it and the
                    collection will have to be re-created.
                </p>
                <div className='modalFooter'>
                    <Button
                        buttonCategory='icon'
                        buttonDisabled={isOptionsLoading}
                        onClick={handleDeleteClick}
                    >
                        {isOptionsLoading ? (
                            <SmallLoader />
                        ) : (
                            <FiCheck className='icon buttonIcon' />
                        )}
                    </Button>
                    <Button
                        buttonCategory='iconAlternate'
                        onClick={handleModalClose}
                        buttonDisabled={isOptionsLoading}
                    >
                        <FiX className='icon buttonIcon' />
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default CollectionsListItem;
