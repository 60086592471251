import { gql } from '@apollo/client';

export const QUERY_GET_WORDS_TIME_SERIES = gql`
    query getWordTimeSeries($userID: ID!, $range: String!) {
        getWordTimeSeries(userID: $userID, range: $range) {
            data {
                month {
                    period
                    display
                }
                day
                words
                wordsCount
            }
            range
        }
    }
`;

export const QUERY_GET_REFERENCES_TIME_SERIES = gql`
    query getReferencesTimeSeries($userID: ID!, $range: String!) {
        getReferencesTimeSeries(userID: $userID, range: $range) {
            data {
                month {
                    period
                    display
                }
                day
                words
                wordsCount
                references
                referencesCount
            }
            range
        }
    }
`;
