import React, { useEffect } from 'react';
import {
    Route,
    Switch,
    useRouteMatch,
    NavLink,
    Redirect
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FiMail, FiSettings, FiUser, FiUnlock } from 'react-icons/fi';

import { selectUserInfo, selectUserVerified } from 'redux/slices/users';
import { thunkPageReset } from 'redux/slices/app';

import EmailSettings from './EmailSettings';
import PasswordSettings from './PasswordSettings';
import UserSettings from './UserSettings';
import WordsSettings from './WordsSettings';
import NotesSettings from './NotesSettings';
import GeneralSettings from './GeneralSettings';
import SecuritySettings from './SecuritySettings';
import Header from 'components/Header';
import NotVerified from 'shared/components/NotVerified';

import './Account.scss';

const Account = () => {
    const dispatch = useDispatch();
    const { path, url } = useRouteMatch();
    const userInfo = useSelector(selectUserInfo);
    const userVerified = useSelector(selectUserVerified);

    useEffect(() => {
        dispatch(thunkPageReset('account'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            <div className='profileContainer'>
                <nav className='profileNav'>
                    <ul>
                        <li>
                            <NavLink
                                activeClassName='active'
                                to={`${url}/profile`}
                            >
                                <FiUser />
                                <span>Profile</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName='active'
                                to={`${url}/email`}
                            >
                                <FiMail />
                                <span>Email</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName='active'
                                to={`${url}/password`}
                            >
                                <FiUnlock />
                                <span>Password</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName='active'
                                to={`${url}/settings`}
                            >
                                <FiSettings />
                                <span>Settings</span>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
                <div className='profileBody'>
                    <Switch>
                        <Route exact path={path}>
                            <Redirect to={{ pathname: `${path}/profile` }} />
                        </Route>
                        <Route path={`${path}/profile`}>
                            <UserSettings userInfo={userInfo} />
                        </Route>
                        <Route path={`${path}/email`}>
                            <EmailSettings userInfo={userInfo} />
                        </Route>
                        <Route path={`${path}/password`}>
                            <PasswordSettings />
                        </Route>
                        <Route path={`${path}/settings`}>
                            <>
                                {userVerified ? (
                                    <>
                                        <GeneralSettings />
                                        <WordsSettings />
                                        <NotesSettings />
                                        <SecuritySettings />
                                    </>
                                ) : (
                                    <NotVerified
                                        userInfo={userInfo}
                                        isLinkOnly={false}
                                    />
                                )}
                            </>
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
};

export default Account;
