import React from 'react';

import CollectionsListItem from '../CollectionsListItem';

import { Word } from 'types/word';
import { Collection } from 'types/collection';

import './CollectionsList.scss';

type Props = {
    collections: Collection[];
    words: Word[];
};

const CollectionsList = ({ collections, words }: Props) => {
    const hasCollections = collections.length > 0;
    const renderCollectionsList = () => {
        return (
            <>
                {hasCollections ? (
                    <>
                        {collections.map((collection) => (
                            <CollectionsListItem
                                collection={collection}
                                words={words}
                                key={collection.collectionID}
                            />
                        ))}
                    </>
                ) : (
                    <p>No words</p>
                )}
            </>
        );
    };

    return (
        <div className='collectionsListContainer'>
            {renderCollectionsList()}
        </div>
    );
};

export default CollectionsList;
