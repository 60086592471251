// Resources
// https://www.wisdomgeek.com/development/web-development/react/detecting-click-outside-component-using-react-hooks/

import React from 'react';
import classNames from 'classnames';

import styles from './Dropdown.module.scss';

export type DropdownOption = {
    name: string;
    disabled?: boolean;
    isDanger?: boolean;
    icon: React.ReactNode;
    onClick?: () => unknown;
};

type Props = {
    options: DropdownOption[];
    buttonSize?: string;
    onClose?: () => void;
};

const Dropdown = React.forwardRef<HTMLDivElement, Props>(
    ({ options, buttonSize, onClose }, ref) => {
        const optionDisabled = (option: DropdownOption) =>
            classNames(styles.option, {
                [styles.option__disabled]: option.disabled,
                [styles.option__danger]: option.isDanger
            });
        const dropdownClass = classNames(styles.dropdown, {
            [styles.dropdown__regular]: buttonSize === 'regular'
        });
        const handleDropdownItemClick = (
            e: React.MouseEvent,
            option: DropdownOption
        ) => {
            e.stopPropagation();

            if (!option.disabled) {
                option.onClick && option.onClick();
            }

            onClose && onClose();
        };

        return (
            <div className={dropdownClass} ref={ref}>
                <ul>
                    {options
                        .filter((option) => !!option.name.length)
                        .map((option) => (
                            <li
                                key={option.name}
                                onClick={(e) =>
                                    handleDropdownItemClick(e, option)
                                }
                                className={optionDisabled(option)}
                            >
                                {option.icon}
                                {option.name}
                            </li>
                        ))}
                </ul>
            </div>
        );
    }
);

Dropdown.displayName = 'Dropdown';

export default Dropdown;
