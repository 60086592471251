import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { client } from 'index';

import {
    QUERY_GET_WORDS_TIME_SERIES,
    QUERY_GET_REFERENCES_TIME_SERIES
} from 'api/activity';

import {
    TimeSeriesType,
    TimeSeriesDataType,
    TimeSeriesRange
} from 'types/activity';

export type TimeSeriesState = {
    isLoading: boolean;
    activeRange: TimeSeriesRange;
    month?: {
        data: TimeSeriesDataType[];
        range: 'month';
    };
    day?: {
        data: TimeSeriesDataType[];
        range: 'day';
    };
    week?: {
        data: TimeSeriesDataType[];
        range: 'week';
    };
};

export type ActivityState = {
    isLoading: boolean;
    timeseries: {
        words: TimeSeriesState;
        references: TimeSeriesState;
    };
};

type ActivitySlice = {
    activity: ActivityState;
};

const initialState: ActivityState = {
    isLoading: true,
    timeseries: {
        words: {
            isLoading: false,
            activeRange: 'month'
        },
        references: {
            isLoading: false,
            activeRange: 'month'
        }
    }
};

export const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        setIsActivityLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateWordsTimeSeries: (state, action) => {
            state.timeseries.words = {
                ...state.timeseries.words,
                ...action.payload
            };
        },
        updateReferencesTimeSeries: (state, action) => {
            state.timeseries.references = {
                ...state.timeseries.references,
                ...action.payload
            };
        },
        resetWordsTimeSeries: (state, action) => {
            state.timeseries.words = action.payload;
        },
        resetReferencesTimeSeries: (state, action) => {
            state.timeseries.references = action.payload;
        }
    }
});

export const {
    setIsActivityLoading,
    updateWordsTimeSeries,
    updateReferencesTimeSeries,
    resetWordsTimeSeries,
    resetReferencesTimeSeries
} = activitySlice.actions;

// SELECTORS
export const selectActivityLoading = (state: ActivitySlice) =>
    state.activity.isLoading;

// THUNKS
export const thunkGetWordTimeSeries =
    (range?: TimeSeriesRange) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        await dispatch(updateWordsTimeSeries({ isLoading: true }));

        const state = getState();
        const {
            users: {
                userInfo: { userID }
            },
            activity: {
                timeseries: {
                    words: { activeRange }
                }
            }
        } = state;

        try {
            const { data, error } = await client.query<{
                getWordTimeSeries: TimeSeriesType;
            }>({
                query: QUERY_GET_WORDS_TIME_SERIES,
                variables: { userID, range: range || activeRange },
                fetchPolicy: 'no-cache'
            });

            if (error) {
                toast.error('Error fetching words data');
                dispatch(
                    updateWordsTimeSeries({
                        isLoading: false,
                        activeRange: range || activeRange
                    })
                );
            }

            if (data && data.getWordTimeSeries) {
                const dataByRange = {
                    [data.getWordTimeSeries.range]: data.getWordTimeSeries
                };

                await dispatch(
                    updateWordsTimeSeries({
                        ...dataByRange,
                        isLoading: false,
                        activeRange: data.getWordTimeSeries.range
                    })
                );
            }
        } catch (err) {
            // console.log(err);
            dispatch(
                updateWordsTimeSeries({
                    isLoading: false,
                    activeRange: range || activeRange
                })
            );
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkGetReferencesTimeSeries =
    (range?: TimeSeriesRange) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        dispatch(updateReferencesTimeSeries({ isLoading: true }));

        const state = getState();
        const {
            users: {
                userInfo: { userID }
            },
            activity: {
                timeseries: {
                    references: { activeRange }
                }
            }
        } = state;

        try {
            const { data, error } = await client.query<{
                getReferencesTimeSeries: TimeSeriesType;
            }>({
                query: QUERY_GET_REFERENCES_TIME_SERIES,
                variables: { userID, range: range || activeRange },
                fetchPolicy: 'no-cache'
            });

            if (error) {
                toast.error('Error fetching rewwinds data');
                dispatch(
                    updateReferencesTimeSeries({
                        isLoading: false,
                        activeRange: range || activeRange
                    })
                );
            }

            if (data && data.getReferencesTimeSeries) {
                const dataByRange = {
                    [data.getReferencesTimeSeries.range]:
                        data.getReferencesTimeSeries
                };

                await dispatch(
                    updateReferencesTimeSeries({
                        ...dataByRange,
                        isLoading: false,
                        activeRange: data.getReferencesTimeSeries.range
                    })
                );
            }
        } catch (err) {
            dispatch(
                updateReferencesTimeSeries({
                    isLoading: false,
                    activeRange: range || activeRange
                })
            );
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkResetActivitySlice =
    () => (dispatch: React.Dispatch<any>) => {
        dispatch(setIsActivityLoading(true));
        dispatch(
            resetWordsTimeSeries({ isLoading: false, activeRange: 'month' })
        );
        dispatch(
            resetReferencesTimeSeries({
                isLoading: false,
                activeRange: 'month'
            })
        );
    };

//SELECTORS
export const selectTimeSeries = (state: ActivitySlice) =>
    state.activity.timeseries;

export default activitySlice.reducer;
