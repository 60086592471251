import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { client } from 'index';

import { setShowSideBar } from './app';

import {
    QUERY_GET_USER_NOTES,
    MUTATION_UPDATE_USER_NOTE,
    MUTATION_ADD_USER_NOTE,
    MUTATION_DELETE_USER_NOTE
} from 'api/notes';

import { Note } from 'types/note';

export type NotesState = {
    notes: Note[];
    editActive: {
        active: boolean;
        note: Note | null;
    };
    isLoading: boolean;
};

type NotesSlice = {
    notes: NotesState;
};

const initialState: NotesState = {
    notes: [],
    editActive: {
        active: false,
        note: null
    },
    isLoading: true
};

export const notesSlice = createSlice({
    name: 'notes',
    initialState,
    reducers: {
        setIsNotesLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        addNote: (state, action) => {
            state.notes.unshift(action.payload);
        },
        setNotes: (state, action) => {
            state.notes = action.payload;
        },
        deleteNote: (state, action) => {
            state.notes.splice(action.payload, 1);
        },
        setEditActive: (state, action) => {
            state.editActive = action.payload;
        },
        updateNote: (state, action) => {
            const {
                payload: { noteToUpdateIndex, updatedItems }
            } = action;

            state.notes[noteToUpdateIndex] = {
                ...state.notes[noteToUpdateIndex],
                ...updatedItems
            };
        }
    }
});

export const {
    setIsNotesLoading,
    addNote,
    setNotes,
    deleteNote,
    setEditActive,
    updateNote
} = notesSlice.actions;

// THUNKS
export const thunkAddNote =
    (note: Note) => async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            }
        } = state;

        try {
            const { data, errors } = await client.mutate<{
                addUserNote: boolean;
            }>({
                mutation: MUTATION_ADD_USER_NOTE,
                variables: { note, userID }
            });

            if (errors) {
                toast.error('Error adding note');
            }

            if (data && data.addUserNote) {
                await dispatch(addNote(note));

                toast.success('Note added');
            } else {
                toast.error('Error adding note');
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkGetNotes =
    () => async (dispatch: React.Dispatch<any>, getState: any) => {
        dispatch(setShowSideBar({ show: false, type: null }));

        const state = getState();
        const {
            users: {
                userInfo: { userID }
            },
            notes: { notes: stateNotes }
        } = state;

        try {
            if (stateNotes.length === 0) {
                const { data, error } = await client.query<{
                    getUserNotes: Note[];
                }>({
                    query: QUERY_GET_USER_NOTES,
                    variables: { userID }
                });

                if (error) {
                    toast.error(error.message);
                }

                if (data && data.getUserNotes) {
                    const notes = data.getUserNotes.slice();
                    const sortedNotes = notes.sort(
                        (a: Note, b: Note) =>
                            new Date(b.dateAdded).getTime() -
                            new Date(a.dateAdded).getTime()
                    );

                    dispatch(setNotes(sortedNotes));
                }
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        } finally {
            dispatch(setIsNotesLoading(false));
        }
    };

export const thunkDeleteNote =
    (noteID: string) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            },
            notes: { notes: stateNotes }
        } = state;

        try {
            const noteToDeleteIndex = stateNotes.findIndex(
                (item: Note) => item.noteID === noteID
            );

            if (noteToDeleteIndex >= 0) {
                const { data, errors } = await client.mutate<{
                    deleteUserNote: boolean;
                }>({
                    mutation: MUTATION_DELETE_USER_NOTE,
                    variables: { noteID, userID }
                });

                if (errors) {
                    toast.error('Error deleting note');
                }

                if (data && data.deleteUserNote) {
                    dispatch(deleteNote(noteToDeleteIndex));

                    toast.success('Note deleted');
                } else {
                    toast.error('Error deleting note');
                }
            } else {
                toast.error('Note not found, please refresh');
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkUpdateUserNote =
    (noteID: string, updatedItems: Note) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            },
            notes: { notes: stateNotes }
        } = state;

        try {
            const noteToUpdateIndex: number = stateNotes.findIndex(
                (item: Note) => item.noteID === noteID
            );

            if (noteToUpdateIndex >= 0) {
                const updatedNote = {
                    ...stateNotes[noteToUpdateIndex],
                    ...updatedItems
                };

                const { data, errors } = await client.mutate<{
                    updateUserNote: boolean;
                }>({
                    mutation: MUTATION_UPDATE_USER_NOTE,
                    variables: { updatedNote, userID }
                });

                if (errors) {
                    toast.error('Error updating note');
                }

                if (data && data.updateUserNote) {
                    await dispatch(
                        updateNote({ noteToUpdateIndex, updatedItems })
                    );

                    toast.success('Note updated');
                } else {
                    toast.error('Error updating note');
                }
            } else {
                toast.error('Note not found, please refresh');
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkResetNotesSlice = () => (dispatch: React.Dispatch<any>) => {
    dispatch(setNotes([]));
    dispatch(setIsNotesLoading(true));
    dispatch(setEditActive({ active: false, note: null }));
};

// SELECTORS
export const selectNotesLoading = (state: NotesSlice): boolean =>
    state.notes.isLoading;
export const selectNotes = (state: NotesSlice): Note[] => state.notes.notes;
export const selectEditActive = (
    state: NotesSlice
): { active: boolean; note: Note | null } => state.notes.editActive;

export default notesSlice.reducer;
