import React, { useState } from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip as ChartTooltip,
    ResponsiveContainer
} from 'recharts';
import { useMediaQuery } from 'react-responsive';

import SectionHeader from 'shared/components/SectionHeader';
import SmallLoader from 'shared/components/SmallLoader';
import Picker from 'shared/components/Picker';
import NoData from 'shared/components/NoData';

import { TimeSeriesDataType } from 'types/activity';

import styles from './Graphs.module.scss';

const DATE_RANGES = [
    { display: '6m', range: 'month' },
    { display: '30d', range: 'day' },
    { display: '1w', range: 'week' }
];
const GRAPH_HEIGHT = 140;

type CustomTooltipProps = {
    active?: boolean;
    payload?: any[];
    label?: string;
    title: string;
    subKeyTitle?: string;
    dataKey: string;
    subKey?: string;
};

const CustomTooltip = ({
    active,
    payload,
    label,
    title,
    subKeyTitle,
    dataKey,
    subKey
}: CustomTooltipProps) => {
    if (active && payload && payload.length) {
        const dataValue = payload[0].payload[dataKey];
        const dataStroke = payload[0].stroke;

        let subValue;
        let subStroke;
        let tooltipLabel;

        if (subKey) {
            subValue = payload[1].payload[subKey];
            subStroke = payload[1].stroke;
        }

        if (
            'month' in payload[0].payload &&
            payload[0].payload.month !== null
        ) {
            tooltipLabel = payload[0].payload.month.period;
        }

        return (
            <div className={styles.graphTooltip}>
                <p
                    className={styles.tooltipTitle}
                    style={{ color: dataStroke }}
                >
                    {dataValue}{' '}
                    {dataValue > 1 ? title : title.slice(0, title.length - 1)}
                </p>
                {subKey && (
                    <p
                        className={styles.tooltipTitle}
                        style={{ color: subStroke }}
                    >
                        {subValue}{' '}
                        {subValue > 1 && subKeyTitle
                            ? subKeyTitle
                            : subKeyTitle?.slice(0, subKeyTitle.length - 1)}
                    </p>
                )}
                <p className={styles.tooltipSubTitle}>
                    {tooltipLabel || label}
                </p>
            </div>
        );
    }

    return null;
};

type GraphsProps = {
    data?: TimeSeriesDataType[];
    dataKey: string;
    subKey?: string;
    xAxisKey?: string;
    title: string;
    subTitle?: string;
    subKeyTitle?: string;
    gradientId?: string;
    subKeyGradientId?: string;
    stroke?: string;
    subKeyStroke?: string;
    onChange?: (...args: any[]) => void;
    isLoading?: boolean;
    onClick?: (...args: any[]) => void;
    showPicker?: boolean;
    showHeader?: boolean;
};

export default function Graphs({
    data,
    dataKey,
    subKey,
    xAxisKey,
    title,
    subTitle,
    subKeyTitle,
    gradientId,
    subKeyGradientId,
    stroke,
    subKeyStroke,
    onChange,
    isLoading,
    onClick,
    showPicker,
    showHeader
}: GraphsProps) {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' });
    const pickerItems = DATE_RANGES.map((item) => ({
        id: item.display,
        display: item.display,
        range: item.range
    }));
    const [selectedRange, setSelectedRange] = useState(pickerItems[0]);
    const handleDateRangeChange = (value: any) => {
        setSelectedRange(value);
        onChange && onChange(value);
    };
    const handleOnClick = (data: any) => {
        onClick && onClick(data);
    };
    const getXInterval = () => {
        if (isMobile) {
            if (
                selectedRange.range === 'month' ||
                selectedRange.range === 'week'
            )
                return 2;
            else if (selectedRange.range === 'day') return 3;
        }

        return 1;
    };

    return (
        <div className={styles.graphContainer}>
            {showHeader && (
                <div
                    style={{
                        padding: '0 20px 20px',
                        borderBottom: '2px solid #111725',
                        margin: '0 0 25px'
                    }}
                >
                    <SectionHeader
                        componentTag='h3'
                        title={title}
                        subTitle={subTitle}
                        renderRight={() =>
                            showPicker && (
                                <Picker
                                    pickerItems={pickerItems}
                                    selectedItem={selectedRange}
                                    onChange={handleDateRangeChange}
                                />
                            )
                        }
                    />
                </div>
            )}
            {isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: `${GRAPH_HEIGHT}px`
                    }}
                >
                    <SmallLoader />
                </div>
            ) : (
                <>
                    {data && data.length > 0 ? (
                        <ResponsiveContainer width='100%' height={GRAPH_HEIGHT}>
                            <AreaChart
                                width={600}
                                height={300}
                                data={data}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 20
                                }}
                                onClick={handleOnClick}
                                // @ts-expect-error TS(2322): Type '{ children: ("" | Element | undefined)[]; wi... Remove this comment to see the full error message
                                cursor='pointer'
                            >
                                <defs>
                                    <linearGradient
                                        id='colorWordsCount'
                                        x1='0'
                                        y1='0'
                                        x2='0'
                                        y2='1'
                                    >
                                        <stop
                                            offset='5%'
                                            stopColor={stroke}
                                            stopOpacity={0.8}
                                        />
                                        <stop
                                            offset='95%'
                                            stopColor={stroke}
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                </defs>
                                <defs>
                                    <linearGradient
                                        id='colorReferencesCount'
                                        x1='0'
                                        y1='0'
                                        x2='0'
                                        y2='1'
                                    >
                                        <stop
                                            offset='5%'
                                            stopColor={stroke}
                                            stopOpacity={0.8}
                                        />
                                        <stop
                                            offset='95%'
                                            stopColor={stroke}
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                </defs>
                                <defs>
                                    <linearGradient
                                        id={subKeyGradientId}
                                        x1='0'
                                        y1='0'
                                        x2='0'
                                        y2='1'
                                    >
                                        <stop
                                            offset='5%'
                                            stopColor={subKeyStroke}
                                            stopOpacity={0.8}
                                        />
                                        <stop
                                            offset='95%'
                                            stopColor={subKeyStroke}
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                </defs>
                                <XAxis
                                    dataKey={xAxisKey}
                                    tick={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '11px'
                                    }}
                                    tickLine={false}
                                    tickSize={20}
                                    axisLine={false}
                                    interval={getXInterval()}
                                />
                                <YAxis
                                    tick={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '11px'
                                    }}
                                    tickLine={false}
                                    tickSize={20}
                                    axisLine={false}
                                    interval={1}
                                    allowDecimals={false}
                                />
                                <ChartTooltip
                                    cursor={false}
                                    content={
                                        <CustomTooltip
                                            title={title}
                                            dataKey={dataKey}
                                            subKey={subKey}
                                            subKeyTitle={subKeyTitle}
                                        />
                                    }
                                />
                                <Area
                                    type='monotone'
                                    dataKey={dataKey}
                                    stroke={stroke}
                                    strokeWidth={2}
                                    activeDot={{ stroke: stroke }}
                                    fillOpacity={0.5}
                                    fill={`url(#${gradientId})`}
                                />
                                {subKey && (
                                    <Area
                                        type='monotone'
                                        dataKey={subKey}
                                        stroke={subKeyStroke}
                                        strokeWidth={2}
                                        activeDot={{ stroke: subKeyStroke }}
                                        fillOpacity={0.5}
                                        fill={`url(#${subKeyGradientId})`}
                                    />
                                )}
                            </AreaChart>
                        </ResponsiveContainer>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: `${GRAPH_HEIGHT}px`
                            }}
                        >
                            <NoData
                                subTitle={`No ${title.toLowerCase()} data`}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
