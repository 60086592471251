import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { IoClose } from 'react-icons/io5';

import { thunkSearchWord } from 'redux/slices/words';
import { setShowSideBar } from 'redux/slices/app';

import WordVariationIdentifier from 'views/Words/WordVariationIdentifier';
import SelectorItem from './SelectorItem';

import * as ROUTES from 'constants/routes';

import { Word, Variation } from 'types/word';
import { Collection } from 'types/collection';

import './Selector.scss';

type Props<T> = {
    words?: Word[];
    items?: T[];
    word?: Word | string;
    type?: 'word' | 'collection' | 'variation';
    allowSearch?: boolean;
    allowMultiple?: boolean;
    identifier: 'collectionID' | 'word' | 'variationID';
    onSelected: (selected: T[]) => void;
};

const Selector = <T extends Record<string, unknown>>({
    items,
    words,
    type,
    allowSearch,
    allowMultiple,
    identifier,
    onSelected
}: Props<T>) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState<T[]>([]);
    const [wordsToShow, setWordsToShow] = useState(words);
    const [searchInput, setSearchInput] = useState('');
    const [showSearchCanel, setShowSearchCancel] = useState<boolean>(false);

    const handleAdd = (itemToAdd: T) => {
        const hasItem = selected.some(
            (item) => item[identifier] === itemToAdd[identifier]
        );

        if (!hasItem) {
            const selectedItems = [...selected, itemToAdd];
            setSelected(selectedItems);
            onSelected(selectedItems);
        }
    };
    const handleRemove = (itemToRemove: T) => {
        const selectedItems = selected.filter(
            (item) => item[identifier] !== itemToRemove[identifier]
        );

        setSelected(selectedItems);
        onSelected(selectedItems);
    };
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);

        if (e.target.value.length > 0) {
            setShowSearchCancel(true);
            const filteredWords = words?.filter((item) =>
                item.word.includes(e.target.value.toLowerCase())
            );
            setWordsToShow(filteredWords);
        } else {
            setShowSearchCancel(false);
            setWordsToShow(words);
        }
    };
    const handleSearchCancel = () => {
        setSearchInput('');
        setShowSearchCancel(false);
        setWordsToShow(words);
    };
    const handleWordSearch = () => {
        setSearchInput('');
        setShowSearchCancel(false);
        dispatch(
            setShowSideBar({
                show: false,
                type: null,
                data: { showVariations: true }
            })
        );

        history.push(ROUTES.SEARCH);
        dispatch(thunkSearchWord(searchInput.trim()));
    };

    const itemDetails = (
        word: Word | null,
        collection: Collection | null,
        variation: Variation | null
    ) => {
        if (type === 'collection' && collection) {
            const onlyWords = words?.map((item) => item.word);
            const undeletedWords = collection.words.filter((item) => {
                if (onlyWords?.includes(item)) {
                    return item;
                }
            });

            return (
                <>
                    <h4>{collection.name}</h4>
                    <p>
                        <span>{undeletedWords.length}</span>{' '}
                        {undeletedWords.length === 1 ? 'word' : 'words'} in this
                        collection
                    </p>
                </>
            );
        } else if (type === 'word' && word) {
            return <h4>{word.word}</h4>;
        } else if (type === 'variation' && variation) {
            return (
                <h4>
                    <WordVariationIdentifier variation={variation} />
                    {variation.definition}
                </h4>
            );
        }
    };

    const renderSelectorItems = () => {
        if (type === 'collection') {
            return (
                <>
                    {items?.map((collection) => (
                        <SelectorItem
                            key={collection['collectionID'] as string}
                            item={collection}
                            onAdd={handleAdd}
                            onRemove={handleRemove}
                            selected={selected}
                            identifier={identifier}
                            itemDetails={itemDetails(
                                null,
                                collection as unknown as Collection,
                                null
                            )}
                            allowMultiple={allowMultiple}
                        />
                    ))}
                </>
            );
        } else if (type === 'word') {
            return (
                <>
                    {wordsToShow && wordsToShow.length > 0 ? (
                        wordsToShow.map((word) => (
                            <SelectorItem
                                key={word['word']}
                                item={word as unknown as T}
                                onAdd={handleAdd}
                                onRemove={handleRemove}
                                identifier={identifier}
                                selected={selected}
                                itemDetails={itemDetails(word, null, null)}
                                allowMultiple={allowMultiple}
                            />
                        ))
                    ) : (
                        <p className='searchWord' onClick={handleWordSearch}>
                            Search for <span>{searchInput}</span>
                        </p>
                    )}
                </>
            );
        } else if (type === 'variation') {
            return (
                <>
                    {items?.map((variation) => (
                        <SelectorItem
                            key={variation['variationID'] as string}
                            item={variation}
                            onAdd={handleAdd}
                            onRemove={handleRemove}
                            identifier={identifier}
                            selected={selected}
                            itemDetails={itemDetails(
                                null,
                                null,
                                variation as unknown as Variation
                            )}
                        />
                    ))}
                </>
            );
        }
    };

    return (
        <>
            {allowMultiple && selected.length > 0 && (
                <span className='selectedIndicator'>
                    {selected.length} selected
                </span>
            )}
            {allowSearch && (
                <div className='selectorSearch'>
                    <input
                        type='text'
                        placeholder='Search'
                        value={searchInput}
                        onChange={handleSearchChange}
                    />
                    {showSearchCanel && (
                        <IoClose
                            className='icon actionIcon'
                            onClick={handleSearchCancel}
                        />
                    )}
                </div>
            )}
            <div className='selectorItems'>{renderSelectorItems()}</div>
        </>
    );
};

export default Selector;
