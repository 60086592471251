import React from 'react';
import { useDispatch } from 'react-redux';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';
import classNames from 'classnames';

import { thunkShowSideBar } from 'redux/slices/app';

import WordOptions from '../WordOptions';
import DateTime from 'shared/components/DateTime';
import WordVariationIdentifier from '../WordVariationIdentifier';

import { Word } from 'types/word';
import { Collection } from 'types/collection';

import './WordListItem.scss';

type Props = {
    word: Word;
    section?: string;
    activeCollection?: Collection;
    isMultiSelectEnabled?: boolean;
    onSelect?: (value: Word) => void;
    isSelected?: boolean;
    showDetails?: boolean;
};

const WordListItem = ({
    word,
    section,
    activeCollection,
    showDetails,
    isMultiSelectEnabled,
    onSelect,
    isSelected
}: Props) => {
    const dispatch = useDispatch();
    const moreThanOneVariations = word.variations.length > 1;
    const defaultVariation = word.variations
        .filter((item) => item.defaultVariation === true)
        .map((item) => item);
    const variationToShow = () => {
        if (defaultVariation.length > 0) {
            return defaultVariation;
        } else {
            return word.variations.slice(-1);
        }
    };
    const renderDetails = () => {
        return variationToShow().map((variation) => (
            <div className='wordListDetails' key={variation.variationID}>
                <p>
                    <WordVariationIdentifier variation={variation} />
                    {variation.definition}
                </p>
                <div className='listItemControls'>
                    <div className='macros'>
                        <span className='partOfSpeech'>
                            {variation.partOfSpeech}
                        </span>
                        <DateTime type='timeAgo' date={variation.dateAdded} />
                    </div>
                </div>
            </div>
        ));
    };
    const showSearch = section === 'collections' ? false : true;
    const showAddToCollection = section === 'collections' ? false : true;
    const showRemoveFromCollection = () => {
        if (activeCollection) {
            return section === 'collections' &&
                activeCollection.words.includes(word.word)
                ? true
                : false;
        }

        return false;
    };
    const wordListItemClass = classNames('wordListItem', {
        wordListItem__selected: isSelected && isMultiSelectEnabled
    });
    const handleSelect = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (onSelect) {
            onSelect(word);
        }
    };
    const handleItemClick = (e: React.MouseEvent) => {
        if (isMultiSelectEnabled) {
            handleSelect(e);
        } else {
            dispatch(thunkShowSideBar(word, 'wordDetails'));
        }
    };

    return (
        <div className={wordListItemClass} onClick={handleItemClick}>
            <div className='wordListItemHeader'>
                <h3>
                    {word.word}&nbsp;
                    <WordVariationIdentifier word={word} />
                </h3>

                {isMultiSelectEnabled ? (
                    <span style={{ height: '23px' }}>
                        {isSelected ? (
                            <IoMdRadioButtonOn
                                className='icon actionIcon'
                                onClick={(e) => handleSelect(e)}
                            />
                        ) : (
                            <IoMdRadioButtonOff
                                className='icon actionIcon'
                                onClick={(e) => handleSelect(e)}
                            />
                        )}
                    </span>
                ) : (
                    <WordOptions
                        word={word}
                        showSearch={showSearch}
                        showAddToCollection={showAddToCollection}
                        showRemoveFromCollection={showRemoveFromCollection()}
                        // isMultiSelectEnabled={isMultiSelectEnabled}
                        // onSelect={onSelect}
                        // isSelected
                    />
                )}
            </div>
            {showDetails && renderDetails()}
            {moreThanOneVariations && (
                <span className='wordListItemFooter'>
                    {word.variations.length}
                </span>
            )}
        </div>
    );
};

export default WordListItem;
