import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HiOutlineCollection } from 'react-icons/hi';
import { useMediaQuery } from 'react-responsive';
import { FiBookmark } from 'react-icons/fi';
import { TbNotes } from 'react-icons/tb';

import { selectHeader, thunkShowSideBar } from 'redux/slices/app';

import Button from 'shared/components/Button/Button';

import styles from './Header.module.scss';

const Header = () => {
    const dispatch = useDispatch();
    const { page } = useSelector(selectHeader);
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' });

    const handleAddWordClick = () => {
        dispatch(thunkShowSideBar(null, 'addWord'));
    };
    const handleAddNoteClick = () => {
        dispatch(thunkShowSideBar(null, 'addNote'));
    };
    const handleNewCollectionClick = () => {
        dispatch(thunkShowSideBar(null, 'newCollection'));
    };

    return (
        <div className={styles.header}>
            <div className={styles.headerLeft}>
                {/* {!isMobile && <h2>{page}</h2>} */}
            </div>
            <div className={styles.headerRight}>
                {page !== 'account' && (
                    <>
                        {page === 'collections' ? (
                            <>
                                <div className={styles.mobileButton}>
                                    <div className='collectionsRenderRight'>
                                        <Button
                                            onClick={handleNewCollectionClick}
                                            buttonCategory='iconAlternate'
                                        >
                                            <HiOutlineCollection className='icon buttonIcon' />
                                        </Button>
                                    </div>
                                </div>
                                <div className={styles.webButton}>
                                    <div className='collectionsRenderRight'>
                                        <Button
                                            onClick={handleNewCollectionClick}
                                            buttonCategory='secondary'
                                        >
                                            <HiOutlineCollection className='icon buttonIcon' />
                                            &nbsp; New Collection
                                        </Button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {page === 'notes' ? (
                                    <>
                                        <div className={styles.mobileButton}>
                                            <Button
                                                onClick={handleAddNoteClick}
                                                buttonCategory='iconAlternate'
                                            >
                                                <TbNotes className='icon buttonIcon' />
                                            </Button>
                                        </div>
                                        <div className={styles.webButton}>
                                            <Button
                                                onClick={handleAddNoteClick}
                                                buttonCategory='secondary'
                                            >
                                                <TbNotes className='icon buttonIcon' />
                                                &nbsp; New note
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={styles.mobileButton}>
                                            <Button
                                                onClick={handleAddWordClick}
                                                buttonCategory='iconAlternate'
                                            >
                                                <FiBookmark className='icon buttonIcon' />
                                            </Button>
                                        </div>
                                        <div className={styles.webButton}>
                                            <Button
                                                onClick={handleAddWordClick}
                                                buttonCategory='secondary'
                                            >
                                                <FiBookmark className='icon buttonIcon' />
                                                &nbsp; Add word
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Header;
