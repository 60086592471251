import React from 'react';
import classNames from 'classnames';

import SmallLoader from '../SmallLoader';

import './Button.scss';

type Props = {
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    children?: React.ReactNode;
    isLoading?: boolean;
    buttonClass?: string;
    buttonType?: 'submit' | 'reset' | 'button' | undefined;
    buttonDisabled?: boolean;
    buttonCategory?: string;
    buttonSize?: 'small' | 'large';
    className?: string;
};

const Button = React.forwardRef<HTMLButtonElement, Props>(
    (
        {
            onClick,
            children,
            isLoading,
            buttonClass,
            buttonType = 'button',
            buttonDisabled,
            buttonCategory,
            buttonSize = 'large',
            className
        },
        ref?: React.Ref<HTMLButtonElement>
    ) => {
        const styles = classNames(buttonClass, className, {
            buttonCategory__icon: buttonCategory === 'icon',
            buttonCategory__icon__small:
                buttonCategory === 'icon' && buttonSize === 'small',
            buttonCategory__icon__alternate: buttonCategory === 'iconAlternate',
            buttonCategory__icon__alternate__small:
                buttonCategory === 'iconAlternate' && buttonSize === 'small',
            buttonCategory__link: buttonCategory === 'link',
            buttonCategory__secondary: buttonCategory === 'secondary'
        });

        return (
            <button
                type={buttonType}
                onClick={onClick}
                className={styles}
                disabled={buttonDisabled || isLoading}
                ref={ref}
            >
                {isLoading ? <SmallLoader /> : <span>{children}</span>}
            </button>
        );
    }
);

Button.displayName = 'Button';

export default Button;
