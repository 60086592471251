import React from 'react';
import { FiInfo } from 'react-icons/fi';

import Picker from 'shared/components/Picker';
import Tooltip from 'shared/components/Tooltip';

import { WordPermissionsType } from 'types/settings';

import styles from './WordPermissionsToggle.module.scss';

type Props = {
    onToggle: (value: boolean) => void;
    wordPermissions?: any;
    isDisabled?: boolean;
};

const WordPermissionsToggle = ({
    onToggle,
    wordPermissions,
    isDisabled
}: Props) => {
    const pickerItems = [
        {
            id: 'private',
            display: 'Private',
            type: 'private',
            onClick: () => onToggle(false)
        },
        {
            id: 'public',
            display: 'Public',
            type: 'public',
            onClick: () => onToggle(true)
        }
    ];

    return (
        <div className={styles.wordPermissionsToggle}>
            <Picker
                pickerItems={pickerItems}
                selectedItem={wordPermissions}
                isDisabled={isDisabled}
            >
                <Tooltip content='coming soon' placement='top'>
                    <span style={{ marginLeft: '5px' }}>
                        <FiInfo className='icon identifierIcon' />
                    </span>
                </Tooltip>
            </Picker>
        </div>
    );
};

export default WordPermissionsToggle;
