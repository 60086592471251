import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectWords, thunkGetWords } from 'redux/slices/words';
import {
    setIsActivityLoading,
    selectActivityLoading,
    thunkGetWordTimeSeries,
    thunkGetReferencesTimeSeries
} from 'redux/slices/activity';
import { thunkPageReset } from 'redux/slices/app';
import { selectWordsReferences } from 'redux/slices/references';
import { selectActiveCollection } from 'redux/slices/collections';

import WordSummary from './WordSummary';
import RecentlyAddedWords from './RecentlyAddedWords';
import Loader from 'shared/components/Loader';
import Header from 'components/Header';
import ActivityGraphs from './ActivityGraphs';
import IncaseYouForgot from './IncaseYouForgot';
import ActivityStats from './ActivityStats';

import { Word, Variation } from 'types/word';

import styles from './Activity.module.scss';

export default function Activity() {
    const dispatch = useDispatch();
    const words = useSelector(selectWords);
    const activeCollection = useSelector(selectActiveCollection);
    const references = useSelector(selectWordsReferences);
    const isLoading = useSelector(selectActivityLoading);
    const sortedVariations = useMemo(
        () =>
            words
                .reduce((acc: Variation[], cur: Word) => {
                    const variationsLength = cur.variations.length;
                    acc = [...acc, cur.variations[variationsLength - 1]];

                    return acc;
                }, [])
                .sort(
                    (a: Variation, b: Variation) =>
                        new Date(b.dateAdded).getTime() -
                        new Date(a.dateAdded).getTime()
                ),
        [words]
    );
    const lastTenWords = useMemo(() => {
        if (sortedVariations.length) {
            return sortedVariations
                .slice(0, 10)
                .map(
                    (item: Variation) =>
                        words.find((word) => word.word === item.word)!
                );
        }

        return [];
    }, [sortedVariations, words]);

    useEffect(() => {
        dispatch(thunkPageReset('activity'));

        async function getWords() {
            dispatch(thunkGetWords());
            dispatch(setIsActivityLoading(false));
        }

        getWords();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function getWordsTimeSeries() {
            dispatch(thunkGetWordTimeSeries());
        }

        if (words.length > 0) {
            getWordsTimeSeries();
        }
    }, [words]);

    useEffect(() => {
        async function getReferencesTimeSeries() {
            dispatch(thunkGetReferencesTimeSeries());
        }

        if (references.length > 0) {
            getReferencesTimeSeries();
        }
    }, [references]);

    return (
        <>
            <Header />
            {isLoading ? (
                <div className={styles.activityLoaderContainer}>
                    <Loader />
                </div>
            ) : (
                <div className={styles.activityContainer}>
                    <div className={styles.activityMain}>
                        <ActivityGraphs words={words} references={references} />
                        {!!words.length && (
                            <div className={styles.activitySummary}>
                                <WordSummary
                                    words={words}
                                    references={references}
                                    lastTenWords={lastTenWords}
                                />
                                {words.length > 3 && (
                                    <IncaseYouForgot words={words} />
                                )}
                                <RecentlyAddedWords
                                    references={references}
                                    lastTenWords={lastTenWords}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.activityStats}>
                        <ActivityStats words={words} references={references} />
                    </div>
                </div>
            )}
        </>
    );
}
