import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import uniqid from 'uniqid';
import 'tippy.js/dist/tippy.css';

import { PARTS_OF_SPEECH } from 'constants/wordsConstants';

import Button from 'shared/components/Button';
import NotVerified from 'shared/components/NotVerified';
import WordPermissionsToggle from '../WordPermissionsToggle';

import { thunkAddWord, selectWords } from 'redux/slices/words';
import {
    selectUserInfo,
    selectUserVerified,
    selectUserSettings
} from 'redux/slices/users';

import { Word } from 'types/word';
import { WordReference } from 'types/reference';

import styles from './AddWord.module.scss';

type FormValues = {
    word: string;
    partOfSpeech: string;
    definition: string;
    reference: string;
    examples: string;
};

const AddWord = () => {
    const dispatch = useDispatch();
    const words = useSelector(selectWords);
    const userInfo = useSelector(selectUserInfo);
    const userVerified = useSelector(selectUserVerified);
    const allWords = useSelector(selectWords);
    const { wordPermissions } = useSelector(selectUserSettings);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [wordExists, setWordExists] = useState<boolean>(false);
    const [isPublic, setIsPublic] = useState(
        wordPermissions.id === 'public' ? true : false
    );
    const [disablePermissions, setDisablePermissions] = useState<boolean>(true);
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm<FormValues>({ mode: 'onChange' });
    const watchRequiredFields = watch(['word', 'definition']);
    const buttonDisabled = () => {
        const haveValues = watchRequiredFields.every(
            (value) => value && value.trim() !== ''
        );

        return !haveValues || buttonLoading;
    };
    const onSubmit = async (data: FormValues) => {
        setButtonLoading(true);
        const { word, definition, reference, partOfSpeech, examples } = data;
        const variationID = uniqid(`${word.trim().toLowerCase()}-`);
        const trimmedExamples = examples.split(',').map((item) => item.trim());
        const dateAdded = new Date().toISOString();
        const wordToAdd = {
            word: word.toLowerCase().trim(),
            variations: [
                {
                    definition: definition.toLowerCase(),
                    dateAdded,
                    partOfSpeech,
                    examples: trimmedExamples,
                    defaultVariation: false,
                    addedManually: true,
                    variationID,
                    word: word.toLowerCase().trim(),
                    isPublic
                }
            ]
        } as Word;
        const referenceToAdd =
            reference.trim() !== ''
                ? ({
                      word: wordToAdd.word,
                      references: [
                          {
                              dateAdded,
                              referenceID: uniqid(),
                              reference: reference.trim(),
                              isPublic: isPublic
                          }
                      ]
                  } as WordReference)
                : null;

        await dispatch(thunkAddWord(wordToAdd, referenceToAdd));
        setButtonLoading(false);
        reset({
            word: '',
            definition: '',
            partOfSpeech: '',
            reference: '',
            examples: ''
        });
        setDisablePermissions(true);
    };
    const handleWordPermissionsToggle = (permission: boolean) => {
        setIsPublic(permission);
    };
    const handleWordOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newWord = e.target.value;
        const exists = !!allWords.filter(
            (word) => word.word === newWord.toLowerCase()
        ).length;

        setWordExists(exists);
    };
    const userHasWord = words.some((item) => item.word === watch('word'));

    if (!userVerified && userInfo) {
        return <NotVerified userInfo={userInfo} isLinkOnly={false} />;
    }

    return (
        <form
            className={styles.addWordContainer}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className={styles.addWordOverview}>
                <p>
                    You can add <span>anything</span> that does not appear in a
                    search!
                </p>
            </div>
            <div className={styles.addWordSection}>
                <input
                    {...register('word', {
                        required: {
                            value: true,
                            message: 'Word is required'
                        }
                    })}
                    placeholder='Enter word, phrase, slang etc.'
                    type='text'
                    onChange={handleWordOnChange}
                    autoComplete='off'
                ></input>
                {wordExists && (
                    <span className='notice notice__right'>
                        Word exixts. Only variation will be added
                    </span>
                )}
                <span className='notice notice__right'>required</span>
            </div>
            {errors.word && (
                <div className={styles.errorMessage}>{errors.word.message}</div>
            )}
            <div className={styles.addWordSection}>
                <select {...register('partOfSpeech')}>
                    <option value='' disabled selected>
                        Select a Part of Speech
                    </option>
                    {PARTS_OF_SPEECH.map((partOfSpeech) => (
                        <option value={partOfSpeech} key={partOfSpeech}>
                            {partOfSpeech}
                        </option>
                    ))}
                </select>
            </div>
            {errors.partOfSpeech && (
                <div className={styles.errorMessage}>
                    {errors.partOfSpeech.message}
                </div>
            )}
            <div className={styles.addWordSection}>
                <input
                    {...register('definition', {
                        required: {
                            value: true,
                            message: 'Definition is required'
                        }
                    })}
                    placeholder='Enter definition'
                    type='text'
                    autoComplete='off'
                ></input>
                <span className='notice notice__right'>required</span>
            </div>
            {errors.definition && (
                <div className={styles.errorMessage}>
                    {errors.definition.message}
                </div>
            )}
            <div className={styles.addWordSection}>
                <textarea
                    {...register('examples')}
                    placeholder='Enter example(s)'
                    rows={5}
                    autoComplete='off'
                ></textarea>
                <span className='notice notice__right'>
                    If adding multiple examples, separate with a comma
                </span>
            </div>
            {errors.examples && (
                <div className={styles.errorMessage}>
                    {errors.examples.message}
                </div>
            )}
            <div className={styles.addWordSection}>
                <textarea
                    {...register('reference')}
                    placeholder={
                        userHasWord
                            ? 'How have you re-discovered this word?'
                            : 'How did you discover this word?'
                    }
                    rows={7}
                    autoComplete='off'
                    // onChange={(e) => handleReferenceOnChange(e)}
                ></textarea>
            </div>
            <div className={styles.addWordSection}>
                <WordPermissionsToggle
                    onToggle={handleWordPermissionsToggle}
                    wordPermissions={wordPermissions}
                    isDisabled={disablePermissions}
                />
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    buttonType='submit'
                    buttonDisabled={buttonDisabled()}
                    isLoading={buttonLoading}
                >
                    Add Word
                </Button>
            </div>
        </form>
    );
};

export default AddWord;
