import React from 'react';

import Pill from 'shared/components/Pill';
import Modal from 'shared/components/Modal';

import { Stat } from '../ActivityStats';

type Props = {
    isModalOpen?: boolean;
    onClose?: () => void;
    archived: Stat;
};

export default function ArchivedModal({
    isModalOpen,
    onClose,
    archived
}: Props) {
    const archivedWords = archived?.words;
    const onPillClick = () => {
        onClose && onClose();
    };

    return (
        <Modal
            show={isModalOpen}
            onClose={onClose}
            title='Archived'
            subTitle={`${archived.value} words`}
        >
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {archivedWords?.map((word) => (
                    <Pill
                        key={word.word}
                        item={word.word}
                        type='word'
                        onClick={onPillClick}
                    />
                ))}
            </div>
        </Modal>
    );
}
