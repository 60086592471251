import React from 'react';
import classNames from 'classnames';

import styles from './SectionHeader.module.scss';

type Props = {
    componentTag: string;
    title?: string;
    subTitle?: string | React.ReactNode;
    type?: string;
    renderRight?: () => React.ReactNode;
    renderLeft?: () => React.ReactNode;
    isPageTitle?: boolean;
};

const SectionHeader = ({
    componentTag,
    title,
    subTitle,
    type,
    renderRight,
    renderLeft,
    isPageTitle
}: Props) => {
    const ComponentTag = componentTag;
    const sectionHeaderContainerClass = classNames(
        styles.sectionHeaderContainer,
        {
            [styles.sectionHeaderContainer__isPageTitle]: isPageTitle
        }
    );
    const titleClass = classNames(styles.title, {
        [styles.title__word]: type === 'word'
    });

    return (
        <div className={sectionHeaderContainerClass}>
            <div className={styles.titleContainer}>
                {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
                <ComponentTag className={titleClass}>
                    {title}&nbsp;{renderLeft && renderLeft()}
                </ComponentTag>
                {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
            </div>
            {renderRight && <div className='renderRight'>{renderRight()}</div>}
        </div>
    );
};

export default SectionHeader;
