import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectSearchedWordList, selectWords } from 'redux/slices/words';
import { selectAllWordsReferences } from 'redux/slices/references';

import SearchListItem from './SearchListItem';
import Loader from 'shared/components/Loader';

import { Word, WordListResult } from 'types/word';
import { Reference } from 'types/reference';

import './SearchList.scss';

type UserWordsProps = {
    userWords: Word[];
};

type SearchReference = Reference & { word: string };

const UserWords = ({ userWords }: UserWordsProps) => {
    return (
        <div className='userWords'>
            <h3>Your Words ({userWords.length})</h3>
            {userWords.length > 0 ? (
                <ul>
                    {userWords.map((word) => (
                        <SearchListItem
                            key={word.word}
                            item={word}
                            userHasWord={true}
                            type='word'
                        />
                    ))}
                </ul>
            ) : (
                <p>You have no words</p>
            )}
        </div>
    );
};

type NewWordsProps = {
    list: WordListResult[];
    isLoading?: boolean;
    error?: boolean;
    onSearchClick?: (value: string) => void;
};

const NewWords = ({
    list = [],
    isLoading,
    error,
    onSearchClick
}: NewWordsProps) => {
    return (
        <div className='newWords'>
            {isLoading ? (
                <div style={{ height: '200px' }}>
                    <Loader size='small' />
                </div>
            ) : list.length > 0 ? (
                <>
                    <h3>Search Results ({list.length})</h3>
                    <ul>
                        {list.map((word) => (
                            <SearchListItem
                                key={word.word}
                                item={word}
                                userHasWord={false}
                                type='word'
                                onSearch={onSearchClick}
                            />
                        ))}
                    </ul>
                </>
            ) : (
                <p className='noResults'>
                    {error
                        ? 'Error getting suggestions. Hit enter to search word'
                        : 'No words found'}
                </p>
            )}
        </div>
    );
};

type ReferencesProps = {
    userReferences: Reference[];
    searchInput?: string;
};

const References = ({ userReferences, searchInput }: ReferencesProps) => {
    return (
        <div className='references'>
            <h3>Rewwinds ({userReferences.length})</h3>
            <ul>
                {userReferences.map((item) => (
                    <SearchListItem
                        key={item.referenceID}
                        item={item}
                        userHasWord={true}
                        type='reference'
                        searchInput={searchInput}
                    />
                ))}
            </ul>
        </div>
    );
};

type SearchListProps = {
    searchInput?: string;
    onSearchClick?: (value: string) => void;
};

const SearchList = ({ searchInput = '', onSearchClick }: SearchListProps) => {
    const searchedWordList = useSelector(selectSearchedWordList);
    const wordReferences = useSelector(selectAllWordsReferences);
    const words = useSelector(selectWords);
    const [userWords, setUserWords] = useState<Word[]>([]);
    const [userReferences, setUserReferences] = useState<SearchReference[]>([]);
    const {
        isLoading,
        list,
        searchedWord,
        error: searchListError
    } = searchedWordList;

    const allReferences = useMemo(
        () =>
            wordReferences.reduce((acc: SearchReference[], cur) => {
                cur.references.forEach((item) => {
                    const refObject = {
                        ...item,
                        word: cur.word
                    };

                    acc.push(refObject);
                });
                return acc;
            }, []),
        [wordReferences]
    );

    useEffect(() => {
        const searchedWordInWords = words.filter((word) => {
            if (searchedWord) {
                return word.word.includes(searchedWord.toLowerCase());
            }
        });
        setUserWords(searchedWordInWords);
    }, [searchInput, searchedWordList, searchedWord, words]);

    useEffect(() => {
        const filteredReferences = allReferences.filter((item) => {
            return item.reference
                .toLowerCase()
                .includes(searchInput?.toLowerCase());
        });

        setUserReferences(filteredReferences);
    }, [searchInput, allReferences]);

    return (
        <div className='searchListContainer'>
            <div className='searchList'>
                {words.length > 0 && userWords.length > 0 && (
                    <UserWords userWords={userWords} />
                )}
                {wordReferences.length > 0 && userReferences.length > 0 && (
                    <References
                        userReferences={userReferences}
                        searchInput={searchInput}
                    />
                )}
                <NewWords
                    list={list}
                    isLoading={isLoading}
                    error={searchListError}
                    onSearchClick={onSearchClick}
                />
            </div>
        </div>
    );
};

export default SearchList;
