import { createSlice } from '@reduxjs/toolkit';

import { thunkSetActiveWord } from './words';

import { Word } from 'types/word';

type Page =
    | 'words'
    | 'notes'
    | 'collections'
    | 'activity'
    | 'community'
    | 'account'
    | 'search';

type SideBarType =
    | 'wordDetails'
    | 'addWord'
    | 'addNote'
    | 'editNote'
    | 'newCollection'
    | 'editCollection'
    | 'addToCollection'
    | 'addWordToCollection'
    | 'graphDetails'
    | 'activity'
    | null;

export type ShowSideBar = {
    show: boolean;
    type: SideBarType;
    header: string;
    data?: any;
    enableClose?: boolean;
};

export type AppState = {
    showSideBar: ShowSideBar;
    showModal: {
        show: boolean;
    };
    header: {
        page: Page;
    };
};

type AppSlice = {
    app: AppState;
};

const initialState: AppState = {
    showSideBar: {
        show: false,
        type: null,
        header: ''
    },
    showModal: {
        show: false
    },
    header: {
        page: 'words'
    }
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setShowSideBar: (state, action) => {
            state.showSideBar = action.payload;
        },
        setHeader: (state, action) => {
            state.header = action.payload;
        }
    }
});

export const { setShowSideBar, setHeader } = appSlice.actions;

// THUNKS
export const thunkShowSideBar =
    (word: Word | null, type: SideBarType, data?: any) =>
    async (dispatch: React.Dispatch<any>) => {
        switch (type) {
            case 'wordDetails':
                if (word !== null) {
                    dispatch(thunkSetActiveWord(word.word));
                    dispatch(
                        setShowSideBar({
                            show: true,
                            type,
                            header: word.word,
                            enableClose: true,
                            data: word
                        })
                    );
                }
                break;
            case 'addWord':
                dispatch(
                    setShowSideBar({
                        show: true,
                        type,
                        header: 'Add Word',
                        enableClose: true
                    })
                );
                break;
            case 'addNote':
                dispatch(
                    setShowSideBar({
                        show: true,
                        type,
                        header: 'Add Note',
                        enableClose: true
                    })
                );
                break;
            case 'editNote':
                dispatch(
                    setShowSideBar({
                        show: true,
                        type,
                        header: 'Edit Note',
                        enableClose: true
                    })
                );
                break;
            case 'newCollection':
                dispatch(
                    setShowSideBar({
                        show: true,
                        type,
                        header: 'New Collection',
                        enableClose: true
                    })
                );
                break;
            case 'editCollection':
                dispatch(
                    setShowSideBar({
                        show: true,
                        type,
                        header: 'Edit Collection',
                        data,
                        enableClose: true
                    })
                );
                break;
            case 'addToCollection':
                if (word !== null) {
                    await dispatch(thunkSetActiveWord(word.word));
                    dispatch(
                        setShowSideBar({
                            show: true,
                            type,
                            header: `Add ${word.word} to a collection`,
                            enableClose: true
                        })
                    );
                }
                break;
            case 'addWordToCollection':
                dispatch(
                    setShowSideBar({
                        show: true,
                        type,
                        header: `Choose words to add`,
                        data,
                        enableClose: true
                    })
                );
                break;
            case 'graphDetails':
                dispatch(
                    setShowSideBar({
                        show: true,
                        type,
                        header: `Activity`,
                        // data,
                        enableClose: true
                    })
                );
                break;
            default:
                break;
        }
    };

export const thunkPageReset =
    (page: Page, data?: any) => (dispatch: React.Dispatch<any>) => {
        dispatch(setShowSideBar({ show: false, type: null }));
        dispatch(setHeader({ page, data }));
    };

export const thunkResetAppSlice = () => (dispatch: React.Dispatch<any>) => {
    dispatch(setShowSideBar({ show: false, type: null }));
};

// SELECTORS
export const selectShowSideBar = (state: AppSlice): ShowSideBar =>
    state.app.showSideBar;
export const selectHeader = (state: AppSlice): { page: Page } =>
    state.app.header;

export default appSlice.reducer;
