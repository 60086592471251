import React, { useState, useEffect } from 'react';
import { VscSplitHorizontal, VscArchive } from 'react-icons/vsc';
import { RiPushpin2Line } from 'react-icons/ri';
import { FiGrid } from 'react-icons/fi';
import classNames from 'classnames';

import { Note } from 'types/note';
import { DisplayTypeOptions, NoteDefaultViewType } from 'types/settings';

type Props = {
    notes: Note[];
    noteDisplayType: DisplayTypeOptions;
    noteDefaultView: NoteDefaultViewType;
    onShowArchivedNotes: (value: boolean) => void;
    onShowPinnedNotes: (value: boolean) => void;
    onDisplayChange: (value: DisplayTypeOptions) => void;
};

type ArchivedSortType = 'archived' | 'non-archived';
type PinnedSortType = 'pinned' | 'unpinned';
type SortType = ArchivedSortType | PinnedSortType;

const NoteSort = ({
    notes,
    noteDisplayType,
    noteDefaultView,
    onShowArchivedNotes,
    onShowPinnedNotes,
    onDisplayChange
}: Props) => {
    const [sortActive, setSortActive] = useState<boolean>(false);
    const [nextArchivedSortType, setNextArchivedSortType] =
        useState<ArchivedSortType>('archived');
    const [nextPinnedSortType, setNextPinnedSortType] =
        useState<PinnedSortType>('pinned');
    const [currentSortType, setCurrentSortType] =
        useState<SortType>('non-archived');
    const sortItemActiveClass = classNames('sortItem', {
        sortItem__active: sortActive
    });
    const sortTypeClass = classNames('sortType', {
        sortType__active: sortActive
    });
    const handleSort = (type: SortType) => {
        switch (type) {
            case 'archived':
                setSortActive(true);
                setCurrentSortType('archived');
                setNextArchivedSortType('non-archived');
                setNextPinnedSortType('pinned');
                onShowArchivedNotes(true);
                break;
            case 'non-archived':
                setCurrentSortType('non-archived');
                setNextArchivedSortType('archived');
                onShowArchivedNotes(false);
                break;
            case 'pinned':
                setSortActive(true);
                setCurrentSortType('pinned');
                setNextArchivedSortType('archived');
                setNextPinnedSortType('unpinned');
                onShowPinnedNotes(true);
                break;
            case 'unpinned':
                setCurrentSortType('unpinned');
                setNextPinnedSortType('pinned');
                onShowPinnedNotes(false);
                break;
            default:
                break;
        }
    };
    const handleDisplayChange = (display: DisplayTypeOptions) => {
        onDisplayChange(display);
    };
    const archivedNotesCount = notes.filter((note) => {
        return note.archived === true;
    }).length;
    const pinnedNotesCount = notes.filter((note) => {
        return note.pinned === true;
    }).length;
    const archivedIconTitle =
        nextArchivedSortType === 'non-archived'
            ? 'hide archived notes'
            : 'show archived notes';
    const pinnedIconTitle =
        nextPinnedSortType === 'unpinned'
            ? 'hide pinned notes'
            : 'show pinned notes';

    useEffect(() => {
        if (noteDefaultView.view === 'pinned') {
            handleSort('pinned');
        }
    }, [noteDefaultView]);

    return (
        <div className='sortContainer noteSortContainer'>
            <div
                className={
                    noteDisplayType === 'grid' ||
                    noteDisplayType === 'horizontal'
                        ? 'sortType sortType__active'
                        : 'sortType'
                }
            >
                <div
                    className={
                        noteDisplayType === 'horizontal'
                            ? 'sortItem sortItem__active'
                            : 'sortItem'
                    }
                    title='horizontal display'
                    onClick={() => handleDisplayChange('horizontal')}
                >
                    <VscSplitHorizontal />
                </div>
                <div
                    className={
                        noteDisplayType === 'grid'
                            ? 'sortItem sortItem__active'
                            : 'sortItem'
                    }
                    title='grid display'
                    onClick={() => handleDisplayChange('grid')}
                >
                    <FiGrid />
                </div>
            </div>
            {archivedNotesCount > 0 && (
                <div
                    className={
                        currentSortType === 'archived'
                            ? sortTypeClass
                            : 'sortType'
                    }
                >
                    <div
                        className={
                            currentSortType === 'archived'
                                ? sortItemActiveClass
                                : 'sortItem'
                        }
                        onClick={() => handleSort(nextArchivedSortType)}
                        title={archivedIconTitle}
                    >
                        <VscArchive className='archivedIcon' />
                    </div>
                    {currentSortType === 'archived' && (
                        <span className='archivedCount'>
                            {archivedNotesCount}
                        </span>
                    )}
                </div>
            )}
            {pinnedNotesCount > 0 && (
                <div
                    className={
                        currentSortType === 'pinned'
                            ? sortTypeClass
                            : 'sortType'
                    }
                >
                    <div
                        className={
                            currentSortType === 'pinned'
                                ? sortItemActiveClass
                                : 'sortItem'
                        }
                        onClick={() => handleSort(nextPinnedSortType)}
                        title={pinnedIconTitle}
                    >
                        <RiPushpin2Line className='pinIcon' />
                    </div>
                    {currentSortType === 'pinned' && (
                        <span className='archivedCount'>
                            {pinnedNotesCount}
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export default NoteSort;
