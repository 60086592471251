import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FiLogOut, FiCheck, FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { resetUsersSlice } from 'redux/slices/users';
import { thunkResetWordsSlice } from 'redux/slices/words';
import { thunkResetActivitySlice } from 'redux/slices/activity';
import { thunkResetNotesSlice } from 'redux/slices/notes';
import { thunkResetCollectionsSlice } from 'redux/slices/collections';
import { firebaseSignOut } from 'api/auth';

import SmallLoader from 'shared/components/SmallLoader';
import Modal from 'shared/components/Modal';
import Button from 'shared/components/Button';

import * as ROUTES from 'constants/routes';

type Props = {
    isMobile?: boolean;
};

const SignOut = ({ isMobile }: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isSignOutLoading, setIsSignOutLoading] = useState<boolean>(false);
    const handleSignOutClick = async () => {
        try {
            const isSignedOut = await firebaseSignOut();

            if (isSignedOut) {
                dispatch(resetUsersSlice());
                dispatch(thunkResetWordsSlice());
                dispatch(thunkResetActivitySlice());
                dispatch(thunkResetNotesSlice());
                dispatch(thunkResetCollectionsSlice());

                history.push(ROUTES.AUTH);
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };
    const handleSignOut = () => {
        setIsSignOutLoading(true);

        setTimeout(() => {
            handleSignOutClick();
        }, 1000);
    };
    const handleModalOpen = async () => {
        await setIsModalOpen(true);
    };
    const handleModalClose = () => {
        setIsSignOutLoading(false);
        setIsModalOpen(false);
    };

    return (
        <>
            <a onClick={handleModalOpen}>
                <FiLogOut className='icon actionIcon' />
                {isMobile && <span>Sign out</span>}
            </a>
            <Modal
                show={isModalOpen}
                onClose={handleModalClose}
                title='Sign out of Rewwind?'
                isCloseDisabled={isSignOutLoading}
                width='350px'
            >
                <p>
                    You can always log in later, with all your words and
                    rewwinds intact. Come back soon!
                </p>
                <div className='modalFooter'>
                    <Button
                        buttonCategory='icon'
                        buttonDisabled={isSignOutLoading}
                        onClick={handleSignOut}
                    >
                        {isSignOutLoading ? (
                            <SmallLoader />
                        ) : (
                            <FiCheck className='icon buttonIcon' />
                        )}
                    </Button>
                    <Button
                        buttonCategory='iconAlternate'
                        onClick={handleModalClose}
                        buttonDisabled={isSignOutLoading}
                    >
                        <FiX className='icon buttonIcon' />
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default SignOut;
