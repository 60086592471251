import React from 'react';
import classNames from 'classnames';

import Logo from '../Logo';

import './Loader.scss';

type Props = {
  appLoader?: boolean;
  alternate?: boolean;
  size?: string;
};

const Loader = ({ appLoader, alternate, size }: Props) => {
  const loaderClass = classNames('loader', {
    loader__alternate: alternate,
    loader__small: size === 'small',
  });
  const handleClick = () => {
    window.open(
      'https://www.instagram.com/accounts/login/?next=/reel/CcDNpOljyxO/',
      '_blank'
    );
  };

  return (
    <div className='loaderContainer' onClick={handleClick}>
      {appLoader ? (
        <Logo shortForm={true} pulse={true} />
      ) : (
        <div className={loaderClass}>
          {size !== 'small' && <div className='loaderInner'></div>}
        </div>
      )}
    </div>
  );
};

export default Loader;
