import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import classNames from 'classnames';

import { thunkUpdateUserWord } from 'redux/slices/words';

import EditWordDefinition from '../../EditWordDefinition';
import WordVariationIdentifier from '../../WordVariationIdentifier';
import DateTime from 'shared/components/DateTime';
import MoreOptions from 'shared/components/MoreOptions';

import { DropdownOption } from 'shared/components/Dropdown/Dropdown';

import { Word, Variation } from 'types/word';
import { DateFormatType } from 'helpers/helpers';

import styles from './WordDefinitionsItem.module.scss';

type Props = {
    variation: Variation;
    word: Word;
    showOptions?: boolean;
    dateTimeType?: DateFormatType;
};

const WordDefinitionsItem = ({
    word,
    variation,
    showOptions = true,
    dateTimeType = 'timeOnly'
}: Props) => {
    const dispatch = useDispatch();
    const [showDefinitionOptions, setShowDefinitionOptions] =
        useState<boolean>(false);
    const [showEditDefinition, setShowEditDefinition] =
        useState<boolean>(false);
    const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(false);
    const [showOptionsButton, setShowOptionsButton] = useState<boolean>(false);
    const definitionDetailsClass = classNames(styles.definitionDetails, {
        [styles.definitionDetails__blurred]: showDefinitionOptions
    });
    const handleClose = () => {
        setShowEditDefinition(false);
        setShowDefinitionOptions(false);
    };
    const handleDelete = async () => {
        if (word.variations.length > 1) {
            setIsOptionsLoading(true);
            const newVariations = word.variations.filter(
                (item) => item.variationID !== variation.variationID
            );
            const newWord = {
                ...word,
                variations: newVariations
            };

            await dispatch(thunkUpdateUserWord(newWord));

            setIsOptionsLoading(false);
        }
    };
    const handleShowMoreClick = () => {
        setShowDefinitionOptions(!showDefinitionOptions);
    };
    const handleMouseEnter = () => {
        if (!showEditDefinition && showOptions) {
            setShowOptionsButton(true);
        }
    };
    const handleMouseLeave = () => {
        if (showOptions) {
            setShowOptionsButton(false);
            setShowDefinitionOptions(false);
        }
    };
    const handleEdit = () => {
        setShowEditDefinition(!showEditDefinition);
        setShowOptionsButton(false);
    };
    const options: DropdownOption[] = [
        {
            name: 'Edit',
            icon: <FiEdit className='icon actionIcon__small' />,
            onClick: handleEdit,
            disabled: variation.addedManually ? false : true
        },
        {
            name: 'Delete',
            icon:
                word.variations.length > 1 ? (
                    <FiTrash2 className='icon actionIcon__small dangerIcon' />
                ) : (
                    <FiTrash2 className='icon actionIcon__small disabled dangerIcon' />
                ),
            // eslint-disable-next-line no-empty-function
            onClick: word.variations.length > 1 ? handleDelete : () => true,
            disabled: word.variations.length > 1 ? false : true
        }
    ];

    return (
        <li
            className={styles.wordDefinitionsListItem}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className={styles.definition}>
                {showEditDefinition ? (
                    <EditWordDefinition
                        variation={variation}
                        onClose={handleClose}
                    />
                ) : (
                    <p className={definitionDetailsClass}>
                        <DateTime
                            type={dateTimeType}
                            date={variation.dateAdded}
                        />
                        &nbsp;
                        <WordVariationIdentifier variation={variation} />
                        {variation.definition}&nbsp;
                        <span className={styles.definitionItem}>
                            {variation.partOfSpeech}
                        </span>
                    </p>
                )}
            </div>
            <div className={styles.examples}>
                {variation.examples &&
                    variation.examples.map((example, key) => (
                        <div key={key}>
                            <span>{example}</span>
                            {variation.examples &&
                                variation.examples.length - 1 !== key && (
                                    <span className={styles.divider}>
                                        &bull;
                                    </span>
                                )}
                        </div>
                    ))}
            </div>
            {showOptionsButton && (
                <MoreOptions
                    options={options}
                    onClick={handleShowMoreClick}
                    isLoading={isOptionsLoading}
                    showDropdown={showDefinitionOptions}
                    buttonSize='small'
                />
            )}
        </li>
    );
};

export default WordDefinitionsItem;
