import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { isEqual } from 'lodash';
import { IoWarning } from 'react-icons/io5';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import { toast } from 'react-toastify';

import Button from 'shared/components/Button';
import SectionHeader from 'shared/components/SectionHeader';

import { thunkUpdateUserInfo, selectUserVerified } from 'redux/slices/users';
import {
    apiUpdateUserProfileImage,
    apiDeleteUserProfileImage
} from 'api/users';

import { User } from 'types/user';

import './UserSettings.scss';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

registerPlugin(
    FilePondPluginFilePoster,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
    FilePondPluginImageExifOrientation,
    FilePondPluginImageResize
);

type Props = {
    userInfo?: User | null;
};

type FormValues = {
    firstName: string;
    lastName: string;
    bio: string;
};

const UserSettings = ({ userInfo }: Props) => {
    const dispatch = useDispatch();
    const userVerified = useSelector(selectUserVerified);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [newImageFile, setNewImageFile] = useState([]);
    const [currentImageFile, setCurrentImageFile] = useState([]);
    const [imageError, setImageError] = useState(null);
    const [imageButtonLoading, setImageButtonLoading] =
        useState<boolean>(false);
    const [imageDeleteLoading, setImageDeleteLoading] =
        useState<boolean>(false);
    const [imageButtonDisabled, setImageButtonDisabled] =
        useState<boolean>(true);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<FormValues>({
        mode: 'onChange'
    });
    const watchedValues = watch();
    const onSubmit = async () => {
        // setButtonLoading(true);
        const newUserInfo = { ...watchedValues };
        handleImageSubmit();
        // await dispatch(thunkUpdateUserInfo(newUserInfo));

        // setButtonLoading(false);
    };
    const isSaveButtonDisabled = () => {
        const userSettingValues = {
            firstName: userInfo?.firstName,
            lastName: userInfo?.lastName,
            // username: userInfo?.username,
            bio: userInfo?.bio
        };
        const haveValues = watch(['firstName', 'lastName']).some(
            (value: string) => value && value !== ''
        );

        return (
            Object.keys(errors).length > 0 ||
            isEqual(userSettingValues, watchedValues) ||
            !haveValues ||
            buttonLoading
        );
    };
    // console.log('newImageFile:', newImageFile);
    const handleFileUpdate = (files: any) => {
        if (files.length > 0 && imageError === null) {
            setNewImageFile(files);

            if (userInfo?.profileImage) {
                if (files[0].file.name !== userInfo?.profileImage.name) {
                    setImageButtonDisabled(false);
                }
            } else {
                setImageButtonDisabled(false);
            }
        } else {
            setNewImageFile(currentImageFile);
            setImageButtonDisabled(true);
            setImageError(null);
        }
    };
    const handleImageError = (error: any) => {
        if (error) {
            setImageError(error);
            setImageButtonDisabled(true);
        } else {
            setImageError(null);
            setImageButtonDisabled(false);
        }
    };
    const handleImageSubmit = () => {
        const submitImage = async () => {
            setImageButtonLoading(true);
            setImageButtonDisabled(true);

            const currentFileName = userInfo?.profileImage
                ? (newImageFile[0] as any).file.name
                : '';

            try {
                if (userInfo?.userID) {
                    const imageURL = await apiUpdateUserProfileImage(
                        userInfo.userID,
                        (newImageFile[0] as any).file,
                        currentFileName
                    );

                    // console.log(imageURL);

                    if (imageURL && typeof imageURL === 'string') {
                        const newUserInfo = {
                            ...watchedValues,
                            profileImage: {
                                name: (newImageFile[0] as any).file.name,
                                size: (newImageFile[0] as any).file.size,
                                type: (newImageFile[0] as any).file.type,
                                imageURL
                            }
                        };

                        await dispatch(thunkUpdateUserInfo(newUserInfo));
                    }
                }
            } catch (err) {
                toast.error('Error uploading image');
            } finally {
                setImageButtonLoading(false);
                setImageButtonDisabled(false);
            }
        };

        if (newImageFile.length > 0) {
            if (currentImageFile.length > 0) {
                if (
                    !isEqual(
                        (currentImageFile[0] as any).file.name,
                        (newImageFile[0] as any).file.name
                    )
                ) {
                    submitImage();
                }
            } else {
                submitImage();
            }
        }
    };

    const handleSubmitUserInfo = async () => {
        setButtonLoading(true);
        const newUserInfo = {
            ...watchedValues,
            profileImage: {
                ...userInfo?.profileImage
            }
        };

        await dispatch(thunkUpdateUserInfo(newUserInfo));
        setButtonLoading(false);
    };

    const handleImageDelete = async () => {
        setImageDeleteLoading(true);

        const currentFileName = userInfo?.profileImage
            ? (currentImageFile[0] as any).file.name
            : '';

        try {
            if (userInfo?.userID) {
                const imageDeleted = await apiDeleteUserProfileImage(
                    userInfo.userID,
                    currentFileName
                );

                if (imageDeleted) {
                    const newUserInfo = {
                        ...watchedValues,
                        profileImage: null
                    };

                    await dispatch(thunkUpdateUserInfo(newUserInfo));

                    setCurrentImageFile([]);
                    setNewImageFile([]);
                }
            }
        } catch (err) {
            toast.error('Error deleting image');
        } finally {
            setImageDeleteLoading(false);
        }
    };

    useEffect(() => {
        if (userInfo?.profileImage !== null) {
            const files = [
                {
                    file: {
                        name: userInfo?.profileImage.name,
                        size: userInfo?.profileImage.size,
                        type: userInfo?.profileImage.type
                    },
                    source: userInfo?.profileImage.imageURL,
                    options: {
                        type: 'local',
                        file: {
                            name: userInfo?.profileImage.name,
                            size: userInfo?.profileImage.size,
                            type: userInfo?.profileImage.type
                        },
                        metadata: {
                            poster: userInfo?.profileImage.imageURL
                        }
                    }
                }
            ];

            // @ts-expect-error TS(2345): Argument of type '{ file: { name: any; size: any; ... Remove this comment to see the full error message
            setCurrentImageFile(files);
            // @ts-expect-error TS(2345): Argument of type '{ file: { name: any; size: any; ... Remove this comment to see the full error message
            setNewImageFile(files);
        } else {
            setCurrentImageFile([]);
            setNewImageFile([]);
        }
    }, [userInfo]);

    return (
        <>
            {userVerified && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SectionHeader componentTag='h3' title='Profile' />
                    <div className='imageContainer'>
                        <FilePond
                            allowMultiple={false}
                            allowImagePreview={true}
                            allowFileTypeValidation={true}
                            acceptedFileTypes={[
                                'image/png',
                                'image/jpeg',
                                'image/gif',
                                'image/jpg'
                            ]}
                            // labelIdle='Add profile photo'
                            stylePanelLayout='circle'
                            // stylePanelAspectRatio={1}
                            allowImageResize
                            // imagePreviewHeight='100'
                            imageResizeMode='cover'
                            // imageResizeTargetHeight='100'
                            // imageResizeTargetWidth='100'
                            styleButtonRemoveItemPosition='center bottom'
                            files={newImageFile}
                            onupdatefiles={handleFileUpdate}
                            onerror={(error) => handleImageError(error)}
                            labelIdle={
                                "Drag & drop profile photo or <span class='filepond--label-action'>Browse</span>"
                            }
                        />
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                gap: '10px'
                            }}
                        >
                            <Button
                                buttonType='submit'
                                buttonDisabled={imageButtonDisabled}
                                isLoading={imageButtonLoading}
                                onClick={handleImageSubmit}
                            >
                                Save
                            </Button>
                            {currentImageFile.length !== 0 && (
                                <Button
                                    buttonType='submit'
                                    buttonDisabled={
                                        currentImageFile.length === 0
                                    }
                                    isLoading={imageDeleteLoading}
                                    onClick={handleImageDelete}
                                >
                                    Remove
                                </Button>
                            )}
                        </div>
                        {imageError !== null && (
                            <div className='errorMessage'>
                                <IoWarning />
                                {(imageError as any).main}
                            </div>
                        )}
                    </div>
                </form>
            )}
            <div className='form'>
                {!userVerified && (
                    <SectionHeader componentTag='h3' title='Profile' />
                )}
                <div className='infoContainer'>
                    <input
                        {...register('firstName', {
                            required: {
                                value: true,
                                message: 'First name is required'
                            }
                        })}
                        type='text'
                        placeholder={userInfo?.firstName || 'First Name'}
                        value={watch('firstName', userInfo?.firstName)}
                    ></input>
                    {errors.firstName && (
                        <div className='errorMessage'>
                            {errors.firstName.message}
                        </div>
                    )}
                    <input
                        {...register('lastName', {
                            required: {
                                value: true,
                                message: 'Last name is required'
                            }
                        })}
                        type='text'
                        placeholder={userInfo?.lastName || 'Last Name'}
                        value={watch('lastName', userInfo?.lastName)}
                    ></input>
                    {errors.lastName && (
                        <div className='errorMessage'>
                            {errors.lastName.message}
                        </div>
                    )}
                    {/*
    <input
      {...register('username')}
      type='text'
      placeholder={userInfo?.username || 'Username'}
      value={watch('username', userInfo?.username)}
    ></input>
  {errors.username && (
    <div className='errorMessage'>{errors.username.message}</div>
  )} */}
                    <textarea
                        {...register('bio')}
                        placeholder={userInfo?.bio || 'Bio'}
                        value={watch('bio', userInfo?.bio)}
                        rows={7}
                    ></textarea>
                    {errors.bio && (
                        <div className='errorMessage'>{errors.bio.message}</div>
                    )}
                    <Button
                        buttonDisabled={isSaveButtonDisabled()}
                        buttonType='submit'
                        isLoading={buttonLoading}
                        onClick={handleSubmitUserInfo}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </>
    );
};

export default UserSettings;
