import React from 'react';
import { useDispatch } from 'react-redux';

import DateTime from 'shared/components/DateTime';

import { formatDate } from 'helpers/helpers';
import { thunkShowSideBar } from 'redux/slices/app';

import { Word } from 'types/word';
import { WordReference } from 'types/reference';

import './WordSummary.scss';

type Props = {
    words: Word[];
    references: WordReference[];
    lastTenWords: Word[];
};

const WordSummary = ({ words, references, lastTenWords }: Props) => {
    const dispatch = useDispatch();
    const lastWord = lastTenWords[0];
    const wordsCount = words.length;
    const variationsCount = words.reduce((acc, cur) => {
        return cur.variations.length + acc;
    }, 0);
    const rewwindsCount = references.reduce((acc, cur) => {
        return cur.references.length + acc;
    }, 0);
    const lastWordDate =
        lastWord.variations[lastWord.variations.length - 1].dateAdded;
    const handleOpenSideBar = () => {
        dispatch(thunkShowSideBar(lastWord, 'wordDetails'));
    };

    return (
        <div className='wordSummaryContainer'>
            <div className='wordSummaryMain'>
                <h1>{wordsCount}</h1>
                <p>{wordsCount > 1 ? 'words' : 'word'}</p>
            </div>
            <div className='wordSummaryDetails'>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '10px'
                    }}
                >
                    <div>
                        <p>variations</p>
                        <span>{variationsCount}</span>
                    </div>
                    <div>
                        <p>rewwinds</p>
                        <span>{rewwindsCount}</span>
                    </div>
                    <div>
                        <p>last word</p>
                        <span className='lastWord' onClick={handleOpenSideBar}>
                            {lastWord.word}
                        </span>
                    </div>
                </div>
                <div>
                    <div
                        title={
                            formatDate(lastWordDate, 'dateAndTime') as string
                        }
                    >
                        <p>last added</p>
                        <DateTime type='timeAgoFull' date={lastWordDate} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WordSummary;
