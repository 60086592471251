import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { formatDate } from 'helpers/helpers';
import { selectUserVerified } from 'redux/slices/users';

import AddWordReference from '../AddWordReference';
import WordReferencesItem from './WordReferencesItem';

import { Word } from 'types/word';
import { WordReference, Reference } from 'types/reference';

import './WordReferences.scss';

type Props = {
    word: Word;
    references: Reference[];
};

const WordReferences = ({ word, references }: Props) => {
    const userVerified = useSelector(selectUserVerified);
    const hasReferences = references.length > 0;
    const allReferences = useMemo(
        () =>
            [...references].sort(
                (a, b) =>
                    new Date(b.dateAdded).getTime() -
                    new Date(a.dateAdded).getTime()
            ),
        [references]
    );
    const referencesByDate = useMemo(
        () =>
            allReferences.reduce(
                (acc: Record<string, Reference[]>, curr: Reference) => {
                    const formattedDate = formatDate(
                        curr.dateAdded,
                        'dateOnly'
                    ) as string;

                    if (!acc[formattedDate]) {
                        acc[formattedDate] = [curr];
                    } else {
                        acc[formattedDate].push(curr);
                    }

                    return acc;
                },
                {}
            ),
        [allReferences]
    );

    const referenceCount = allReferences.length;

    return (
        <div className='referenceContainer' key='key'>
            <div className='referenceHeader'>
                <h4>Rewwinds {referenceCount > 0 && `(${referenceCount})`}</h4>
            </div>
            {hasReferences ? (
                <ul>
                    {Object.keys(referencesByDate).map((date) => {
                        return (
                            <li key={date} className='dateListItem'>
                                <span className='dateAdded'>{date}</span>
                                <ul className='referenceList'>
                                    {referencesByDate[date].map(
                                        (reference: Reference) => (
                                            <WordReferencesItem
                                                word={word}
                                                reference={reference}
                                                key={reference.referenceID}
                                            />
                                        )
                                    )}
                                </ul>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <p className='noReference'>No rewwinds</p>
            )}
            {userVerified && (
                <AddWordReference word={word} references={references} />
            )}
        </div>
    );
};

export default WordReferences;
