import React from 'react';
import { FiX, FiCheck } from 'react-icons/fi';
import classNames from 'classnames';

import Button from '../../Button';

import './SelectorItem.scss';

type Props<T> = {
    item: T;
    onAdd: (item: T) => void;
    onRemove: (item: T) => void;
    selected: T[];
    itemDetails: any;
    allowMultiple?: boolean;
    identifier: 'collectionID' | 'word' | 'variationID';
};

const SelectorItem = <T extends Record<string, unknown>>({
    item,
    onAdd,
    onRemove,
    identifier,
    selected,
    itemDetails,
    allowMultiple
}: Props<T>) => {
    const isSelected = (itemToCheck: T) => {
        return selected.some(
            (item) => item[identifier] === itemToCheck[identifier]
        );
    };

    const selectorDetailsClass = classNames('selectorDetails', {
        selectorDetails__selected: isSelected(item)
    });

    return (
        <div className='selectorItem'>
            <div className={selectorDetailsClass}>{itemDetails}</div>
            {isSelected(item) && (
                <Button
                    buttonCategory='icon'
                    buttonSize='small'
                    onClick={() => onRemove(item)}
                >
                    <FiX className='icon buttonIcon' />
                </Button>
            )}
            {allowMultiple
                ? !isSelected(item) && (
                      <Button
                          buttonCategory='icon'
                          buttonSize='small'
                          onClick={() => onAdd(item)}
                      >
                          <FiCheck className='icon buttonIcon' />
                      </Button>
                  )
                : selected.length === 0 && (
                      <Button
                          buttonCategory='icon'
                          buttonSize='small'
                          onClick={() => onAdd(item)}
                      >
                          <FiCheck className='icon buttonIcon' />
                      </Button>
                  )}
        </div>
    );
};

export default SelectorItem;
