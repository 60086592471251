import React from 'react';
import { useSelector } from 'react-redux';

import { selectActiveWordDetails } from 'redux/slices/words';

import Pill from 'shared/components/Pill';

import { WordDetails } from 'types/word';

import './WordDetailsItem.scss';

type Props = {
    section: keyof WordDetails;
};

const WordDetailsItem = ({ section }: Props) => {
    const activeWordDetails = useSelector(selectActiveWordDetails);
    const { details } = activeWordDetails;
    const hasDetails = details[section] && details[section].length > 0;
    const sectionType = typeof details[section];

    return (
        <div className='searchDetailsContainer'>
            {hasDetails && (
                <>
                    <h4>{section}</h4>
                    <div className='searchDetailsItems'>
                        {sectionType === 'object' ? (
                            <>
                                {details[section].map((item, key) => (
                                    <Pill key={key} item={item} type='word' />
                                ))}
                            </>
                        ) : (
                            <p>{details[section]}</p>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default WordDetailsItem;
