import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as ROUTES from 'constants/routes';

import { selectAuthUser } from 'redux/slices/users';

import Loader from 'shared/components/Loader';

type ProtectedRouteType = {
    children: JSX.Element;
    isLoading: boolean;
};

const ProtectedRoute = ({ children, isLoading }: ProtectedRouteType) => {
    const authUser = useSelector(selectAuthUser);

    if (!isLoading) {
        if (!authUser || authUser === null) {
            return <Redirect to={ROUTES.AUTH} />;
        }

        return children;
    }

    return <Loader appLoader={true} />;
};

export default ProtectedRoute;
