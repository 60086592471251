import { gql } from '@apollo/client';

export const QUERY_SEARCH_WORD = gql`
    query searchWordQuery($word: String!) {
        searchWord(word: $word) {
            word
            results {
                definition
                partOfSpeech
                examples
            }
        }
    }
`;

export const QUERY_SEARCHED_WORD_LIST = gql`
    query searchWordListQuery($word: String!) {
        searchWordList(word: $word) {
            word
            results {
                word
                score
            }
        }
    }
`;

export const QUERY_SEARCH_WORD_DETAILS = gql`
    query searchWordDetailsQuery($word: String!) {
        searchWordDetails(word: $word) {
            synonyms
            antonyms
            rhymes
            pronunciation
        }
    }
`;

export const QUERY_GET_WORD_PRONUNCIATION = gql`
    query getWordPronunciation(
        $word: String!
        $voice: String!
        $speechSpeed: Int!
        $language: String!
    ) {
        getWordPronunciation(
            word: $word
            voice: $voice
            speechSpeed: $speechSpeed
            language: $language
        ) {
            data
            error
        }
    }
`;

export const QUERY_GET_USER_WORDS = gql`
    query getUserWords($userID: ID!) {
        getUserWords(userID: $userID) {
            words {
                word
                variations {
                    variationID
                    dateAdded
                    word
                    definition
                    partOfSpeech
                    examples
                    addedManually
                    defaultVariation
                    isPublic
                }
                archived
                favorite
            }
        }
    }
`;

export const MUTATION_ADD_USER_WORD_VARIATION = gql`
    mutation addUserWordVariation(
        $variation: WordVariationInput!
        $word: String!
        $userID: ID!
    ) {
        addUserWordVariation(
            variation: $variation
            word: $word
            userID: $userID
        )
    }
`;

export const MUTATION_ADD_USER_WORD = gql`
    mutation addUserWord($word: WordInput!, $userID: ID!) {
        addUserWord(word: $word, userID: $userID)
    }
`;

export const MUTATION_UPDATE_USER_WORD = gql`
    mutation updateUserWord($newWord: WordInput!, $userID: ID!) {
        updateUserWord(newWord: $newWord, userID: $userID)
    }
`;

export const MUTATION_UPDATE_USER_WORDS = gql`
    mutation updateUserWords($newWords: [WordInput]!, $userID: ID!) {
        updateUserWords(newWords: $newWords, userID: $userID)
    }
`;

export const MUTATION_DELETE_USER_WORD = gql`
    mutation deleteUserWord($word: String!, $userID: ID!) {
        deleteUserWord(word: $word, userID: $userID)
    }
`;

export const MUTATION_DELETE_USER_WORDS = gql`
    mutation deleteUserWords($words: [String]!, $userID: ID!) {
        deleteUserWords(words: $words, userID: $userID)
    }
`;

export const MUTATION_SET_USER_WORD_DEFAULT_VARIATION = gql`
    mutation setUserWordDefaultVariation(
        $newDefaultVariation: WordVariationInput!
        $oldDefaultVariation: WordVariationInput
        $userID: ID!
    ) {
        setUserWordDefaultVariation(
            newDefaultVariation: $newDefaultVariation
            oldDefaultVariation: $oldDefaultVariation
            userID: $userID
        )
    }
`;

export const MUTATION_UPDATE_USER_WORD_VARIATION = gql`
    mutation updateUserWordVariation(
        $newVariation: WordVariationInput!
        $userID: ID!
    ) {
        updateUserWordVariation(newVariation: $newVariation, userID: $userID)
    }
`;
