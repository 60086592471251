import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VscArchive } from 'react-icons/vsc';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { thunkUpdateSettings, selectUserSettings } from 'redux/slices/users';

import NotesListItem from '../NotesListItem';
import NoteSort from '../NoteSort';
import NoData from 'shared/components/NoData';
import Pill from 'shared/components/Pill';

import { Note } from 'types/note';
import { UserSettings } from 'types/user';
import { DisplayTypeOptions } from 'types/settings';

import './NotesList.scss';

type Props = {
    notes: Note[];
};

const NotesList = ({ notes }: Props) => {
    const dispatch = useDispatch();
    const { noteDisplayType, noteDefaultView } =
        useSelector(selectUserSettings);
    const [showArchives, setShowArchives] = useState<boolean>(false);
    const [showPinned, setShowPinned] = useState(
        () => noteDefaultView?.view === 'pinned'
    );
    const [noteListDisplay, setNoteListDisplay] = useState(
        noteDisplayType?.type
    );
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' });
    const hasOnlyArchives = notes.every((note) => note.archived === true);
    const handleShowArchives = (shouldShow: boolean) => {
        setShowPinned(false);
        setShowArchives(shouldShow);
    };
    const handleShowPinned = (shouldShow: boolean) => {
        setShowArchives(false);
        setShowPinned(shouldShow);
    };
    const notesToShow = useMemo(() => {
        let result;

        if (showArchives) {
            const archivedNotes = notes.filter(
                (note) => note.archived === true
            );

            if (archivedNotes.length === 0) {
                result = notes.filter((note) => note.archived === false);
            } else {
                result = notes.filter((note) => note.archived === true);
            }
        } else if (showPinned) {
            const pinnedNotes = notes.filter((note) => note.pinned === true);

            if (pinnedNotes.length === 0) {
                result = notes.filter((note) => note.pinned === false);
            } else {
                result = notes.filter((note) => note.pinned === true);
            }
        } else {
            result = notes.filter((note) => note.archived === false);
        }

        return result.sort(
            (a: Note, b: Note) =>
                new Date(b.dateAdded).getTime() -
                new Date(a.dateAdded).getTime()
        );
    }, [notes, showArchives, showPinned]);

    const notesCount = isMobile
        ? `${notesToShow.length} of ${notes.length}`
        : `Showing ${notesToShow.length} of ${notes.length}`;

    const notesListClass = classNames('notesList', {
        notesList__grid: noteListDisplay === 'grid'
    });

    const handleDisplayChange = (display: DisplayTypeOptions) => {
        setNoteListDisplay(display);

        const newSetting = {
            noteDisplayType: { type: display }
        };

        dispatch(thunkUpdateSettings(newSetting as UserSettings, false));
    };

    return (
        <div className='notesListContainer'>
            <div className='notesSortContainer'>
                <NoteSort
                    notes={notes}
                    noteDisplayType={noteListDisplay}
                    noteDefaultView={noteDefaultView}
                    onShowArchivedNotes={handleShowArchives}
                    onShowPinnedNotes={handleShowPinned}
                    onDisplayChange={handleDisplayChange}
                />
                <Pill item={notesCount} />
            </div>
            {hasOnlyArchives && !showArchives && !showPinned ? (
                <NoData
                    title='All your notes are archived'
                    subTitle='Click the archive icon above to view them'
                    icon={<VscArchive />}
                />
            ) : (
                <div className={notesListClass}>
                    {notesToShow.map((note) => (
                        <NotesListItem
                            note={note}
                            key={note.noteID}
                            noteDisplayType={noteListDisplay}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default NotesList;
