import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    thunkGetWordTimeSeries,
    thunkGetReferencesTimeSeries,
    selectTimeSeries
} from 'redux/slices/activity';

import Graphs from 'components/Graphs';
import ActivityGraphsDetails from '../ActivityGraphsDetails';
import Modal from 'shared/components/Modal';

import { Word } from 'types/word';
import { WordReference } from 'types/reference';

import { TimeSeriesDataType, TimeSeriesRange } from 'types/activity';
import { TimeSeriesState } from 'redux/slices/activity';

import styles from './ActivityGraphs.module.scss';

type Props = {
    words: Word[];
    references: WordReference[];
};

type ActiveGraph = TimeSeriesDataType & {
    name?: 'wordsCount' | 'referencesCount';
};

export default function ActivityGraphs({ words, references }: Props) {
    const dispatch = useDispatch();
    const timeSeries = useSelector(selectTimeSeries);
    const [wordsData, setWordsData] = useState<TimeSeriesState>(
        timeSeries.words
    );
    const [referencesData, setReferencesData] = useState<TimeSeriesState>(
        timeSeries.references
    );
    const [wordsRange, setWordsRange] = useState<TimeSeriesRange>('month');
    const [referencesRange, setReferencesRange] =
        useState<TimeSeriesRange>('month');
    const [activeGraph, setActiveGraph] = useState<ActiveGraph>(
        {} as ActiveGraph
    );
    const [activeGraphRange, setActiveGraphRange] = useState('month');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleWordGraphChange = useCallback(
        (value: any) => {
            if (words.length > 0) {
                dispatch(thunkGetWordTimeSeries(value.range));
            }

            setWordsRange(value.range);
        },
        [words, wordsRange]
    );
    const handleReferencesGraphChange = useCallback(
        (value: any) => {
            if (references.length > 0) {
                dispatch(thunkGetReferencesTimeSeries(value.range));
            }

            setReferencesRange(value.range);
        },
        [references, referencesRange]
    );
    const handleGraphClick = (value: any) => {
        if (value !== null) {
            setActiveGraph(value.activePayload[0]);

            if (value.activePayload[0].payload.month !== null) {
                setActiveGraphRange('month');
            } else {
                setActiveGraphRange('day');
            }

            setIsModalOpen(true);
        }
    };
    const handleModalClose = () => {
        setIsModalOpen(false);
        setActiveGraph({} as ActiveGraph);
    };
    const graphSubtitle = useCallback(() => {
        const appendedText = () => {
            if (activeGraphRange === 'month') {
                return 'in the last 6 months';
            } else {
                if (
                    (activeGraph.name === 'wordsCount' &&
                        wordsRange === 'week') ||
                    (activeGraph.name === 'referencesCount' &&
                        referencesRange === 'week')
                ) {
                    return 'this week';
                }

                return 'in the last 30 days';
            }
        };

        const graphName =
            'name' in activeGraph && activeGraph.name === 'wordsCount'
                ? 'Words'
                : 'Rewwinds';

        return `${graphName} added ${appendedText()}`;
    }, [activeGraphRange, activeGraph, wordsRange, referencesRange]);

    useEffect(() => {
        setWordsData(timeSeries.words);
    }, [timeSeries.words]);

    useEffect(() => {
        setReferencesData(timeSeries.references);
    }, [timeSeries.references]);

    return (
        <>
            <div className={styles.activityGraphs}>
                <Graphs
                    data={wordsData[wordsRange]?.data}
                    dataKey='wordsCount'
                    title='Words'
                    subTitle='See how often you add words'
                    gradientId='colorWordsCount'
                    stroke='#3f4f7b'
                    xAxisKey={wordsRange === 'month' ? 'month.display' : 'day'}
                    onChange={handleWordGraphChange}
                    isLoading={wordsData.isLoading}
                    onClick={handleGraphClick}
                    showPicker
                    showHeader
                />
                <Graphs
                    data={referencesData[referencesRange]?.data}
                    dataKey='referencesCount'
                    subKey='wordsCount'
                    title='Rewwinds'
                    subKeyTitle='words'
                    subTitle='Track your experience'
                    gradientId='colorReferencesCount'
                    subKeyGradientId='colorReferencesWordsCount'
                    stroke='#3f4f7b'
                    subKeyStroke='#ffc683'
                    xAxisKey={
                        referencesRange === 'month' ? 'month.display' : 'day'
                    }
                    onChange={handleReferencesGraphChange}
                    isLoading={referencesData.isLoading}
                    onClick={handleGraphClick}
                    showPicker
                    showHeader
                />
            </div>
            <Modal
                title='Activity'
                subTitle={graphSubtitle()}
                show={isModalOpen}
                onClose={handleModalClose}
                width='650px'
            >
                <ActivityGraphsDetails
                    activeGraph={activeGraph}
                    activeGraphRange={activeGraphRange}
                    onClose={() => setIsModalOpen(false)}
                >
                    {activeGraph.name === 'wordsCount' ? (
                        <Graphs
                            data={wordsData[wordsRange]?.data}
                            dataKey='wordsCount'
                            title='Words'
                            subTitle='Take a deeper look into the frequeny with which you discover words.'
                            gradientId='colorWordsCount'
                            stroke='#3f4f7b'
                            xAxisKey={
                                wordsRange === 'month' ? 'month.display' : 'day'
                            }
                            onChange={handleWordGraphChange}
                            isLoading={wordsData.isLoading}
                            onClick={handleGraphClick}
                            showPicker={false}
                        />
                    ) : (
                        <Graphs
                            data={referencesData[referencesRange]?.data}
                            dataKey='referencesCount'
                            subKey='wordsCount'
                            title='Rewwinds'
                            subKeyTitle='words'
                            subTitle='Track when and how you discover words'
                            gradientId='colorReferencesCount'
                            subKeyGradientId='colorReferencesWordsCount'
                            stroke='#3f4f7b'
                            subKeyStroke='#ffc683'
                            xAxisKey={
                                referencesRange === 'month'
                                    ? 'month.display'
                                    : 'day'
                            }
                            onChange={handleReferencesGraphChange}
                            isLoading={referencesData.isLoading}
                            onClick={handleGraphClick}
                            showPicker={false}
                        />
                    )}
                </ActivityGraphsDetails>
            </Modal>
        </>
    );
}
