import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { toast } from 'react-toastify';

import { client } from 'index';

import { throwError } from 'api/utilities';

import {
    QUERY_SEARCH_WORD,
    QUERY_SEARCHED_WORD_LIST,
    QUERY_GET_USER_WORDS,
    QUERY_SEARCH_WORD_DETAILS,
    MUTATION_ADD_USER_WORD_VARIATION,
    MUTATION_ADD_USER_WORD,
    MUTATION_UPDATE_USER_WORD,
    MUTATION_UPDATE_USER_WORDS,
    MUTATION_SET_USER_WORD_DEFAULT_VARIATION,
    MUTATION_UPDATE_USER_WORD_VARIATION,
    MUTATION_DELETE_USER_WORD,
    MUTATION_DELETE_USER_WORDS
} from 'api/words';
import { MUTATION_DELETE_WHOLE_USER_REFERENCE } from 'api/references';

import { setShowSideBar } from '../slices/app';
import { thunkAddCommunityReference } from '../slices/community';
import {
    setWordsReferences,
    thunkAddWordReference,
    deleteWholeWordReference,
    thunkGetReferences
} from '../slices/references';

import {
    Word,
    Variation,
    WordDetails,
    SearchedWordResult,
    SearchedWordList
} from 'types/word';
import { WordReference } from 'types/reference';

type ActiveWordDetailsState = {
    detailsLoading: boolean;
    details: WordDetails;
    error: boolean;
};

type SearchedWordState = {
    isLoading: boolean;
    list: [];
    searchedWord: string;
    error: boolean;
};

export type WordsState = {
    words: Word[];
    searchedWord: {
        isLoading: boolean;
        word: SearchedWordResult;
        show: boolean;
    };
    searchedWordList: SearchedWordState;
    activeWord: Word | null;
    activeWordDetails: ActiveWordDetailsState;
    isLoading: boolean;
};

type WordsSlice = {
    words: WordsState;
};

const initialState: WordsState = {
    words: [],
    searchedWord: {
        isLoading: false,
        word: {
            word: '',
            results: []
        },
        show: false
    },
    searchedWordList: {
        isLoading: false,
        list: [],
        searchedWord: '',
        error: false
    },
    activeWord: null,
    activeWordDetails: {
        detailsLoading: true,
        details: {} as WordDetails,
        error: false
    },
    isLoading: true
};

export const wordsSlice = createSlice({
    name: 'words',
    initialState,
    reducers: {
        setSearchedWord: (state, action) => {
            state.searchedWord = action.payload;
        },
        setSearchedWordList: (state, action) => {
            state.searchedWordList = action.payload;
        },
        addWord: (state, action) => {
            state.words.unshift(action.payload);
        },
        addWordVariation: (state, action) => {
            const {
                payload: { wordIndex, newVariation }
            } = action;

            state.words[wordIndex].archived = false;
            state.words[wordIndex].variations.push(newVariation);
        },
        deleteWord: (state, action) => {
            const {
                payload: { wordIndex }
            } = action;

            state.words.splice(wordIndex, 1);
        },
        updateWord: (state, action) => {
            const {
                payload: { wordIndex, newWord }
            } = action;

            state.words.splice(wordIndex, 1, newWord);
        },
        updateWordVariation: (state, action) => {
            const {
                payload: { wordIndex, newVariationIndex, newVariation }
            } = action;

            state.words[wordIndex].variations.splice(
                newVariationIndex,
                1,
                newVariation
            );
        },
        setIsWordsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setWords: (state, action) => {
            state.words = action.payload;
        },
        setWordDetails: (state, action) => {
            const {
                payload: { wordIndex, details }
            } = action;

            state.words[wordIndex].details = details;
        },
        setActiveWord: (state, action) => {
            state.activeWord = action.payload;
        },
        setActiveWordDetails: (state, action) => {
            state.activeWordDetails = action.payload;
        },
        setDefaultVariation: (state, action) => {
            const {
                payload: {
                    wordIndex,
                    newDefaultVariationIndex,
                    newDefaultVariation,
                    oldDefaultVariationIndex,
                    oldDefaultVariation
                }
            } = action;

            if (oldDefaultVariationIndex >= 0) {
                state.words[wordIndex].variations.splice(
                    oldDefaultVariationIndex,
                    1,
                    oldDefaultVariation
                );
                state.words[wordIndex].variations.splice(
                    newDefaultVariationIndex,
                    1,
                    newDefaultVariation
                );
            } else {
                state.words[wordIndex].variations.splice(
                    newDefaultVariationIndex,
                    1,
                    newDefaultVariation
                );
            }
        }
    }
});

export const {
    addWord,
    addWordVariation,
    updateWordVariation,
    updateWord,
    deleteWord,
    setWords,
    setWordDetails,
    setIsWordsLoading,
    setSearchedWord,
    setSearchedWordList,
    setActiveWord,
    setActiveWordDetails,
    setDefaultVariation
} = wordsSlice.actions;

//ASYNC ACTIONS (THUNKS)
export const thunkAddWord =
    (word: Word, reference: WordReference | null) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            },
            words: { words: stateWords, activeWord }
        } = state;
        const wordIndex = stateWords.findIndex(
            (item: Word) => item.word === word.word
        );

        if (wordIndex >= 0) {
            const newVariation = { ...word.variations[0] };
            const variationIndex = stateWords[wordIndex].variations.findIndex(
                (item: Variation) => item.definition === newVariation.definition
            );

            if (variationIndex >= 0) {
                // Check if word to be added is an archived word
                if (stateWords[wordIndex].archived === true) {
                    const unArchivedWord = {
                        ...stateWords[wordIndex],
                        archived: false
                    };
                    dispatch(thunkUpdateUserWord(unArchivedWord));
                } else {
                    toast.error('Word variation already exists');
                }
            } else {
                try {
                    const { data, errors } = await client.mutate<{
                        addUserWordVariation: boolean;
                    }>({
                        mutation: MUTATION_ADD_USER_WORD_VARIATION,
                        variables: {
                            variation: newVariation,
                            word: word.word,
                            userID
                        }
                    });

                    if (errors) {
                        toast.error('Error adding word variation');
                    }

                    if (data && data.addUserWordVariation) {
                        await dispatch(
                            addWordVariation({ wordIndex, newVariation })
                        );

                        // Check if word variation being added is the active word in the side bar
                        if (
                            activeWord !== null &&
                            word.word === activeWord.word
                        ) {
                            await dispatch(thunkSetActiveWord(activeWord.word));
                        }

                        if (reference !== null) {
                            await dispatch(
                                thunkAddWordReference(reference, true)
                            );

                            if (reference.references[0].isPublic) {
                                await dispatch(
                                    thunkAddCommunityReference(word, reference)
                                );
                            }
                        }

                        toast.success('Word variation added');
                    }
                } catch (err) {
                    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                    toast.error(err);
                }
            }
        } else {
            try {
                const { data, errors } = await client.mutate<{
                    addUserWord: boolean;
                }>({
                    mutation: MUTATION_ADD_USER_WORD,
                    variables: {
                        word,
                        userID
                    }
                });

                if (errors) {
                    toast.error('Error adding word');
                }

                if (data && data.addUserWord) {
                    await dispatch(addWord(word));

                    if (reference !== null) {
                        await dispatch(thunkAddWordReference(reference, true));

                        // * NOTE: This will only add the word to the community collection if there is a reference
                        if (reference.references[0].isPublic) {
                            await dispatch(
                                thunkAddCommunityReference(word, reference)
                            );
                        }
                    }

                    toast.success('Word added');
                }
            } catch (err) {
                // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                toast.error(err);
            }
        }
    };

export const thunkSearchWordList =
    (searchedWord: string) => async (dispatch: React.Dispatch<any>) => {
        dispatch(
            setSearchedWordList({ isLoading: true, list: [], searchedWord })
        );

        try {
            const { data, error } = await client.query<{
                searchWordList: SearchedWordList;
            }>({
                query: QUERY_SEARCHED_WORD_LIST,
                variables: {
                    word: searchedWord
                }
            });

            if (error) {
                dispatch(
                    setSearchedWordList({
                        isLoading: false,
                        error: true,
                        searchedWord,
                        list: []
                    })
                );
                toast.error('Word not found');
            }

            if (data && data.searchWordList) {
                const sortedList = data.searchWordList.results
                    .slice()
                    .sort((a: any, b: any) => b.score - a.score);
                dispatch(
                    setSearchedWordList({
                        isLoading: false,
                        list: sortedList,
                        searchedWord,
                        error: false
                    })
                );
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
            dispatch(
                setSearchedWordList({
                    isLoading: false,
                    error: true,
                    searchedWord,
                    list: []
                })
            );
        }
    };

export const thunkSearchWord =
    (searchedWord: string) => async (dispatch: React.Dispatch<any>) => {
        dispatch(
            setSearchedWord({
                isLoading: true,
                word: null,
                active: false,
                show: true
            })
        );

        try {
            const { data, error } = await client.query<{
                searchWord: SearchedWordResult;
            }>({
                query: QUERY_SEARCH_WORD,
                variables: {
                    word: searchedWord
                }
            });

            if (error) {
                dispatch(
                    setSearchedWord({
                        isLoading: false,
                        word: null,
                        active: true,
                        show: true
                    })
                );
                toast.error('Word not found');
            }

            if (data && data.searchWord) {
                dispatch(
                    setSearchedWord({
                        isLoading: false,
                        word: data.searchWord,
                        active: true,
                        show: true
                    })
                );
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkSetActiveWord =
    (word: string) => async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            words: { words: stateWords }
        } = state;
        const activeWord = stateWords.find((item: any) => item.word === word);

        dispatch(setActiveWord(activeWord));
    };

export const thunkActiveWordDetails =
    (word: string) => async (dispatch: React.Dispatch<any>, getState: any) => {
        dispatch(setActiveWordDetails({ detailsLoading: true, details: {} }));
        const {
            words: { words: stateWords }
        } = getState();
        const wordIndex = stateWords.findIndex(
            (item: any) => item.word === word
        );

        if (wordIndex >= 0 && 'details' in stateWords[wordIndex]) {
            dispatch(
                setActiveWordDetails({
                    detailsLoading: false,
                    details: stateWords[wordIndex].details
                })
            );
        } else {
            try {
                const { data, error } = await client.query<{
                    searchWordDetails: WordDetails;
                }>({
                    query: QUERY_SEARCH_WORD_DETAILS,
                    variables: {
                        word
                    }
                });

                if (error) {
                    toast.error('Error getting word details');
                }

                if (data && data.searchWordDetails) {
                    dispatch(
                        setActiveWordDetails({
                            detailsLoading: false,
                            details: data.searchWordDetails
                        })
                    );
                    dispatch(
                        setWordDetails({
                            wordIndex,
                            details: data.searchWordDetails
                        })
                    );
                } else {
                    dispatch(
                        setActiveWordDetails({
                            detailsLoading: false,
                            details: {},
                            error: true
                        })
                    );
                }
            } catch (err) {
                // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                toast.error(err);
            }
        }
    };

export const thunkGetWords =
    () => async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            },
            words: { words: stateWords }
        } = state;

        try {
            if (stateWords.length === 0) {
                const { data, error } = await client.query<{
                    getUserWords: { words: Word[] };
                }>({
                    query: QUERY_GET_USER_WORDS,
                    variables: {
                        userID
                    }
                });

                if (error) {
                    toast.error('Error getting words');
                }

                if (data && data.getUserWords) {
                    const { words } = data.getUserWords;

                    await dispatch(setWords(words));
                    await dispatch(thunkGetReferences());
                }
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        } finally {
            dispatch(setIsWordsLoading(false));
        }
    };

export const thunkSetDefaultVariation =
    (variation: Variation, word: Word) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            words: { words: stateWords },
            users: {
                userInfo: { userID }
            }
        } = state;
        const wordIndex = stateWords.findIndex(
            (item: any) => item.word === word.word
        );

        // Set new default variation
        const variations = stateWords[wordIndex].variations;
        const newDefaultVariationIndex = variations.findIndex(
            (item: any) => item.variationID === variation.variationID
        );
        const newDefaultVariation = {
            ...variations[newDefaultVariationIndex],
            defaultVariation: true
        };

        // Remove old default variation
        const oldDefaultVariationIndex = variations.findIndex(
            (item: any) => item.defaultVariation === true
        );

        if (oldDefaultVariationIndex >= 0) {
            const oldDefaultVariation = {
                ...variations[oldDefaultVariationIndex],
                defaultVariation: false
            };

            try {
                const { data, errors } = await client.mutate<{
                    setUserWordDefaultVariation: boolean;
                }>({
                    mutation: MUTATION_SET_USER_WORD_DEFAULT_VARIATION,
                    variables: {
                        newDefaultVariation,
                        oldDefaultVariation,
                        userID
                    }
                });

                if (errors) {
                    toast.error('Error saving word');
                }

                if (data && data.setUserWordDefaultVariation) {
                    await dispatch(
                        setDefaultVariation({
                            wordIndex,
                            newDefaultVariationIndex,
                            newDefaultVariation,
                            oldDefaultVariationIndex,
                            oldDefaultVariation
                        })
                    );

                    dispatch(thunkSetActiveWord(word.word));

                    toast.success('Word saved');
                }
            } catch (err) {
                // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                toast.error(err);
            }
        } else {
            try {
                const { data, errors } = await client.mutate<{
                    setUserWordDefaultVariation: boolean;
                }>({
                    mutation: MUTATION_SET_USER_WORD_DEFAULT_VARIATION,
                    variables: {
                        newDefaultVariation,
                        oldDefaultVariation: null,
                        userID
                    }
                });

                if (errors) {
                    toast.error('Error saving word');
                }

                if (data && data.setUserWordDefaultVariation) {
                    await dispatch(
                        setDefaultVariation({
                            wordIndex,
                            newDefaultVariationIndex,
                            newDefaultVariation,
                            oldDefaultVariationIndex: -1,
                            oldDefaultVariation: null
                        })
                    );

                    dispatch(thunkSetActiveWord(word.word));

                    toast.success('Word saved');
                }
            } catch (err) {
                // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                toast.error(err);
            }
        }
    };

export const thunkUpdateUserWord =
    (newWord: any) => async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            words: { words: stateWords, activeWord },
            users: {
                userInfo: { userID }
            }
        } = state;
        const wordIndex = stateWords.findIndex(
            (item: any) => item.word === newWord.word
        );

        if (wordIndex >= 0) {
            try {
                const { data, errors } = await client.mutate<{
                    updateUserWord: boolean;
                }>({
                    mutation: MUTATION_UPDATE_USER_WORD,
                    variables: {
                        newWord,
                        userID
                    }
                });

                if (errors) {
                    toast.error('Error saving word');
                }

                if (data && data.updateUserWord) {
                    await dispatch(
                        updateWord({
                            wordIndex,
                            newWord
                        })
                    );

                    if (
                        activeWord !== null &&
                        newWord.word === activeWord.word
                    ) {
                        await dispatch(thunkSetActiveWord(activeWord.word));
                    }

                    toast.success('Word updated');
                }
            } catch (err) {
                // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                toast.error(err);
            }
        } else {
            toast('Please refresh');
        }
    };

export const thunkUpdateUserWords =
    (wordsToUpdate: Word[]) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            words: { words: stateWords },
            users: {
                userInfo: { userID }
            }
        } = state;
        const wordsExist = wordsToUpdate.every((word: Word) =>
            stateWords.some((item: any) => item.word === word.word)
        );

        if (wordsExist) {
            try {
                const { data, errors } = await client.mutate<{
                    updateUserWords: boolean;
                }>({
                    mutation: MUTATION_UPDATE_USER_WORDS,
                    variables: {
                        newWords: wordsToUpdate,
                        userID
                    }
                });

                if (errors) {
                    toast.error('Error updating word');
                }

                if (data && data.updateUserWords) {
                    const stateWordsCopy = stateWords.slice();

                    wordsToUpdate.forEach(async (word: Word) => {
                        const wordIndex = stateWordsCopy.findIndex(
                            (item: Word) => item.word === word.word
                        );

                        stateWordsCopy.splice(wordIndex, 1, word);
                    });

                    await dispatch(setWords(stateWordsCopy));

                    toast.success('Words updated');
                }
            } catch (err) {
                // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                toast.error(err);
            }
        } else {
            toast('Please refresh');
        }
    };

export const thunkDeleteUserWord =
    (wordToDelete: string) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            words: { words: stateWords },
            references: { wordsReferences },
            users: {
                userInfo: { userID }
            }
        } = state;
        const wordToDeleteIndex = stateWords.findIndex(
            (item: Word) => item.word === wordToDelete
        );

        if (wordToDeleteIndex >= 0) {
            try {
                const { data, errors } = await client.mutate<{
                    deleteUserWord: boolean;
                }>({
                    mutation: MUTATION_DELETE_USER_WORD,
                    variables: {
                        word: wordToDelete,
                        userID
                    }
                });

                if (errors) {
                    toast.error('Error deleting word');
                }

                if (data && data.deleteUserWord) {
                    await dispatch(
                        deleteWord({
                            wordIndex: wordToDeleteIndex
                        })
                    );

                    // Delete reference
                    // TODO: Try moving this to the server
                    const hasReference = wordsReferences.some(
                        (item: WordReference) => item.word === wordToDelete
                    );

                    if (hasReference) {
                        try {
                            const { data, errors } = await client.mutate<{
                                deleteWholeUserReference: boolean;
                            }>({
                                mutation: MUTATION_DELETE_WHOLE_USER_REFERENCE,
                                variables: {
                                    words: [wordToDelete],
                                    userID
                                }
                            });

                            if (errors) {
                                throwError(errors);
                            }

                            if (data && data.deleteWholeUserReference) {
                                const wordReferenceIndex =
                                    wordsReferences.findIndex(
                                        (item: WordReference) =>
                                            item.word === wordToDelete
                                    );

                                if (wordReferenceIndex >= 0) {
                                    await dispatch(
                                        deleteWholeWordReference({
                                            wordIndex: wordReferenceIndex
                                        })
                                    );
                                }
                            }
                        } catch (err) {
                            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                            throw new Error(err);
                        }
                    }

                    await dispatch(setShowSideBar({ show: false, type: null }));

                    toast.success('Word deleted');
                }
            } catch (err) {
                // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                toast.error(err);
            }
        } else {
            toast('Please refresh');
        }
    };

export const thunkDeleteUserWords =
    (wordsToDelete: string[]) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            words: { words: stateWords },
            references: { wordsReferences },
            users: {
                userInfo: { userID }
            }
        } = state;
        const wordsExist = wordsToDelete.every((word: string) =>
            stateWords.some((item: Word) => item.word === word)
        );

        if (wordsExist) {
            try {
                // Delete words
                const { data: wordsData, errors: wordsError } =
                    await client.mutate<{
                        deleteUserWords: boolean;
                    }>({
                        mutation: MUTATION_DELETE_USER_WORDS,
                        variables: {
                            words: wordsToDelete,
                            userID
                        }
                    });

                // Delete references
                const { data: referencesData, errors: referencesError } =
                    await client.mutate<{
                        deleteWholeUserReference: boolean;
                    }>({
                        mutation: MUTATION_DELETE_WHOLE_USER_REFERENCE,
                        variables: {
                            words: wordsToDelete,
                            userID
                        }
                    });

                // Words deletion handler
                if (wordsError) {
                    toast.error('Error deleting words');
                }

                if (wordsData && wordsData.deleteUserWords) {
                    const newWords = stateWords.filter(
                        (item: Word) => !wordsToDelete.includes(item.word)
                    );

                    await dispatch(setWords(newWords));
                }

                // References deletion handler
                if (referencesError) {
                    throwError(referencesError);
                }

                if (referencesData && referencesData.deleteWholeUserReference) {
                    const newReferences = wordsReferences.filter(
                        (item: WordReference) =>
                            !wordsToDelete.includes(item.word)
                    );

                    await dispatch(setWordsReferences(newReferences));
                }

                if (
                    wordsData &&
                    wordsData.deleteUserWords &&
                    referencesData &&
                    referencesData.deleteWholeUserReference
                ) {
                    toast.success('Words deleted');
                }
            } catch (err) {
                // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                toast.error(err);
            }
        } else {
            toast('Please refresh');
        }
    };

export const thunkUpdateUserWordVariation =
    (newVariation: Variation) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            words: { words: stateWords, activeWord },
            users: {
                userInfo: { userID }
            }
        } = state;
        const wordIndex = stateWords.findIndex(
            (item: Word) => item.word === activeWord.word
        );

        // Set new variation
        const variations = stateWords[wordIndex].variations;
        const newVariationIndex = variations.findIndex(
            (item: Variation) => item.variationID === newVariation.variationID
        );

        if (newVariationIndex >= 0) {
            try {
                const { data, errors } = await client.mutate<{
                    updateUserWordVariation: boolean;
                }>({
                    mutation: MUTATION_UPDATE_USER_WORD_VARIATION,
                    variables: {
                        newVariation,
                        userID
                    }
                });

                if (errors) {
                    toast.error('Error saving word');
                }

                if (data && data.updateUserWordVariation) {
                    await dispatch(
                        updateWordVariation({
                            wordIndex,
                            newVariationIndex,
                            newVariation
                        })
                    );

                    // Update active word
                    if (activeWord !== null) {
                        dispatch(thunkSetActiveWord(activeWord.word));
                    }

                    toast.success('Word saved');
                }
            } catch (err) {
                // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                toast.error(err);
            }
        } else {
            toast('Please refresh');
        }
    };

export const thunkResetWordsSlice = () => (dispatch: React.Dispatch<any>) => {
    dispatch(setIsWordsLoading(true));
    dispatch(setWords([]));
    dispatch(setSearchedWord({ isLoading: true, word: null, wordDetails: {} }));
    dispatch(
        setSearchedWordList({
            isLoading: true,
            list: [],
            searchedWord: '',
            error: false
        })
    );
    dispatch(setActiveWord(null));
    dispatch(setWordsReferences([]));
};

//SELECTORS
export const selectWords = (state: WordsSlice): Word[] => state.words.words;
export const selectSearchedWord = (state: WordsSlice) =>
    state.words.searchedWord;
export const selectSearchedWordList = (state: WordsSlice): SearchedWordState =>
    state.words.searchedWordList;
export const selectActiveWord = (state: WordsSlice): Word | null =>
    state.words.activeWord;
export const selectActiveWordDetails = (
    state: WordsSlice
): ActiveWordDetailsState => state.words.activeWordDetails;
export const selectWordsLoading = (state: WordsSlice): boolean =>
    state.words.isLoading;
export const selectSearchedWordInWords = createSelector(
    [selectWords, selectSearchedWord],
    (words, searchedWord) => {
        return words.find((word: Word) => {
            if (searchedWord.word) {
                return (
                    word.archived !== true &&
                    word.word === searchedWord.word.word
                );
            }
        });
    }
);

export default wordsSlice.reducer;
