import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

import { selectWords } from 'redux/slices/words';
import { thunkSetDefaultVariation } from 'redux/slices/words';

import Selector from 'shared/components/Selector';
import Button from 'shared/components/Button';

import { Word, Variation } from 'types/word';

import styles from './WordSettings.module.scss';

type WordSettingItemHeaderProps = {
    headerText?: string;
    showContent?: boolean;
    onClick?: () => void;
};

const WordSettingItemHeader = ({
    headerText,
    showContent,
    onClick
}: WordSettingItemHeaderProps) => {
    return (
        <h4 className={styles.wordSettingsItemHeader} onClick={onClick}>
            {headerText}
            <span>
                {showContent ? (
                    <FiChevronUp className='icon actionIcon__small' />
                ) : (
                    <FiChevronDown className='icon actionIcon__small' />
                )}
            </span>
        </h4>
    );
};

type WordSettingsProps = {
    word: Word;
};

const WordSettings = ({ word }: WordSettingsProps) => {
    const dispatch = useDispatch();
    const words = useSelector(selectWords);
    const [showSelector, setShowSelector] = useState<boolean>(false);
    const [selected, setSelected] = useState<Variation[]>([]);
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
    const wordToUse = words.find((item) => item.word === word.word) as Word;
    const variations = wordToUse.variations;
    const handleOpen = (type: 'selector') => {
        switch (type) {
            case 'selector':
                setShowSelector(!showSelector);
                break;
            default:
                break;
        }
    };

    const handleOnSelected = (selected: Variation[]) => {
        setSelected(selected);
    };

    const buttonDisabled = () => {
        if (variations) {
            const defaultVariation = variations.find(
                (item) => item.defaultVariation === true
            );
            const addCondition = () => {
                return defaultVariation !== undefined
                    ? defaultVariation.variationID === selected[0].variationID
                    : false;
            };

            return selected.length === 0 || isButtonLoading || addCondition();
        }
    };

    const handleSave = async () => {
        if (!buttonDisabled()) {
            setIsButtonLoading(true);

            if (word && selected.length) {
                await dispatch(thunkSetDefaultVariation(selected[0], word));
            }

            setIsButtonLoading(false);
        }
    };

    return (
        <div className={styles.wordSettingsContainer}>
            {variations.length > 1 && (
                <div className={styles.wordSettingsItem}>
                    <WordSettingItemHeader
                        headerText={`Select default variation (${variations.length})`}
                        showContent={showSelector}
                        onClick={() => handleOpen('selector')}
                    />
                    {showSelector && (
                        <>
                            <Selector
                                items={variations}
                                type='variation'
                                word={word}
                                identifier='variationID'
                                onSelected={handleOnSelected}
                            />
                            <Button
                                onClick={handleSave}
                                buttonDisabled={buttonDisabled()}
                                isLoading={isButtonLoading}
                            >
                                Save
                            </Button>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default WordSettings;
