import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectActiveWord,
    selectActiveWordDetails,
    thunkActiveWordDetails
} from 'redux/slices/words';
import { selectWordReferences } from 'redux/slices/references';
import { WORD_DETAIL_SECTIONS } from 'constants/wordsConstants';

import WordReferences from '../WordReferences';
import WordDefinitions from '../WordDefinitions';
import WordDetailsItem from './WordDetailsItem';
import WordSettings from '../WordSettings';
import Tabs from 'shared/components/Tabs';
import SmallLoader from 'shared/components/SmallLoader';

import { TabItem } from 'shared/components/Tabs/Tabs';

import { Word, Variation, WordDetails as WordDetailsType } from 'types/word';
import { Reference } from 'types/reference';

import './WordDetails.scss';

type WordComponentProps = {
    activeWord: Word;
    references: Reference[];
    variations: Variation[];
};

const WordComponent = ({
    activeWord,
    references,
    variations
}: WordComponentProps) => {
    return (
        <>
            <div className='wordSideBarOverview'>
                <p>
                    You have&nbsp;
                    <span className='counter__noBorder'>
                        {variations.length}
                    </span>
                    &nbsp;
                    {variations.length > 1 ? 'variations' : 'variation'} of this
                    word:
                </p>
            </div>
            <WordDefinitions activeWord={activeWord} />
            <WordReferences word={activeWord} references={references} />
        </>
    );
};

const DetailsComponent = () => {
    const activeWordDetails = useSelector(selectActiveWordDetails);
    const { detailsLoading, error } = activeWordDetails;

    if (error) {
        return <p>Error loading details</p>;
    }

    return (
        <div className='detialsContainer'>
            {detailsLoading ? (
                <div
                    style={{
                        height: '300px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <SmallLoader />
                </div>
            ) : (
                <>
                    {WORD_DETAIL_SECTIONS.map((detail) => {
                        const detailKey = detail as keyof WordDetailsType;
                        return (
                            <WordDetailsItem section={detailKey} key={detail} />
                        );
                    })}
                </>
            )}
        </div>
    );
};

const WordDetails = () => {
    const dispatch = useDispatch();
    const activeWord = useSelector(selectActiveWord);
    const references = useSelector(selectWordReferences);

    if (activeWord) {
        const { variations } = activeWord;

        const tabItems: TabItem[] = [
            {
                name: 'word',
                display: 'Word',
                word: activeWord.word,
                Component: (
                    <WordComponent
                        activeWord={activeWord}
                        variations={variations}
                        references={references}
                    />
                )
            },
            {
                name: 'details',
                display: 'More Details',
                Component: <DetailsComponent />,
                onClick: () => dispatch(thunkActiveWordDetails(activeWord.word))
            },
            variations.length > 1
                ? {
                      name: 'options',
                      display: 'Options',
                      Component: <WordSettings word={activeWord} />
                  }
                : { name: '' }
        ];

        return (
            <div className='wordDetails'>
                <Tabs tabItems={tabItems} />
            </div>
        );
    }

    return null;
};

export default WordDetails;
