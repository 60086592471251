import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    FiUser,
    FiMenu,
    FiBookmark,
    FiX,
    FiActivity,
    FiSearch
} from 'react-icons/fi';
import { CgNotes } from 'react-icons/cg';
import { HiOutlineCollection } from 'react-icons/hi';
import {
    MdOutlineNewReleases,
    MdOutlineComment,
    MdOutlineInfo
} from 'react-icons/md';
// import { GiLinkedRings } from 'react-icons/gi';

import * as ROUTES from 'constants/routes';

import { selectAuthUser, selectUserInfo } from 'redux/slices/users';
import { selectHeader } from 'redux/slices/app';

import SignOut from 'views/Authentication/SignOut';
import Button from 'shared/components/Button';

import './MobileNavigation.scss';

const MobileNavigation = () => {
    const userInfo = useSelector(selectUserInfo);
    const authUser = useSelector(selectAuthUser);
    const header = useSelector(selectHeader);

    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [imageError, setImageError] = useState<boolean>(false);
    const handleLinkClick = () => {
        setShowMenu(false);
    };
    const handleImageError = () => {
        setImageError(true);
    };
    const imageToShow = () => {
        if (authUser?.photoURL !== null) {
            return userInfo?.profileImage !== null
                ? userInfo?.profileImage.imageURL
                : authUser?.photoURL;
        }
    };
    const navPageTitle = `${header.page
        .charAt(0)
        .toUpperCase()}${header.page.slice(1)}`;

    return (
        <div className='mobileNavContainer'>
            <Button
                onClick={() => setShowMenu(!showMenu)}
                className={
                    header.page === 'search' ? '' : 'mobileNavigationClass'
                }
                buttonCategory={header.page === 'search' ? 'icon' : ''}
            >
                {header.page === 'search' ? (
                    showMenu ? (
                        <FiX className='icon buttonIcon' />
                    ) : (
                        <FiMenu className='icon buttonIcon' />
                    )
                ) : (
                    <>
                        <span>
                            {showMenu ? (
                                <FiX className='icon buttonIcon' />
                            ) : (
                                <FiMenu className='icon buttonIcon' />
                            )}
                        </span>
                        <span className='divider'>
                            <hr />
                        </span>
                        <span>{navPageTitle}</span>
                    </>
                )}
            </Button>
            {showMenu && (
                <ul>
                    <li onClick={handleLinkClick}>
                        <NavLink activeClassName='active' to={ROUTES.SEARCH}>
                            <FiSearch />
                            <span>Search</span>
                        </NavLink>
                    </li>
                    <li onClick={handleLinkClick}>
                        <NavLink activeClassName='active' to={ROUTES.ACTIVITY}>
                            <FiActivity />
                            <span>Activity</span>
                            <span className='newIndicator'>new</span>
                        </NavLink>
                    </li>
                    {/* <li onClick={handleLinkClick}>
            <NavLink activeClassName='active' to={ROUTES.COMMUNITY}>
              <GiLinkedRings className='icon actionIcon' />
              <span>Community</span>
              <span className='newIndicator'>new</span>
            </NavLink>
          </li> */}
                    <li onClick={handleLinkClick}>
                        <NavLink activeClassName='active' to={ROUTES.WORDS}>
                            <FiBookmark />
                            <span>Words</span>
                        </NavLink>
                    </li>
                    <li onClick={handleLinkClick}>
                        <NavLink
                            activeClassName='active'
                            to={ROUTES.COLLECTIONS}
                        >
                            <HiOutlineCollection />
                            <span>Collections</span>
                        </NavLink>
                    </li>
                    <li onClick={handleLinkClick}>
                        <NavLink activeClassName='active' to={ROUTES.NOTES}>
                            <CgNotes />
                            <span>Notes</span>
                        </NavLink>
                    </li>
                    <hr />
                    <li onClick={handleLinkClick}>
                        <NavLink activeClassName='active' to={ROUTES.ACCOUNT}>
                            {imageError || authUser?.photoURL === null ? (
                                <FiUser />
                            ) : (
                                <img
                                    src={imageToShow()}
                                    onError={handleImageError}
                                    className='profile-picture'
                                    alt='Profile picture'
                                />
                            )}
                            <span>My Account</span>
                        </NavLink>
                    </li>
                    <li>
                        <a
                            href='https://www.rewwind.co'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <MdOutlineInfo className='icon actionIcon' />
                            <span>About Rewwind</span>
                        </a>
                    </li>
                    <li>
                        <a
                            // href='https://docs.google.com/forms/d/e/1FAIpQLSfQnzyEoxx8vUH6Uu-KOqeVDxoiDENNOhwFnL5pvIzDLJrt4Q/viewform'
                            href='https://feedback.rewwind.co/feature-requests'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <MdOutlineComment />
                            <span>Feedback</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href='https://feedback.rewwind.co/changelog'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <MdOutlineNewReleases />
                            <span>Whats&apos;s new?</span>
                        </a>
                    </li>
                    <hr />

                    <li>
                        <SignOut isMobile={true} />
                    </li>
                    <hr />
                    <li>
                        <p className='menuFooter'>
                            &copy; 2023{' '}
                            <a
                                href='https://www.linkedin.com/in/ayomide-onalaja-5a1469113/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                Ayomide Onalaja
                            </a>
                            &nbsp;
                            <span className='emoji'>&#127475;&#127468;</span>
                        </p>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default MobileNavigation;
