import React, { useMemo } from 'react';

import Pill from 'shared/components/Pill';
import Modal from 'shared/components/Modal';
import Tabs from 'shared/components/Tabs';
import WordDefinitionsItem from 'views/Words/WordDefinitions/WordDefinitionsItem';

import { Variation } from 'types/word';
import { Stat } from '../ActivityStats';

import 'shared/styles/fonts.scss';

type Props = {
    isModalOpen?: boolean;
    onClose?: () => void;
    userAdded: Stat;
};

export default function UserAddedModal({
    isModalOpen,
    onClose,
    userAdded
}: Props) {
    const { userAddedWords } = userAdded;
    const onPillClick = () => {
        onClose && onClose();
    };
    const variationsByWord = useMemo(
        () =>
            userAddedWords?.variations.reduce(
                (acc: Record<string, Variation[]>, curr) => {
                    const currentWord = curr.word.toLowerCase();

                    if (!acc[currentWord]) {
                        acc[currentWord] = [curr];
                    } else {
                        acc[currentWord].push(curr);
                    }

                    return acc;
                },
                {}
            ),
        [userAddedWords]
    );
    const tabItems = [
        {
            name: 'words',
            display: 'Words',
            Component: (
                <div>
                    {userAddedWords?.words.map((word) => (
                        <Pill
                            key={word.word}
                            item={word.word}
                            type='word'
                            onClick={onPillClick}
                        />
                    ))}
                </div>
            )
        },
        {
            name: 'variations',
            display: 'Variations',
            Component: (
                <ul>
                    {variationsByWord &&
                        Object.keys(variationsByWord).map((word) => (
                            <li key={word}>
                                <p
                                    style={{
                                        fontWeight: 500,
                                        color: 'bisque',
                                        fontFamily: '$zenDots'
                                    }}
                                >
                                    {word}
                                </p>
                                <ul>
                                    {variationsByWord[word].map((variation) => {
                                        const wordToUse =
                                            userAddedWords?.words.find(
                                                (item) =>
                                                    item.word ===
                                                    variation.word.toLowerCase()
                                            );

                                        if (wordToUse) {
                                            return (
                                                <WordDefinitionsItem
                                                    word={wordToUse}
                                                    variation={variation}
                                                    key={variation.variationID}
                                                    showOptions={false}
                                                    dateTimeType='dateOnly'
                                                />
                                            );
                                        }

                                        return null;
                                    })}
                                </ul>
                            </li>
                        ))}
                </ul>
            )
        }
    ];

    return (
        <Modal
            show={isModalOpen}
            onClose={onClose}
            title='Manually added variations'
            subTitle={`${userAddedWords?.words.length} words, ${userAddedWords?.value} variations`}
        >
            <Tabs tabItems={tabItems} />
        </Modal>
    );
}
