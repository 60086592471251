import React, { useMemo } from 'react';
import { formatDate } from 'helpers/helpers';

import WordDefinitionsItem from './WordDefinitionsItem';

import { Variation, Word } from 'types/word';

import './WordDefinitions.scss';

type Props = {
    activeWord: Word;
};

const WordDefinitions = ({ activeWord }: Props) => {
    const { variations } = activeWord;
    const definitionsByDate = useMemo(
        () =>
            variations.reduce(
                (acc: Record<string, Variation[]>, curr: Variation) => {
                    const formattedDate = formatDate(
                        curr.dateAdded,
                        'dateOnly'
                    ) as string;

                    if (!acc[formattedDate]) {
                        acc[formattedDate] = [curr];
                    } else {
                        acc[formattedDate].push(curr);
                    }

                    return acc;
                },
                {}
            ),
        [variations]
    );

    return (
        <div className='definitionsContainer'>
            <h4>definitions</h4>
            <ul>
                {Object.keys(definitionsByDate).map((date) => {
                    return (
                        <li key={date} className='dateListItem'>
                            <span className='dateAdded'>{date}</span>
                            <ul>
                                {definitionsByDate[date].map((variation) => (
                                    <WordDefinitionsItem
                                        word={activeWord}
                                        variation={variation}
                                        key={variation.variationID}
                                    />
                                ))}
                            </ul>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default WordDefinitions;
