import { Word } from 'types/word';
import { WordSortType as WordSort } from 'views/Words/WordSort/WordSort';

export function sortWordsHelper(type: WordSort, words: Word[]): Word[] {
    const wordCopy = words.slice();
    let sortedWords: Word[] = [];

    if (type === 'alphaAsc') {
        sortedWords = wordCopy.sort((a: Word, b: Word) => {
            const x = a.word;
            const y = b.word;
            if (x < y) return -1;
            if (x > y) return 1;
            return 0;
        });
    } else if (type === 'alphaDesc') {
        sortedWords = wordCopy.sort((a: Word, b: Word) => {
            const x = a.word;
            const y = b.word;
            if (x > y) return -1;
            if (x < y) return 1;
            return 0;
        });
    } else if (type === 'dateAsc') {
        sortedWords = wordCopy.sort((a: Word, b: Word) => {
            return (
                new Date(
                    b.variations[b.variations.length - 1].dateAdded
                ).getTime() -
                new Date(
                    a.variations[a.variations.length - 1].dateAdded
                ).getTime()
            );
        });
    } else if (type === 'dateDesc') {
        sortedWords = wordCopy.sort((a: Word, b: Word) => {
            return (
                new Date(
                    a.variations[a.variations.length - 1].dateAdded
                ).getTime() -
                new Date(
                    b.variations[b.variations.length - 1].dateAdded
                ).getTime()
            );
        });
    } else if (type === 'archived') {
        sortedWords = wordCopy.filter((item: Word) => item.archived);
    }

    return sortedWords;
}

export function wordPermissionsHelper(item: { isPublic: boolean }): {
    id: 'private' | 'public';
} {
    return item.isPublic ? { id: 'public' } : { id: 'private' };
}
