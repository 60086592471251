import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { thunkShowSideBar } from 'redux/slices/app';
import { setSearchedWord, selectSearchedWord } from 'redux/slices/words';

import { Word } from 'types/word';

type Props = {
    item?: any;
    userHasWord?: boolean;
    onClick?: () => void;
    type?: string;
    searchInput?: string;
    onSearch?: (value: string) => void;
};

const SearchListItem = ({
    item,
    userHasWord,
    type,
    searchInput,
    onSearch
}: Props) => {
    const dispatch = useDispatch();
    const searchedWord = useSelector(selectSearchedWord);
    const searchListItemClass = classNames('searchListItem', {
        word: type === 'word'
    });
    const listItem = type === 'word' ? item.word : item.reference;

    const handleItemClick = async () => {
        if (userHasWord) {
            dispatch(thunkShowSideBar(item, 'wordDetails'));

            searchedWord.show && dispatch(setSearchedWord({ show: false }));
        } else {
            onSearch && onSearch(listItem);
        }
    };

    // https://tomekdev.com/posts/highlight-text-in-javascript
    // @ts-expect-error TS(2769): No overload matches this call.
    const regex = new RegExp(searchInput, 'gi');
    const newText = listItem.replace(
        regex,
        `<mark class="highlight">$&</mark>`
    );

    return (
        <li className={searchListItemClass} onClick={handleItemClick}>
            {searchInput ? (
                <span dangerouslySetInnerHTML={{ __html: newText }} />
            ) : (
                listItem
            )}
        </li>
    );
};

export default SearchListItem;
