import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import Button from 'shared/components/Button';
import SectionHeader from 'shared/components/SectionHeader';

import { thunkUpdatePassword } from 'redux/slices/users';

type FormValues = {
    newPassword: string;
    confirmNewPassword: string;
};

const PasswordSettings = () => {
    const dispatch = useDispatch();
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        trigger,
        watch,
        getValues,
        formState: { errors, isValid, isDirty }
    } = useForm<FormValues>({
        mode: 'onChange'
    });
    const newPassword = watch('newPassword');
    const confirmNewPassword = watch('confirmNewPassword');
    const onSubmit = async () => {
        if (isEqual(newPassword, confirmNewPassword)) {
            setButtonLoading(true);
            await dispatch(thunkUpdatePassword(newPassword));
            setButtonLoading(false);
        }
    };

    useEffect(() => {
        if (isDirty) {
            trigger('confirmNewPassword');
        }
    }, [watch('newPassword'), isDirty]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ marginBottom: '10px' }}>
                <SectionHeader componentTag='h3' title='Password' />{' '}
            </div>
            <div>
                <input
                    {...register('newPassword', {
                        required: {
                            value: true,
                            message: 'Password is required'
                        },
                        minLength: {
                            value: 6,
                            message:
                                'Confirm password must be more than 6 characters'
                        },
                        maxLength: {
                            value: 20,
                            message:
                                'Confirm password cannot be more than 20 characters'
                        }
                    })}
                    type='password'
                    placeholder='New Password'
                ></input>
            </div>
            {errors.newPassword && (
                <div className='errorMessage'>{errors.newPassword.message}</div>
            )}
            <div>
                <input
                    {...register('confirmNewPassword', {
                        required: {
                            value: true,
                            message: 'Confirm password is required'
                        },
                        minLength: {
                            value: 6,
                            message:
                                'Confirm password must be more than 6 characters'
                        },
                        maxLength: {
                            value: 20,
                            message:
                                'Confirm password cannot be more than 20 characters'
                        },
                        validate: (value) => {
                            const { newPassword } = getValues();

                            return (
                                value === newPassword ||
                                'The passwords do not match'
                            );
                        }
                    })}
                    type='password'
                    placeholder='Confirm New Password'
                ></input>
            </div>
            {errors.confirmNewPassword && (
                <div className='errorMessage'>
                    {errors.confirmNewPassword.message}
                </div>
            )}
            <span className='notice notice__left'>
                If changed, you will have to log back in using the new password.
            </span>
            <Button
                buttonDisabled={!isValid || buttonLoading}
                buttonType='submit'
                isLoading={buttonLoading}
            >
                Save
            </Button>
        </form>
    );
};

export default PasswordSettings;
