import React from 'react';

import Pill from 'shared/components/Pill';

import { WordReference } from 'types/reference';
import { Word } from 'types/word';

import './RecentlyAddedWords.scss';

// Add Reference and Word type
type Props = {
  references: WordReference[];
  lastTenWords: Word[];
};

const RecentlyAddedWords = ({ references, lastTenWords }: Props) => {
  const variationsCount = lastTenWords.reduce((acc, cur) => {
    return cur.variations.length + acc;
  }, 0);
  const lastTenWordsOnly = lastTenWords.map((item) => item.word);
  const rewwindsCount = references.reduce((acc, cur) => {
    if (lastTenWordsOnly.includes(cur.word)) {
      return cur.references.length + acc;
    }

    return acc;
  }, 0);

  return (
    <div className='recentlyAddedContainer'>
      <h3>Last 10 words</h3>
      <div className='stats'>
        <div>
          <p>variations</p>
          <span>{variationsCount}</span>
        </div>
        <div>
          <p>rewwinds</p>
          <span>{rewwindsCount}</span>
        </div>
      </div>
      {lastTenWords.length && (
        <div className='recentlyAddedWords'>
          {lastTenWords.map((word) => (
            <Pill key={word.word} item={word.word} type='word' />
          ))}
        </div>
      )}
    </div>
  );
};

export default RecentlyAddedWords;
