import React, { useState } from 'react';
import { FiMail } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { UserInfo } from 'firebase/auth';

import * as ROUTES from 'constants/routes';
import { firebaseSendEmailVerificationLink } from 'api/email';

import Button from '../Button';
import SmallLoader from '../SmallLoader';

import { User } from 'types/user';

import './NotVerified.scss';

type Props = {
    userInfo: User | null;
    isLinkOnly?: boolean;
};

const NotVerified = ({ userInfo, isLinkOnly }: Props) => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    // const { email } = userInfo;
    const hasEmail = userInfo?.email !== '';

    const handleSendLink = async () => {
        setButtonLoading(true);
        try {
            await firebaseSendEmailVerificationLink();
            toast.success('Verification link sent');
        } catch (err) {
            toast.error('Error sending link');
        } finally {
            setButtonLoading(false);
        }
    };

    if (isLinkOnly) {
        const emailRender = buttonLoading ? <SmallLoader /> : 'Resend link';

        return (
            <>
                {hasEmail ? (
                    <a
                        className='sendVerificationEmailLink'
                        onClick={handleSendLink}
                    >
                        {emailRender}
                    </a>
                ) : (
                    <Link className='goToSettingsLink' to={ROUTES.ACCOUNT}>
                        Choose Email
                    </Link>
                )}
            </>
        );
    }

    return (
        <div className='notVerifiedContainer'>
            <FiMail />
            <h2>Your email has not been verified</h2>
            <p>
                Check your email <strong>{userInfo?.email}</strong> for a
                verification link or click the button below to resend the
                verification link.
            </p>
            <br />
            <p>
                If you have already verified your email, please{' '}
                <strong>refresh</strong>.
            </p>
            <br />
            <p>
                If you do not verify your email within{' '}
                <strong> 24 hours</strong>, your account will be deactivated.
            </p>
            {hasEmail ? (
                <Button
                    buttonType='button'
                    buttonClass='sendVerificationEmailButton'
                    onClick={handleSendLink}
                    isLoading={buttonLoading}
                    buttonDisabled={buttonLoading}
                >
                    Resend Verification Link
                </Button>
            ) : (
                <Link className='goToSettingsLink' to={ROUTES.ACCOUNT}>
                    Choose Email
                </Link>
            )}
        </div>
    );
};

export default NotVerified;
