import React, { useState, useMemo } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { AiFillStar } from 'react-icons/ai';
import { VscArchive } from 'react-icons/vsc';
import { FiUserPlus } from 'react-icons/fi';

import Tooltip from 'shared/components/Tooltip/Tooltip';
import FavoritesModal from './ActivityStatsModals/FavoritesModal';
import UserAddedModal from './ActivityStatsModals/UserAddedModal';
import ArchivedModal from './ActivityStatsModals/ArchivedModal';
import ReferencesModal from './ActivityStatsModals/ReferencesModal';

import { WordReference } from 'types/reference';
import { Variation, Word } from 'types/word';

import styles from './ActivityStats.module.scss';
import 'react-circular-progressbar/dist/styles.css';

const PERCENTAGE_STYLES = buildStyles({
    backgroundColor: '#0f131d',
    textColor: '#3f4f7b',
    textSize: '25px',
    pathColor: '#3f4f7b',
    trailColor: '#0f131d'
});

type Props = {
    words: Word[];
    references: WordReference[];
};

type ModalTypes = 'favorites' | 'archived' | 'references' | 'userAdded';

type UserAddedWords = {
    words: Word[];
    value: number;
    variations: Variation[];
};

export type Stat = {
    percentage?: number;
    value: number;
    words?: Word[];
    wordsWith?: WordReference[];
    wordsWithout?: Word[];
    variations?: Variation[];
    userAddedWords?: UserAddedWords;
};

function ActivityStats({ words, references }: Props) {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalType, setModalType] = useState<ModalTypes>('favorites');
    const handleModalClose = () => {
        setIsModalOpen(false);
    };
    const handleModalOpen = (type: ModalTypes) => {
        setModalType(type);
        setIsModalOpen(true);
    };

    // Archived
    const archived = useMemo(
        () => ({
            percentage: Math.round(
                (words.filter((word) => word.archived).length / words.length) *
                    100
            ),
            value: words.filter((word) => word.archived).length,
            words: words.filter((word) => word.archived)
        }),
        [words]
    );

    // Favorites
    const favorites = useMemo(
        () => ({
            percentage: Math.round(
                (words.filter((word) => word.favorite).length / words.length) *
                    100
            ),
            value: words.filter((word) => word.favorite).length,
            words: words.filter((word) => word.favorite)
        }),
        [words]
    );

    // Rewwinds
    const referencesCount = useMemo(
        () => ({
            percentage: Math.round(
                (references.filter(
                    (reference) => reference.references.length > 0
                ).length /
                    words.length) *
                    100
            ),
            value: references.filter(
                (reference) => reference.references.length > 0
            ).length,
            wordsWith: references.filter(
                (reference) => reference.references.length > 0
            ),
            wordsWithout: words.filter(
                (word) =>
                    !references
                        .filter((reference) => reference.references.length > 0)
                        .map((item) => item.word)
                        .includes(word.word)
            )
        }),
        [references, words]
    );

    // User Added
    const variationsAddedManually = useMemo(
        () =>
            words.reduce(
                (
                    acc: {
                        words: Word[];
                        variations: Variation[];
                        value: number;
                    },
                    cur
                ) => {
                    const hasManuallyAddedWord = cur.variations.some(
                        (variation: Variation) => variation.addedManually
                    );

                    if (hasManuallyAddedWord) {
                        const variationsToAdd = cur.variations.filter(
                            (variation: Variation) => variation.addedManually
                        );

                        acc.words = [...acc.words, cur];
                        acc.variations = [
                            ...acc.variations,
                            ...variationsToAdd
                        ];
                        acc.value = variationsToAdd.length + acc.value;
                    }

                    return acc;
                },
                { words: [], variations: [], value: 0 }
            ),
        [words]
    );

    const variationsCount = words.reduce((acc, cur) => {
        return cur.variations.length + acc;
    }, 0);

    const userAdded = useMemo(
        () => ({
            percentage: Math.round(
                (variationsAddedManually.value / variationsCount) * 100
            ),
            value: variationsAddedManually.value,
            userAddedWords: variationsAddedManually
        }),
        [variationsAddedManually, variationsCount]
    );

    return (
        <>
            <div className={styles.activityStatsContainer}>
                <div
                    className={styles.activityStatsItem}
                    onClick={() =>
                        favorites.value > 0 && handleModalOpen('favorites')
                    }
                >
                    <div className={styles.backgroundImage}>
                        <AiFillStar className='icon actionIcon__small' />
                    </div>
                    <h3>Favorites</h3>
                    <div className={styles.statsCount}>
                        <h2>{favorites.value}</h2>
                        {favorites.value > 0 && (
                            <Tooltip
                                content={`${favorites.percentage}% of your words are favorites`}
                            >
                                <div className={styles.statsPercentage}>
                                    <CircularProgressbar
                                        value={favorites.percentage}
                                        text={`${favorites.percentage}%`}
                                        background
                                        backgroundPadding={6}
                                        styles={PERCENTAGE_STYLES}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div
                    className={styles.activityStatsItem}
                    onClick={() =>
                        archived.value > 0 && handleModalOpen('archived')
                    }
                >
                    <div className={styles.backgroundImage}>
                        <VscArchive className='icon actionIcon__small' />
                    </div>
                    <h3>Archived</h3>
                    <div className={styles.statsCount}>
                        <h2>{archived.value}</h2>
                        {archived.value > 0 && (
                            <Tooltip
                                content={`${archived.percentage}% of your words are archived`}
                            >
                                <div className={styles.statsPercentage}>
                                    <CircularProgressbar
                                        value={archived.percentage}
                                        text={`${archived.percentage}%`}
                                        background
                                        backgroundPadding={6}
                                        styles={PERCENTAGE_STYLES}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div
                    className={styles.activityStatsItem}
                    onClick={() =>
                        referencesCount.value && handleModalOpen('references')
                    }
                >
                    <h3>Rewwinds</h3>
                    <div className={styles.statsCount}>
                        <h2>{referencesCount.value}</h2>
                        {referencesCount.value > 0 && (
                            <Tooltip
                                content={`${referencesCount.percentage}% of your words have rewwinds`}
                            >
                                <div className={styles.statsPercentage}>
                                    <CircularProgressbar
                                        value={referencesCount.percentage}
                                        text={`${referencesCount.percentage}%`}
                                        background
                                        backgroundPadding={6}
                                        styles={PERCENTAGE_STYLES}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div
                    className={styles.activityStatsItem}
                    onClick={() =>
                        userAdded.value && handleModalOpen('userAdded')
                    }
                >
                    <div className={styles.backgroundImage}>
                        <FiUserPlus className='icon actionIcon__small' />
                    </div>
                    <h3>User added</h3>
                    <div className={styles.statsCount}>
                        <h2>{userAdded.value}</h2>
                        {userAdded.value > 0 && (
                            <Tooltip
                                content={`${userAdded.percentage}% of your variations were added manually`}
                            >
                                <div className={styles.statsPercentage}>
                                    <CircularProgressbar
                                        value={userAdded.percentage}
                                        text={`${userAdded.percentage}%`}
                                        background
                                        backgroundPadding={6}
                                        styles={PERCENTAGE_STYLES}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
            {modalType === 'favorites' && (
                <FavoritesModal
                    isModalOpen={isModalOpen}
                    onClose={handleModalClose}
                    favorites={favorites}
                />
            )}
            {modalType === 'archived' && (
                <ArchivedModal
                    isModalOpen={isModalOpen}
                    onClose={handleModalClose}
                    archived={archived}
                />
            )}
            {modalType === 'userAdded' && (
                <UserAddedModal
                    isModalOpen={isModalOpen}
                    onClose={handleModalClose}
                    userAdded={userAdded}
                />
            )}
            {modalType === 'references' && (
                <ReferencesModal
                    isModalOpen={isModalOpen}
                    onClose={handleModalClose}
                    referencesCount={referencesCount}
                />
            )}
        </>
    );
}

export default React.memo(ActivityStats);
