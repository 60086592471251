import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { client } from 'index';
import { firebaseErrors } from 'constants/auth.constants';

import { isValidEmail } from 'helpers/authHelper';

import Button from 'shared/components/Button';

import * as ROUTES from 'constants/routes';

import {
    setAuthUser,
    setUserInfo,
    thunkUpdateSettings
} from 'redux/slices/users';
import { firebaseCreateUserWithEmailAndPassword } from 'api/auth';
import { QUERY_GET_USER } from 'api/users';

import { User } from 'types/user';
import { AuthError } from 'types/auth';

import './SignUp.scss';

type FormValues = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
};

const SignUp = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [apiError, setApiError] = useState('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        reset,
        watch,
        trigger,
        getValues,
        formState: { errors, isValid, isDirty }
    } = useForm<FormValues>({
        mode: 'onChange'
    });
    const onSubmit = async (data: FormValues) => {
        const { firstName, lastName, email, password } = data;
        setButtonLoading(true);
        setApiError('');

        try {
            const authUser = await firebaseCreateUserWithEmailAndPassword(
                email,
                password,
                firstName,
                lastName
            );

            await dispatch(setAuthUser(authUser));

            const { data, error } = await client.query<{
                firestoreGetUser: User;
            }>({
                query: QUERY_GET_USER,
                variables: { userID: authUser.uid }
            });

            if (error) {
                toast.error('Authentication error');
            }

            if (data && data.firestoreGetUser) {
                const userInfo = data.firestoreGetUser;
                await dispatch(setUserInfo(userInfo));

                if (userInfo.settings !== null) {
                    dispatch(thunkUpdateSettings(userInfo.settings));
                }

                setApiError('');
                reset({
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    confirmPassword: ''
                });

                history.push(ROUTES.WORDS);
            }
        } catch (err: any) {
            setApiError(firebaseErrors[err as keyof AuthError] || err);
            reset({
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: ''
            });
        } finally {
            setButtonLoading(false);
        }
    };

    useEffect(() => {
        setButtonLoading(false);
    }, []);

    useEffect(() => {
        if (isDirty) {
            setApiError('');
        }

        if (isDirty && watch('password').length) {
            trigger('confirmPassword');
        }
    }, [watch('password'), isDirty]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Sign Up</h2>
            <div>
                <input
                    {...register('firstName', {
                        required: {
                            value: true,
                            message: 'First name is required'
                        }
                    })}
                    type='text'
                    placeholder='First name'
                ></input>
            </div>
            {errors.firstName && (
                <div className='errorMessage'>{errors.firstName.message}</div>
            )}
            <div>
                <input
                    {...register('lastName', {
                        required: {
                            value: true,
                            message: 'Last name is required'
                        }
                    })}
                    type='text'
                    placeholder='Last name'
                ></input>
            </div>
            {errors.lastName && (
                <div className='errorMessage'>{errors.lastName.message}</div>
            )}
            <div>
                <input
                    {...register('email', {
                        required: {
                            value: true,
                            message: 'Email is required'
                        },
                        validate: (value) => isValidEmail(value)
                    })}
                    type='email'
                    placeholder='Email'
                ></input>
            </div>
            {errors.email && (
                <div className='errorMessage'>{errors.email.message}</div>
            )}
            <div>
                <input
                    {...register('password', {
                        required: {
                            value: true,
                            message: 'Password is required'
                        },
                        minLength: {
                            value: 6,
                            message: 'Password must be at least 6 characters'
                        },
                        maxLength: {
                            value: 20,
                            message:
                                'Password cannot be more than 20 characters'
                        }
                    })}
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Password'
                ></input>
                <span className='showPasswordIcon'>
                    {showPassword ? (
                        <FiEyeOff onClick={() => setShowPassword(false)} />
                    ) : (
                        <FiEye onClick={() => setShowPassword(true)} />
                    )}
                </span>
            </div>
            {errors.password && (
                <div className='errorMessage'>{errors.password.message}</div>
            )}
            <div>
                <input
                    {...register('confirmPassword', {
                        required: {
                            value: true,
                            message: 'Confirm password is required'
                        },
                        minLength: {
                            value: 6,
                            message:
                                'Confirm password must be at least 6 characters'
                        },
                        maxLength: {
                            value: 20,
                            message:
                                'Confirm password cannot be more than 20 characters'
                        },
                        validate: (value) => {
                            const { password } = getValues();

                            return (
                                value === password ||
                                'The passwords do not match'
                            );
                        }
                    })}
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Confirm password'
                ></input>
                <span className='showPasswordIcon'>
                    {showPassword ? (
                        <FiEyeOff onClick={() => setShowPassword(false)} />
                    ) : (
                        <FiEye onClick={() => setShowPassword(true)} />
                    )}
                </span>
            </div>
            {errors.confirmPassword && (
                <div className='errorMessage'>
                    {errors.confirmPassword.message}
                </div>
            )}
            {apiError !== '' && <div className='errorMessage'>{apiError}</div>}
            <Button
                buttonDisabled={!isValid || buttonLoading}
                buttonType='submit'
                isLoading={buttonLoading}
            >
                Create Account
            </Button>
        </form>
    );
};

export default SignUp;
