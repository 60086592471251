import { gql } from '@apollo/client';

export const MUTATION_ADD_COMMUNITY_RFERENCE = gql`
    mutation addCommunityReference(
        $word: CommunityWordInput!
        $reference: CommunityReferenceInput!
        $user: CommunityUserInput!
    ) {
        addCommunityReference(word: $word, reference: $reference, user: $user)
    }
`;

export const MUTATION_UPDATE_COMMUNITY_REFERENCE = gql`
    mutation updateCommunityReference(
        $word: CommunityWordInput!
        $updatedReference: CommunityReferenceInput!
        $user: CommunityUserInput!
    ) {
        updateCommunityReference(
            word: $word
            updatedReference: $updatedReference
            user: $user
        )
    }
`;

export const MUTATION_DELETE_COMMUNITY_REFERENCE = gql`
    mutation deleteCommunityReference(
        $word: CommunityWordInput!
        $reference: CommunityReferenceInput!
        $user: CommunityUserInput!
    ) {
        deleteCommunityReference(
            word: $word
            reference: $reference
            user: $user
        )
    }
`;
