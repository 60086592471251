import { LanguageType } from 'types/settings';

export const PARTS_OF_SPEECH = [
    'noun',
    'pronoun',
    'adjective',
    'verb',
    'adverb',
    'preposition',
    'conjunction',
    'determiner',
    'interjection'
];
export const VOICES_GB = ['Alice', 'Nancy', 'Lily', 'Harry'];
export const VOICES_US = ['Amy', 'Mary', 'John', 'Mike'];
export const LANGUAGES: LanguageType[] = [
    { code: 'en-gb', language: 'GB' },
    {
        code: 'en-us',
        language: 'US'
    }
];
export const WORD_DETAIL_SECTIONS = [
    'pronunciation',
    'antonyms',
    'synonyms',
    'rhymes'
];
export const WORD_SHARE_PERMISSIONS = ['Private', 'Public'];
