import React, { useMemo } from 'react';

import WordListItem from 'views/Words/WordListItem';
import SectionHeader from 'shared/components/SectionHeader';

import { Word } from 'types/word';
import { Collection } from 'types/collection';

import styles from './IncaseYouForgot.module.scss';

type Props = {
    words: Word[];
};

function IncaseYouForgot({ words }: Props) {
    const wordsCopy = words.slice();
    const wordsToShow = useMemo(() => {
        return wordsCopy
            .reverse()
            .slice(0, words.length / 2 + 1)
            .sort(() => 0.5 - Math.random())
            .slice(0, 3);
    }, [words, wordsCopy]);

    return (
        <div className={styles.inCaseYouForgot}>
            <SectionHeader
                title='In case you forgot'
                subTitle='Random words to keep you remembering'
                componentTag='h3'
            />
            <br />
            <div className={styles.wordsList}>
                {wordsToShow.slice(0, 3).map((word) => (
                    <WordListItem
                        key={word.word}
                        word={word}
                        section='words'
                        showDetails={false}
                    />
                ))}
            </div>
        </div>
    );
}

export default React.memo(IncaseYouForgot);
