import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid';
import { FiCheck, FiX } from 'react-icons/fi';

import { thunkAddWord, selectSearchedWordInWords } from 'redux/slices/words';
import { selectUserVerified, selectUserSettings } from 'redux/slices/users';

import Button from 'shared/components/Button';
import SmallLoader from 'shared/components/SmallLoader';
import Tooltip from 'shared/components/Tooltip';
import WordPermissionsToggle from 'views/Words/WordPermissionsToggle';

import { Word, WordResult } from 'types/word';
import { WordReference } from 'types/reference';

import './SearchResultsItem.scss';

type Props = {
    word: string;
    details: WordResult;
};

const SearchResultsItem = ({ word, details }: Props) => {
    const dispatch = useDispatch();
    const userVerified = useSelector(selectUserVerified);
    const userWord = useSelector(selectSearchedWordInWords);
    const { wordPermissions } = useSelector(selectUserSettings);
    const [showReference, setShowReference] = useState<boolean>(false);
    const [referenceText, setReferenceText] = useState<string>('');
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [isPublic, setIsPublic] = useState<boolean>(
        wordPermissions?.id === 'public' ? true : false
    );
    const handleShowReference = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (userVerified) {
            setShowReference(!showReference);
        }
    };
    const handleAddWord = async (e: React.MouseEvent) => {
        e.stopPropagation();

        setButtonLoading(true);
        const variationID = uniqid(`${word.toLowerCase()}-`);
        const dateAdded = new Date().toISOString();
        const wordToAdd = {
            word,
            variations: [
                {
                    partOfSpeech: details.partOfSpeech,
                    definition: details.definition,
                    examples: details.examples && details.examples.slice(0, 2),
                    defaultVariation: false,
                    dateAdded,
                    variationID,
                    word,
                    addedManually: false,
                    isPublic: isPublic
                }
            ]
        } as Word;
        const referenceToAdd =
            referenceText.trim() !== ''
                ? ({
                      word: wordToAdd.word,
                      references: [
                          {
                              dateAdded,
                              referenceID: uniqid(),
                              reference: referenceText,
                              isPublic: isPublic
                          }
                      ]
                  } as WordReference)
                : null;

        await dispatch(thunkAddWord(wordToAdd, referenceToAdd));
        setButtonLoading(false);
        setShowReference(false);
    };
    const userHasVariation = () => {
        if (details && userWord) {
            return !!userWord.variations.filter(
                (variation) => variation.definition === details.definition
            ).length;
        }

        return false;
    };
    const handleWordPermissionsToggle = (permission: boolean) => {
        setIsPublic(permission);
    };

    if (details) {
        return (
            // * This classless div is necessary
            <div>
                <div className='searchItem'>
                    <div
                        className='searchItemDefinition'
                        onClick={(e) => handleShowReference(e)}
                    >
                        <span className='partOfSpeech'>
                            {details.partOfSpeech}
                        </span>
                        <h4>{details.definition}</h4>
                        {details.examples &&
                            details.examples.map((example, key) => (
                                <span className='example' key={key}>
                                    {example}
                                    {details.examples &&
                                        details.examples.length - 1 !== key && (
                                            <span className='divider'>
                                                &bull;
                                            </span>
                                        )}
                                </span>
                            ))}
                    </div>
                    {showReference && !userHasVariation() && (
                        <div className='searchItemDetails'>
                            <div className='referenceContainer'>
                                <textarea
                                    placeholder={
                                        userWord
                                            ? 'How have you re-discovered this word?'
                                            : 'How did you discover this word?'
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        setReferenceText(e.target.value);
                                    }}
                                />
                            </div>
                            <div className='searchItemControls'>
                                <WordPermissionsToggle
                                    onToggle={handleWordPermissionsToggle}
                                    wordPermissions={wordPermissions}
                                    // isDisabled={referenceText.trim().length <= 0}
                                    isDisabled
                                />
                                <div className='searchItemControlsButtons'>
                                    <Button
                                        onClick={(e) => handleAddWord(e)}
                                        buttonDisabled={buttonLoading}
                                        buttonCategory='icon'
                                        buttonSize='small'
                                    >
                                        {buttonLoading ? (
                                            <SmallLoader />
                                        ) : (
                                            <FiCheck className='icon buttonIcon' />
                                        )}
                                    </Button>
                                    <Button
                                        onClick={(e) => handleShowReference(e)}
                                        buttonDisabled={buttonLoading}
                                        buttonCategory='iconAlternate'
                                        buttonSize='small'
                                    >
                                        <FiX className='icon buttonIcon' />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {userHasVariation() && (
                        <Tooltip
                            content='Variation already added'
                            placement='top'
                            arrow
                        >
                            <div className='searchItemFooter'>
                                <FiCheck />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
        );
    } else return null;
};

export default SearchResultsItem;
