import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { client } from 'index';

import {
    MUTATION_UPDATE_USER_SETTINGS,
    MUTATION_UPDATE_USER_INFO
} from 'api/users';
import { MUTATION_UPDATE_USER_EMAIL } from 'api/email';
import { MUTATION_UPDATE_USER_PASSWORD } from 'api/auth';

import { AuthUser, User, UserSettings } from 'types/user';

export type UsersState = {
    authUser: AuthUser | null;
    userInfo: User | null;
    settings: UserSettings;
};

type UsersSlice = {
    users: UsersState;
};

const initialState: UsersState = {
    authUser: null,
    userInfo: null,
    settings: {
        defaultVoice: { id: 'amy', name: 'Amy' },
        noteDisplayType: { type: 'horizontal' },
        wordPermissions: { id: 'private' },
        language: { code: 'en-us', language: 'US' },
        speechSpeed: { speed: 0 },
        noteDefaultView: { view: 'all' },
        defaultPage: { page: '/words' }
    }
};

// createSlice() automatically generates action creators and action types that correspond to the reducers and state.
export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setAuthUser: (state, action) => {
            state.authUser = action.payload;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        setUserEmail: (state, action) => {
            if (state.userInfo !== null) {
                state.userInfo.email = action.payload;
            }
        },
        setUserSettings: (state, action) => {
            state.settings = action.payload;
        }
    }
});

export const { setAuthUser, setUserInfo, setUserSettings, setUserEmail } =
    usersSlice.actions;

//ASYNC ACTIONS (THUNKS)
export const resetUsersSlice = () => (dispatch: React.Dispatch<any>) => {
    dispatch(setAuthUser(null));
    dispatch(setUserInfo(null));
    dispatch(
        setUserSettings({
            defaultVoice: { id: 'amy', name: 'Amy' },
            noteDisplayType: { type: 'horizontal' },
            wordPermissions: { id: 'private' },
            language: { code: 'en-us', language: 'US' },
            speechSpeed: { speed: 0 },
            noteDefaultView: { view: 'all' },
            defaultPage: { page: '/words' }
        })
    );
};

export const thunkUpdateUserInfo =
    (userInfo: any) => async (dispatch: React.Dispatch<any>, getState: any) => {
        const {
            users: {
                userInfo: stateUserInfo,
                authUser: { uid }
            }
        } = getState();
        const newUserInfo = { ...stateUserInfo, ...userInfo };

        try {
            const { data, errors } = await client.mutate<{
                firestoreUpdateUserInfo: boolean;
            }>({
                mutation: MUTATION_UPDATE_USER_INFO,
                variables: { userID: uid, userInfo }
            });

            if (errors) {
                toast.error('Error updating info');
            }

            if (data && data.firestoreUpdateUserInfo) {
                await dispatch(setUserInfo(newUserInfo));
                toast.success('Info updated');
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkUpdateEmail =
    (email: string) => async (dispatch: React.Dispatch<any>, getState: any) => {
        const {
            users: {
                userInfo: { userID }
            }
        } = getState();

        try {
            const { data, errors } = await client.mutate<{
                firebaseUpdateUserEmail: boolean;
            }>({
                mutation: MUTATION_UPDATE_USER_EMAIL,
                variables: { userID, email }
            });

            if (errors) {
                throw errors;
                // toast.error('Error updating email');
            }

            if (data && data.firebaseUpdateUserEmail) {
                await dispatch(setUserEmail(email));
                toast.success('Email updated');
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkUpdatePassword =
    (password: any) => async (dispatch: React.Dispatch<any>, getState: any) => {
        const {
            users: {
                userInfo: { userID }
            }
        } = getState();

        try {
            const { data, errors } = await client.mutate<{
                firebaseUpdateUserPassword: boolean;
            }>({
                mutation: MUTATION_UPDATE_USER_PASSWORD,
                variables: { userID, password }
            });

            if (errors) {
                toast.error('Error updating password');
            }

            if (data && data.firebaseUpdateUserPassword) {
                toast.success('Password updated');
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkUpdateSettings =
    (newSetting: UserSettings, showAlert?: boolean) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const {
            users: {
                userInfo: { userID },
                settings: stateSettings
            }
        } = getState();

        const newSettings = { ...stateSettings, ...newSetting };

        try {
            const { data, errors } = await client.mutate<{
                updateUserSettings: boolean;
            }>({
                mutation: MUTATION_UPDATE_USER_SETTINGS,
                variables: { userID, userSettings: newSettings }
            });

            if (errors) {
                toast.error('Error updating settings');
            }

            if (data && data.updateUserSettings) {
                const settings = {
                    defaultVoice:
                        newSettings.defaultVoice === null
                            ? stateSettings.defaultVoice
                            : newSettings.defaultVoice,
                    noteDisplayType:
                        newSettings.noteDisplayType === null
                            ? stateSettings.noteDisplayType
                            : newSettings.noteDisplayType,
                    language:
                        newSettings.language === null
                            ? stateSettings.language
                            : newSettings.language,
                    speechSpeed:
                        newSettings.speechSpeed === null
                            ? stateSettings.speechSpeed
                            : newSettings.speechSpeed,
                    noteDefaultView:
                        newSettings.noteDefaultView === null
                            ? stateSettings.noteDefaultView
                            : newSettings.noteDefaultView,
                    wordPermissions:
                        newSettings.wordPermissions === null
                            ? stateSettings.wordPermissions
                            : newSettings.wordPermissions,
                    defaultPage:
                        newSettings.defaultPage === null
                            ? stateSettings.defaultPage
                            : newSettings.defaultPage
                };

                dispatch(setUserSettings(settings));

                if (showAlert) {
                    toast.success('Settings updated');
                }
            }
        } catch (err) {
            toast.error('Error updating settings');
        }
    };

// SELECTORS
export const selectAuthUser = (state: UsersSlice): AuthUser | null =>
    state.users.authUser;
export const selectUserInfo = (state: UsersSlice): User | null =>
    state.users.userInfo;
export const selectUserVerified = (state: UsersSlice): boolean =>
    state.users.authUser !== null ? state.users.authUser.emailVerified : false;
export const selectUserSettings = (state: UsersSlice): UserSettings =>
    state.users.settings;

export default usersSlice.reducer;
