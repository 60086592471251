import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FiX, FiCheck } from 'react-icons/fi';

import { thunkUpdateUserWordVariation } from 'redux/slices/words';

import Button from 'shared/components/Button';

import { Variation } from 'types/word';

import styles from './EditWordDefinition.module.scss';

type Props = {
    variation: Variation;
    onClose: () => void;
};

const EditWordDefinition = ({ variation, onClose }: Props) => {
    const dispatch = useDispatch();
    const [definitionText, setDefinitionText] = useState<string>('');
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const buttonDisabled = () => {
        return (
            definitionText.trim() === '' ||
            variation.definition === definitionText
        );
    };

    const handleAddClick = async () => {
        setButtonLoading(true);
        const newVariation = {
            ...variation,
            definition: definitionText
        };

        await dispatch(thunkUpdateUserWordVariation(newVariation));

        onClose();
        setButtonLoading(false);
    };

    const handleCancel = () => {
        onClose();
        setDefinitionText('');
    };

    useEffect(() => {
        setDefinitionText(variation.definition);
    }, [variation]);

    return (
        <div className={styles.editWordContainer}>
            <div>
                <textarea
                    placeholder='Edit word definition'
                    rows={5}
                    onChange={(e) => setDefinitionText(e.target.value)}
                    value={definitionText}
                />
                <div className={styles.editWordControls}>
                    <Button
                        onClick={handleAddClick}
                        isLoading={buttonLoading}
                        buttonDisabled={buttonDisabled()}
                        buttonCategory='icon'
                        buttonSize='small'
                    >
                        <FiCheck className='icon buttonIcon' />
                    </Button>
                    <Button
                        onClick={handleCancel}
                        buttonCategory='iconAlternate'
                        buttonSize='small'
                    >
                        <FiX className='icon buttonIcon' />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditWordDefinition;
