import React from 'react';
import { GiIsland } from 'react-icons/gi';

import SearchResultsItem from './SearchResultsItem';
import WordOptions from '../../Words/WordOptions';
import Loader from 'shared/components/Loader';
import NoData from 'shared/components/NoData';

import { SearchedWordResult, Word } from 'types/word';

import './SearchResults.scss';

type Props = {
    searchedWord?: SearchedWordResult;
    isLoading?: boolean;
};

const SearchResults = ({ searchedWord, isLoading }: Props): JSX.Element => {
    if (isLoading) {
        return (
            <div className='searchResultContainer'>
                <div className='searchLoaderContainer'>
                    <Loader alternate />
                </div>
            </div>
        );
    }

    if (!searchedWord) {
        return (
            <NoData
                icon={<GiIsland />}
                title='No word selected'
                subTitle='Try selecting/searching for another word'
            />
        );
    }

    return (
        <div className='searchResultContainer'>
            <div className='searchResultHeader'>
                <h3>
                    Choose variation to add: <span>{searchedWord.word}</span>
                </h3>
                <WordOptions word={searchedWord as unknown as Word} />
            </div>
            <div className='searchItemContainer'>
                {searchedWord.results.map((item, key) => {
                    return (
                        <SearchResultsItem
                            key={key}
                            word={searchedWord.word}
                            details={item}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default SearchResults;
