import { gql } from '@apollo/client';

export const QUERY_GET_USER_REFERENCES = gql`
    query getUserReferences($userID: ID!) {
        getUserReferences(userID: $userID) {
            references {
                references {
                    referenceID
                    reference
                    dateAdded
                }
                word
            }
        }
    }
`;

export const MUTATION_ADD_USER_REFERENCE = gql`
    mutation addUserReference($reference: WordReferenceInput!, $userID: ID!) {
        addUserReference(reference: $reference, userID: $userID)
    }
`;

export const MUTATION_UPDATE_USER_REFERENCE = gql`
    mutation updateUserReference(
        $newReference: WordReferenceItem!
        $referenceWord: String!
        $userID: ID!
    ) {
        updateUserReference(
            newReference: $newReference
            referenceWord: $referenceWord
            userID: $userID
        )
    }
`;

export const MUTATION_DELETE_USER_REFERENCE = gql`
    mutation deleteUserReference(
        $referenceID: ID!
        $referenceWord: String!
        $userID: ID!
    ) {
        deleteUserReference(
            referenceID: $referenceID
            referenceWord: $referenceWord
            userID: $userID
        )
    }
`;

export const MUTATION_DELETE_WHOLE_USER_REFERENCE = gql`
    mutation deleteWholeUserReference($words: [String]!, $userID: ID!) {
        deleteWholeUserReference(words: $words, userID: $userID)
    }
`;
