import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FcGoogle } from 'react-icons/fc';
import { AiOutlineTwitter } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { UserCredential } from 'firebase/auth';

import { client } from 'index';

import SignUp from 'views/Authentication/SignUp';
import SignIn from 'views/Authentication/SignIn';
import Button from 'shared/components/Button';
import Logo from 'shared/components/Logo/Logo';

import { setAuthUser } from 'redux/slices/users';
import { firebaseSignInWithGoogle, firebaseSignInWithTwitter } from 'api/auth';
import { QUERY_GET_USER } from 'api/users';
import { handleSuccessfulAuth } from 'helpers/authHelper';

import { AuthUser, User } from 'types/user';

import './Auth.scss';

const Auth = () => {
    const [showSignUp, setShowSignUp] = useState<boolean>(true);
    const [googleButtonLoading, setGoogleButtonLoading] =
        useState<boolean>(false);
    const [twitterButtonLoading, setTwitterButtonLoading] =
        useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleGoogleClick = async () => {
        setGoogleButtonLoading(true);
        try {
            const authUser = await firebaseSignInWithGoogle();
            dispatch(setAuthUser(authUser.user));

            const { data, error } = await client.query<{
                firestoreGetUser: User;
            }>({
                query: QUERY_GET_USER,
                variables: { userID: authUser.user.uid }
            });

            if (error) {
                toast.error('Authentication error');
            }

            if (data && data.firestoreGetUser) {
                const userInfo = data.firestoreGetUser;

                handleSuccessfulAuth(userInfo, history, dispatch);
            }
        } catch (err) {
            toast.error('Authentication error');
        } finally {
            setGoogleButtonLoading(false);
        }
    };

    const handleTwitterClick = async () => {
        setTwitterButtonLoading(true);
        try {
            const authUser = await firebaseSignInWithTwitter();

            dispatch(setAuthUser(authUser.user));

            const { data, error } = await client.query<{
                firestoreGetUser: User;
            }>({
                query: QUERY_GET_USER,
                variables: { userID: authUser.user.uid }
            });

            if (error) {
                toast.error('Authentication error');
            }

            if (data && data.firestoreGetUser) {
                const userInfo = data.firestoreGetUser;

                handleSuccessfulAuth(userInfo, history, dispatch);
            }
        } catch (err) {
            toast.error('Authentication error');
        } finally {
            setTwitterButtonLoading(false);
        }
    };

    useEffect(() => {
        setGoogleButtonLoading(false);
        setTwitterButtonLoading(false);
    }, []);

    return (
        <div className='authContainer'>
            <div className='authLeft'>
                <div className='authLeftContainer'>
                    <Logo shortForm />
                    <div className='authLeftFooter'>
                        {/* <p>
                            When you discover a{' '}
                            <span className='textHightlight'>great word</span>,
                            do you remember how you found it? Chances are
                            it&apos;ll be a while till you find that word again.
                            With <span className='textHightlight'>Rewwind</span>
                            , you&apos;ll know exactly where to go if you need
                            that word again!
                        </p> */}
                        <p>
                            Save
                            <span className='textHightlight'> words</span>, and
                            how you{' '}
                            <span className='textHightlight'>discovered </span>
                            them.
                        </p>
                        <br />
                        <p>
                            With{' '}
                            <span className='textHightlight'> Rewwind</span>,
                            you&apos;ll know exactly where to go if you need
                            that great word again!
                        </p>
                    </div>
                </div>
            </div>
            <div className='authRight'>
                <div className='mobileLogo'>
                    <Logo shortForm={true} />
                </div>
                <div className='formContainer'>
                    {showSignUp ? <SignUp /> : <SignIn />}
                    <div className='alternateAuth'>
                        <h4>
                            You can also sign {showSignUp ? 'up' : 'in'} with
                        </h4>
                        <div className='alternateAuthButtons'>
                            <Button
                                buttonClass='googleButton'
                                buttonType='button'
                                onClick={handleGoogleClick}
                                isLoading={googleButtonLoading}
                            >
                                <FcGoogle />
                            </Button>
                            <Button
                                buttonClass='twitterButton'
                                buttonType='button'
                                onClick={handleTwitterClick}
                                isLoading={twitterButtonLoading}
                            >
                                <AiOutlineTwitter />
                            </Button>
                        </div>
                    </div>
                    {showSignUp ? (
                        <span className='authFooter'>
                            Already signed up?&nbsp;
                            <a onClick={() => setShowSignUp((state) => !state)}>
                                Sign In
                            </a>
                        </span>
                    ) : (
                        <span className='authFooter'>
                            Don&apos;t have an account?&nbsp;
                            <a onClick={() => setShowSignUp((state) => !state)}>
                                Sign Up
                            </a>
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Auth;
