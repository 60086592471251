import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { isEqual } from 'lodash';
import classNames from 'classnames';

import Button from 'shared/components/Button';
import SectionHeader from 'shared/components/SectionHeader';

import { isValidEmail } from 'helpers/authHelper';

import { thunkUpdateEmail, selectUserVerified } from 'redux/slices/users';

import { User } from 'types/user';

import './EmailSettings.scss';

type Props = {
    userInfo: User | null;
};

type FormValues = {
    email: string;
};

const EmailSettings = ({ userInfo }: Props) => {
    const dispatch = useDispatch();
    const userVerified = useSelector(selectUserVerified);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid }
    } = useForm<FormValues>();
    const watchedEmail = watch('email', userInfo?.email as string);
    const onSubmit = async () => {
        setButtonLoading(true);
        await dispatch(thunkUpdateEmail(watchedEmail));
        setButtonLoading(false);
    };
    const buttonDisabled = () => {
        return (
            !isValid || isEqual(userInfo?.email, watchedEmail) || buttonLoading
        );
    };
    const isVerified = () => {
        return userVerified ? 'verified' : 'unverified';
    };
    const isVerifiedClass = classNames('verificationPill', {
        verificationPill__unverified: !userVerified,
        verificationPill__verified: userVerified
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='emailHeader'>
                <SectionHeader componentTag='h3' title='Email' />
                <span className={isVerifiedClass}>{isVerified()}</span>
            </div>
            <div>
                <input
                    {...register('email', {
                        required: {
                            value: true,
                            message: 'Email is required'
                        },
                        validate: (value) => isValidEmail(value)
                    })}
                    type='email'
                    placeholder={userInfo?.email || 'Email'}
                    value={watchedEmail}
                ></input>
                <span className='notice notice__left'>
                    If changed, you will have to log back in using the new email
                    address.
                </span>
            </div>
            {errors.email && (
                <div className='errorMessage'>{errors.email.message}</div>
            )}
            <Button
                buttonDisabled={buttonDisabled()}
                buttonType='submit'
                isLoading={buttonLoading}
            >
                Save
            </Button>
        </form>
    );
};

export default EmailSettings;
