import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { toast } from 'react-toastify';

import { client } from 'index';

import {
    thunkAddCommunityReference,
    thunkUpdateCommunityReference,
    thunkDeleteCommunityReference
} from '../slices/community';

import {
    QUERY_GET_USER_REFERENCES,
    MUTATION_DELETE_WHOLE_USER_REFERENCE,
    MUTATION_ADD_USER_REFERENCE,
    MUTATION_DELETE_USER_REFERENCE,
    MUTATION_UPDATE_USER_REFERENCE
} from 'api/references';

import { WordReference, Reference } from 'types/reference';
import { Word } from 'types/word';
import { State } from 'redux/store';

export type ReferencesState = {
    wordsReferences: WordReference[];
};

type ReferencesSlice = {
    references: ReferencesState;
};

const initialState: ReferencesState = {
    wordsReferences: []
};

export const referencesSlice = createSlice({
    name: 'references',
    initialState,
    reducers: {
        addWordReference: (state, action) => {
            state.wordsReferences.unshift(action.payload);
        },
        setWordsReferences: (state, action) => {
            state.wordsReferences = action.payload;
        },
        updateWordReference: (state, action) => {
            const {
                payload: { wordIndex, newReferenceIndex, newReference }
            } = action;

            state.wordsReferences[wordIndex].references.splice(
                newReferenceIndex,
                1,
                newReference
            );
        },
        deleteWordReference: (state, action) => {
            const {
                payload: { wordIndex, newWordReference }
            } = action;

            state.wordsReferences.splice(wordIndex, 1, newWordReference);
        },
        deleteWholeWordReference: (state, action) => {
            const {
                payload: { wordIndex }
            } = action;

            state.wordsReferences.splice(wordIndex, 1);
        }
    }
});

export const {
    addWordReference,
    setWordsReferences,
    updateWordReference,
    deleteWordReference,
    deleteWholeWordReference
} = referencesSlice.actions;

// THUNKS
export const thunkGetReferences =
    () => async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            }
        } = state;

        try {
            const { data, error } = await client.query<{
                getUserReferences: { references: WordReference };
            }>({
                query: QUERY_GET_USER_REFERENCES,
                variables: { userID }
            });

            if (error) {
                toast.error('Error getting rewwinds');
            }

            if (data && data.getUserReferences) {
                const { references } = data.getUserReferences;
                await dispatch(setWordsReferences(references));
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkAddWordReference =
    (reference: WordReference, isAddedWithWord?: boolean) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            words: { words: stateWords },
            users: {
                userInfo: { userID }
            },
            references: { wordsReferences: stateWordsReferences }
        } = state;

        try {
            const { data, errors } = await client.mutate<{
                addUserReference: boolean;
            }>({
                mutation: MUTATION_ADD_USER_REFERENCE,
                variables: { userID, reference }
            });

            if (errors) {
                toast.error('Error adding reference');
            }

            if (data && data.addUserReference) {
                const newWordsReferences = stateWordsReferences.slice();
                const referenceIndex = stateWordsReferences.findIndex(
                    (item: WordReference) => item.word === reference.word
                );

                if (referenceIndex >= 0) {
                    newWordsReferences[referenceIndex] = {
                        word: newWordsReferences[referenceIndex].word,
                        references: [
                            ...newWordsReferences[referenceIndex].references,
                            ...reference.references
                        ]
                    };
                } else {
                    newWordsReferences.push(reference);
                }

                if (reference.references[0].isPublic) {
                    const stateWord = stateWords.find(
                        (item: Word) => item.word === reference.word
                    );

                    dispatch(thunkAddCommunityReference(stateWord, reference));
                }

                await dispatch(setWordsReferences(newWordsReferences));

                !isAddedWithWord && toast.success('Rewwind added');
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkUpdateUserWordReference =
    (newReference: Reference) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            words: { activeWord, words: stateWords },
            references: { wordsReferences },
            users: {
                userInfo: { userID }
            }
        } = state;
        const wordIndex = wordsReferences.findIndex(
            (item: any) => item.word === activeWord.word
        );

        if (wordIndex >= 0) {
            // Set new variation
            const references = wordsReferences[wordIndex].references;
            const newReferenceIndex = references.findIndex(
                (item: any) => item.referenceID === newReference.referenceID
            );

            if (newReferenceIndex >= 0) {
                try {
                    const { data, errors } = await client.mutate<{
                        updateUserReference: boolean;
                    }>({
                        mutation: MUTATION_UPDATE_USER_REFERENCE,
                        variables: {
                            newReference,
                            referenceWord: activeWord.word,
                            userID
                        }
                    });

                    if (errors) {
                        toast.error('Error saving rewwind');
                    }

                    if (data && data.updateUserReference) {
                        dispatch(
                            updateWordReference({
                                wordIndex,
                                newReferenceIndex,
                                newReference
                            })
                        );

                        const stateWord = stateWords.find(
                            (item: any) =>
                                item.word === wordsReferences[wordIndex].word
                        );
                        const isCurrentReferencePublic =
                            references[newReferenceIndex].isPublic;
                        // const communityWord = {
                        //     word: stateWord.word,
                        //     isPublic: stateWord.isPublic,
                        //     addedManually: stateWord.addedManually
                        // };

                        if (
                            (!isCurrentReferencePublic &&
                                newReference.isPublic) ||
                            newReference.isPublic
                        ) {
                            await dispatch(
                                thunkUpdateCommunityReference(
                                    stateWord,
                                    newReference
                                )
                            );
                        } else if (
                            isCurrentReferencePublic &&
                            !newReference.isPublic
                        ) {
                            await dispatch(
                                thunkDeleteCommunityReference(
                                    stateWord,
                                    newReference
                                )
                            );
                        }

                        toast.success('Rewwind saved');
                    }
                } catch (err) {
                    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                    toast.error(err);
                }
            }
        }
    };

export const thunkDeleteUserWordReference =
    (reference: any, word: any) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            words: { activeWord, words: stateWords },
            references: { wordsReferences },
            users: {
                userInfo: { userID }
            }
        } = state;
        const wordIndex = wordsReferences.findIndex(
            (item: any) => item.word === activeWord.word
        );

        if (wordIndex >= 0) {
            const references = wordsReferences[wordIndex].references;
            const newReferences = references.filter(
                (item: any) => item.referenceID !== reference.referenceID
            );
            const newWordReference = {
                ...wordsReferences[wordIndex],
                references: newReferences
            };

            // Check if the reference to delete is the only reference for the word
            // If true, delete the whole reference object
            // if false, delete the specific reference from the reference.references array
            if (references.length === 1) {
                if (references[0].referenceText === reference.referenceText) {
                    try {
                        const { data, errors } = await client.mutate<{
                            deleteWholeUserReference: boolean;
                        }>({
                            mutation: MUTATION_DELETE_WHOLE_USER_REFERENCE,
                            variables: {
                                words: [word.word],
                                userID
                            }
                        });

                        if (errors) {
                            toast.error('Error deleting rewwind');
                        }

                        if (data && data.deleteWholeUserReference) {
                            await dispatch(
                                deleteWholeWordReference({ wordIndex })
                            );

                            toast.success('Rewwind deleted');
                        }
                    } catch (err) {
                        // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                        throw new Error(err);
                    }
                }
            } else {
                try {
                    const { data, errors } = await client.mutate<{
                        deleteUserReference: boolean;
                    }>({
                        mutation: MUTATION_DELETE_USER_REFERENCE,
                        variables: {
                            referenceID: reference.referenceID,
                            referenceWord: activeWord.word,
                            userID
                        }
                    });

                    if (errors) {
                        toast.error('Error deleting rewwind');
                    }

                    if (data && data.deleteUserReference) {
                        if (newWordReference.references.length === 0) {
                            dispatch(deleteWholeWordReference({ wordIndex }));
                        } else {
                            dispatch(
                                deleteWordReference({
                                    wordIndex,
                                    newWordReference
                                })
                            );
                        }

                        if (reference.isPublic) {
                            const stateWord = stateWords.find(
                                (item: any) =>
                                    item.word ===
                                    wordsReferences[wordIndex].word
                            );

                            dispatch(
                                thunkDeleteCommunityReference(
                                    stateWord,
                                    reference
                                )
                            );
                        }

                        toast.success('Rewwind deleted');
                    }
                } catch (err) {
                    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                    toast.error(err);
                }
            }
        }
    };

// SELECTORS
const selectWords = (state: any): Word[] => state.words.words;
export const selectActiveWord = (state: any): Word => state.words.activeWord;
export const selectWordsReferences = (
    state: ReferencesSlice
): WordReference[] => state.references.wordsReferences;
export const selectAllWordsReferences = createSelector(
    [selectWordsReferences, selectWords],
    (wordsReferences, words) => {
        return wordsReferences.filter((item: any) => {
            const wordInWords = words.find(
                (word: any) => word.word === item.word
            );

            return wordInWords !== undefined && wordInWords.archived !== true;
        });
    }
);
export const selectWordReferences = createSelector(
    [selectWordsReferences, selectActiveWord],
    (wordsReferences, activeWord) => {
        const references = wordsReferences.filter(
            (wordReferences: any) => wordReferences.word === activeWord.word
        );

        if (references.length) {
            return references[0].references;
        }

        return [];
    }
);

export default referencesSlice.reducer;
