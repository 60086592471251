import React, { useRef, useEffect } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import classNames from 'classnames';

import Button from '../Button';
import Dropdown from '../Dropdown';
import SmallLoader from '../SmallLoader';

import { DropdownOption } from '../Dropdown/Dropdown';

import './MoreOptions.scss';

type Props = {
    onClick: (e: React.MouseEvent) => void;
    isLoading?: boolean;
    showDropdown: boolean;
    options: DropdownOption[];
    selfAlign?: boolean;
    buttonSize?: 'small' | 'large';
    disabled?: boolean;
    onClose?: () => void;
};

const MoreOptions = ({
    onClick,
    onClose,
    isLoading,
    showDropdown,
    options,
    selfAlign,
    disabled,
    buttonSize
}: Props) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const optionsContainerClass = classNames('moreOptionsContainer', {
        moreOptionsContainer__interior: selfAlign
    });

    // TODO: Fix any type
    const handleClickOutside = (e: any) => {
        if (buttonRef.current && buttonRef.current.contains(e.target)) {
            // do nothing and let onClick handler take care of it
            return;
        } else {
            if (
                dropdownRef.current &&
                dropdownRef?.current.contains(e.target)
            ) {
                // inside click
                return;
            }

            // outside click
            if (onClose) {
                onClose();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={optionsContainerClass}>
            <Button
                buttonCategory='icon'
                onClick={onClick}
                buttonDisabled={isLoading || disabled}
                buttonSize={buttonSize}
                ref={buttonRef}
            >
                {isLoading ? (
                    <SmallLoader />
                ) : (
                    <FiMoreHorizontal className='icon buttonIcon' />
                )}
            </Button>
            {showDropdown && (
                <Dropdown
                    options={options}
                    buttonSize={buttonSize}
                    ref={dropdownRef}
                    onClose={onClose}
                />
            )}
        </div>
    );
};

export default MoreOptions;
