import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { isEqual } from 'lodash';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import the plugin styles
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';

import Button from 'shared/components/Button';
import NotVerified from 'shared/components/NotVerified';

import { thunkUpdateCollection } from 'redux/slices/collections';
import { setShowSideBar } from 'redux/slices/app';
import { selectUserInfo, selectUserVerified } from 'redux/slices/users';
import { apiUpdateCollectionImage } from 'api/collections';

import { Collection } from 'types/collection';

import './EditCollection.scss';

registerPlugin(
    FilePondPluginImagePreview,
    FilePondPluginFilePoster,
    FilePondPluginFileValidateType
);

// TODO: Add File types
type Props = {
    collection: Collection;
};

type FormValues = {
    name: string;
    description: string;
};

const EditCollection = ({ collection }: Props) => {
    const dispatch = useDispatch();
    const userInfo = useSelector(selectUserInfo);
    const userVerified = useSelector(selectUserVerified);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [newImageFile, setNewImageFile] = useState([]);
    const [currentImageFile, setCurrentImageFile] = useState([]);
    const [imageButtonLoading, setImageButtonLoading] =
        useState<boolean>(false);
    const [imageButtonDisabled, setImageButtonDisabled] =
        useState<boolean>(true);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<FormValues>({ mode: 'onChange' });
    const [formName, setFormName] = useState(watch('name', collection.name));
    const [formDescription, setFormDescription] = useState(
        watch('description', collection.description)
    );
    const watchedName = formName;
    const watchedDescription = formDescription;
    const buttonDisabled = () => {
        const haveValues = [formName, formDescription].every(
            (value) => value && value !== ''
        );
        const sameContent =
            isEqual(collection.name, watchedName) &&
            isEqual(collection.description, watchedDescription);
        return (
            Object.keys(errors).length > 0 ||
            !haveValues ||
            buttonLoading ||
            sameContent
        );
    };
    const onSubmit = async () => {
        setButtonLoading(true);

        const updatedCollection = {
            ...collection,
            name: watchedName,
            description: watchedDescription,
            dateAdded: new Date().toISOString()
        };

        if (
            !isEqual(collection.name, updatedCollection.name) ||
            !isEqual(collection.description, updatedCollection.description)
        ) {
            await dispatch(thunkUpdateCollection(updatedCollection));
            dispatch(setShowSideBar({ show: false, type: null }));
        }

        setButtonLoading(false);
    };
    const onImageSubmit = () => {
        const submitImage = async () => {
            setImageButtonLoading(true);
            setImageButtonDisabled(true);

            const currentFileName = collection.collectionImage
                ? (currentImageFile[0] as any).file.name
                : '';

            try {
                if (userInfo?.userID) {
                    const imageURL = await apiUpdateCollectionImage(
                        userInfo?.userID,
                        (newImageFile[0] as any).file,
                        collection.collectionID,
                        currentFileName
                    );

                    if (typeof imageURL === 'string') {
                        const updatedCollection = {
                            ...collection,
                            name: watchedName,
                            description: watchedDescription,
                            dateAdded: new Date().toISOString(),
                            collectionImage: {
                                name: (newImageFile[0] as any).file.name,
                                size: (newImageFile[0] as any).file.size,
                                type: (newImageFile[0] as any).file.type,
                                imageURL
                            }
                        };

                        await dispatch(
                            thunkUpdateCollection(updatedCollection)
                        );

                        dispatch(setShowSideBar({ show: false, type: null }));
                    }
                }
            } catch (err) {
                // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                throw new Error(err);
            } finally {
                setImageButtonLoading(false);
                setImageButtonDisabled(false);
            }
        };

        if (currentImageFile.length > 0) {
            if (
                !isEqual(
                    (currentImageFile[0] as any).file.name,
                    (newImageFile[0] as any).file.name
                )
            ) {
                submitImage();
            }
        } else {
            submitImage();
        }
    };
    const handleUpdateFiles = (files: any) => {
        if (files.length > 0) {
            setNewImageFile(files);

            if (collection.collectionImage) {
                if (files[0].file.name !== collection.collectionImage.name) {
                    setImageButtonDisabled(false);
                }
            } else {
                setImageButtonDisabled(false);
            }
        } else {
            setNewImageFile(currentImageFile);
            setImageButtonDisabled(true);
        }
    };

    useEffect(() => {
        setFormName(collection.name);
        setFormDescription(collection.description);
        setImageButtonDisabled(true);

        if (collection.collectionImage) {
            const files = [
                {
                    file: {
                        name: collection.collectionImage.name,
                        size: collection.collectionImage.size,
                        type: collection.collectionImage.type
                    },
                    source: collection.collectionImage.imageURL,
                    options: {
                        type: 'local',
                        file: {
                            name: collection.collectionImage.name,
                            size: collection.collectionImage.size,
                            type: collection.collectionImage.type
                        },
                        metadata: {
                            poster: collection.collectionImage.imageURL
                        }
                    }
                }
            ];
            // @ts-expect-error TS(2345): Argument of type '{ file: { name: any; size: any; ... Remove this comment to see the full error message
            setCurrentImageFile(files);
            // @ts-expect-error TS(2345): Argument of type '{ file: { name: any; size: any; ... Remove this comment to see the full error message
            setNewImageFile(files);
        } else {
            setCurrentImageFile([]);
            setNewImageFile([]);
        }
    }, [collection]);

    if (!userVerified)
        return <NotVerified userInfo={userInfo} isLinkOnly={false} />;

    return (
        <div className='editCollectionContainer'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='editCollectionSection'>
                    <input
                        {...register('name', {
                            required: {
                                value: true,
                                message: 'A name is required'
                            }
                        })}
                        placeholder='Collection name'
                        type='text'
                        value={formName}
                        onChange={(e) => setFormName(e.target.value)}
                        autoComplete='off'
                    ></input>
                    <span className='notice'>required</span>
                </div>
                {errors.name && (
                    <div className='errorMessage'>{errors.name.message}</div>
                )}
                <div className='newCollectionSection'>
                    <textarea
                        {...register('description', {
                            required: {
                                value: true,
                                message: 'Description is required'
                            },
                            maxLength: {
                                value: 140,
                                message: 'Cannot be more than 140 characters'
                            }
                        })}
                        placeholder='Collection description'
                        rows={7}
                        value={formDescription}
                        onChange={(e) => setFormDescription(e.target.value)}
                        autoComplete='off'
                    ></textarea>
                    <span className='notice'>required</span>
                </div>
                {errors.description && (
                    <div className='errorMessage'>
                        {errors.description.message}
                    </div>
                )}
                <Button
                    buttonType='submit'
                    buttonDisabled={buttonDisabled()}
                    isLoading={buttonLoading}
                >
                    Save Details
                </Button>
            </form>
            <div className='imageContainer'>
                <FilePond
                    allowMultiple={false}
                    allowImagePreview={true}
                    allowFilePoster={true}
                    allowFileTypeValidation={true}
                    acceptedFileTypes={[
                        'image/png',
                        'image/jpeg',
                        'image/gif',
                        'image/jpg'
                    ]}
                    labelIdle="Drag & Drop collection image or <span class='filepond--label-action'>Browse</span>"
                    files={newImageFile}
                    onupdatefiles={handleUpdateFiles}
                />
                <span className='notice'>required</span>
                <Button
                    buttonType='submit'
                    buttonDisabled={imageButtonDisabled}
                    isLoading={imageButtonLoading}
                    onClick={onImageSubmit}
                >
                    Save Image
                </Button>
            </div>
        </div>
    );
};

export default EditCollection;
