import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getAnalytics } from 'firebase/analytics';

import { app } from '.';

import { User } from 'types/user';

export function initSentry(user: User | null) {
    if (process.env.NODE_ENV === 'production') {
        // Firebase google analytics
        getAnalytics(app);

        // Sentry error tracking for prod
        Sentry.init({
            dsn: `https://${process.env.REACT_APP_SENTRY_API_KEY}@o203272.ingest.sentry.io/5757393`,
            integrations: [new Integrations.BrowserTracing()],
            replaysSessionSampleRate: 0.5,
            replaysOnErrorSampleRate: 1.0,
            environment: 'production',
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0
        });

        Sentry.setUser({ id: user?.userID, email: user?.email });
    } else {
        // Sentry error tracking for dev
        Sentry.init({
            dsn: `https://${process.env.REACT_APP_SENTRY_API_KEY}@o203272.ingest.sentry.io/5757393`,
            integrations: [
                new Integrations.BrowserTracing(),
                new Sentry.Integrations.Breadcrumbs({
                    console: false
                })
            ],
            replaysSessionSampleRate: 1.0,
            replaysOnErrorSampleRate: 1.0,
            environment: 'development',
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0
        });

        Sentry.setUser({ id: user?.userID, email: user?.email });
    }
}
