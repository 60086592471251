import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiCheck } from 'react-icons/fi';
import { isEqual } from 'lodash';

import { thunkUpdateSettings, selectUserSettings } from 'redux/slices/users';

import SectionHeader from 'shared/components/SectionHeader';
import Picker from 'shared/components/Picker';
import Button from 'shared/components/Button';

import { UserSettings } from 'types/user';
import { NoteDefaultViewType, DefaultViewTypeOptions } from 'types/settings';

import styles from './NotesSettings.module.scss';

const DEFAULT_VIEW = ['Pinned', 'All'];

function NotesSettings() {
    const dispatch = useDispatch();
    const { noteDefaultView } = useSelector(selectUserSettings);
    const [newSettings, setNewSettings] = useState({
        noteDefaultView
    });
    const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

    // Default view
    const defaultViewPickerItems = DEFAULT_VIEW.map((item) => ({
        view: item.toLowerCase() as DefaultViewTypeOptions,
        display: item
    }));
    const handleDefaultViewChange = (value: NoteDefaultViewType) => {
        setNewSettings((state) => ({
            ...state,
            noteDefaultView: { view: value.view }
        }));
    };

    // Save
    const isSaveDisabled = () => {
        return isEqual({ noteDefaultView }, newSettings) || isSaveLoading;
    };
    const handleSave = async () => {
        setIsSaveLoading(true);
        await dispatch(thunkUpdateSettings(newSettings as UserSettings, true));
        setIsSaveLoading(false);
    };

    useEffect(() => {
        setNewSettings({ noteDefaultView });
    }, [noteDefaultView]);

    return (
        <div className={styles.settingsContainer}>
            <SectionHeader
                componentTag='h3'
                title='Notes'
                renderRight={() => (
                    <Button
                        buttonDisabled={isSaveDisabled()}
                        buttonCategory='icon'
                        buttonSize='small'
                        isLoading={isSaveLoading}
                        onClick={handleSave}
                    >
                        <FiCheck className='icon buttonIcon' />
                    </Button>
                )}
            />
            <div
                className={styles.settingsItem}
                style={{ borderTop: '1px solid #1a2133', marginTop: '20px' }}
            >
                <div className={styles.settingsControls}>
                    <h4>Default view</h4>
                    <Picker
                        pickerItems={defaultViewPickerItems}
                        selectedItem={newSettings.noteDefaultView}
                        idProperty='view'
                        onChange={handleDefaultViewChange}
                    />
                </div>
                <span className='notice notice__left'>
                    This will determine if all notes or only pinned notes are
                    displayed when you navigate to &apos;Notes&apos;.
                </span>
            </div>
            {/* <div className={styles.settingsItem}>
                <div className={styles.settingsControls}>
                    <h4>Note password</h4>
                    <input type='password' value='*******' />
                </div>
                <span className='notice notice__left'>
                    This will determine the password for all your locked notes
                </span>
            </div> */}
        </div>
    );
}

export default NotesSettings;
