import React from 'react';
import classNames from 'classnames';

import './Logo.scss';

type Props = {
    shortForm?: boolean;
    pulse?: boolean;
    size?: string;
};

const Logo = ({ shortForm, pulse, size }: Props) => {
    const logoClass = classNames('logo', {
        logo__pulse: pulse,
        logo__small: size === 'small',
        logo__shortForm: shortForm
    });

    return (
        <>
            {shortForm ? (
                <img
                    className={logoClass}
                    src={`${process.env.PUBLIC_URL}/rewwind_logos/transparent_and_jpg/JPG-05.jpg`}
                    alt='Rewwind logo'
                />
            ) : (
                <img
                    className={logoClass}
                    src={`${process.env.PUBLIC_URL}/rewwind_logos/transparent_and_jpg/Transparent PNG-02.png`}
                    alt='Rewwind logo'
                />
            )}
        </>
    );
};

export default Logo;
