import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid';
import { FiPlus, FiX, FiCheck } from 'react-icons/fi';

import { selectUserSettings } from 'redux/slices/users';
import { thunkAddWordReference } from 'redux/slices/references';

import Button from 'shared/components/Button';
import WordPermissionsToggle from 'views/Words/WordPermissionsToggle';

import { Word } from 'types/word';
import { Reference } from 'types/reference';

import './AddWordReference.scss';

type Props = {
    word: Word;
    references: Reference[];
};

const AddWordReference = ({ word, references }: Props) => {
    const dispatch = useDispatch();
    const { wordPermissions } = useSelector(selectUserSettings);
    const [referenceText, setReferenceText] = useState('');
    const [showReferenceInput, setShowReferenceInput] =
        useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [isPublic, setIsPublic] = useState(
        wordPermissions?.id === 'public' ? true : false
    );

    const handleAddClick = async () => {
        setButtonLoading(true);
        const newReference = {
            word: word.word,
            references: [
                {
                    dateAdded: new Date().toISOString(),
                    referenceID: uniqid(),
                    reference: referenceText,
                    isPublic: isPublic
                }
            ]
        };

        await dispatch(thunkAddWordReference(newReference));
        setButtonLoading(false);
        setReferenceText('');
        setShowReferenceInput(false);
        setIsPublic(wordPermissions?.id === 'public' ? true : false);
    };

    const handleCancel = () => {
        setShowReferenceInput(false);
        setReferenceText('');
    };

    const handleWordPermissionsToggle = (permission: boolean) => {
        setIsPublic(permission);
    };

    useEffect(() => {
        setShowReferenceInput(false);
        setReferenceText('');
    }, [word]);

    return (
        <div className='addReferenceContainer'>
            {showReferenceInput ? (
                <div>
                    <textarea
                        placeholder={
                            references.length >= 1
                                ? 'How have you re-discovered this word?'
                                : 'How did you discover this word?'
                        }
                        rows={5}
                        onChange={(e) => setReferenceText(e.target.value)}
                        value={referenceText}
                    />
                    <div className='addReferenceControls'>
                        <WordPermissionsToggle
                            onToggle={handleWordPermissionsToggle}
                            wordPermissions={wordPermissions}
                            // isDisabled={referenceText.trim().length <= 0}
                            isDisabled
                        />
                        <div className='addReferenceControlsButtons'>
                            <Button
                                onClick={handleAddClick}
                                isLoading={buttonLoading}
                                buttonDisabled={referenceText.trim() === ''}
                                buttonCategory='icon'
                                buttonSize='small'
                            >
                                <FiCheck className='icon buttonIcon' />
                            </Button>
                            <Button
                                onClick={handleCancel}
                                buttonCategory='iconAlternate'
                                buttonSize='small'
                            >
                                <FiX className='icon buttonIcon' />
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <Button
                    buttonCategory='icon'
                    onClick={() => setShowReferenceInput(true)}
                >
                    <FiPlus className='icon buttonIcon' />
                </Button>
            )}
        </div>
    );
};

export default AddWordReference;
