import React, { useState } from 'react';
import { BsSortAlphaDownAlt, BsSortAlphaUp } from 'react-icons/bs';
import { MdUpdate } from 'react-icons/md';
import { VscArchive } from 'react-icons/vsc';
import classNames from 'classnames';

import { Word } from 'types/word';

type Props = {
    words: Word[];
    onSort?: (value: WordSortType) => void;
    onShowArchivedNotes?: (value: boolean) => void;
};

export type WordSortType =
    | 'alphaAsc'
    | 'alphaDesc'
    | 'dateAsc'
    | 'dateDesc'
    | 'archived'
    | 'non-archived';

const WordSort = ({ words, onShowArchivedNotes, onSort }: Props) => {
    const [sortActive, setSortActive] = useState<boolean>(true);
    const [sortType, setSortType] = useState<WordSortType>('dateAsc');
    const [nextArchivedSortType, setNextArchivedSortType] = useState<
        'archived' | 'non-archived'
    >('archived');
    const [currentSortType, setCurrentSortType] = useState('non-archived');
    const sortItemActiveClass = classNames('sortItem', {
        sortItem__active: sortActive
    });
    const sortTypeClass = classNames('sortType', {
        sortType__active: sortActive
    });
    const handleFilter = (type: WordSortType) => {
        setSortActive(true);

        switch (type) {
            case 'archived':
                setCurrentSortType('archived');
                setNextArchivedSortType('non-archived');
                onShowArchivedNotes && onShowArchivedNotes(true);
                break;
            case 'non-archived':
                setCurrentSortType('non-archived');
                setNextArchivedSortType('archived');
                onShowArchivedNotes && onShowArchivedNotes(false);
                break;
            default:
                break;
        }
    };
    const handleGenericSort = (type: WordSortType) => {
        onSort && onSort(type);
        setSortType(type);
    };
    const archivedWordsCount = words.filter((word) => word.archived).length;

    return (
        <div className='sortContainer'>
            <div
                className={
                    sortType === 'dateAsc' || sortType === 'dateDesc'
                        ? sortTypeClass
                        : 'sortType'
                }
            >
                <div
                    className={
                        sortType === 'dateAsc'
                            ? sortItemActiveClass
                            : 'sortItem'
                    }
                    onClick={() => handleGenericSort('dateAsc')}
                >
                    <MdUpdate />
                </div>
                <div
                    className={
                        sortType === 'dateDesc'
                            ? sortItemActiveClass
                            : 'sortItem'
                    }
                    onClick={() => handleGenericSort('dateDesc')}
                >
                    <MdUpdate className='icon invertedIcon' />
                </div>
            </div>
            <div
                className={
                    sortType === 'alphaAsc' || sortType === 'alphaDesc'
                        ? sortTypeClass
                        : 'sortType'
                }
            >
                <div
                    className={
                        sortType === 'alphaAsc'
                            ? sortItemActiveClass
                            : 'sortItem'
                    }
                    onClick={() => handleGenericSort('alphaAsc')}
                >
                    <BsSortAlphaUp />
                </div>
                <div
                    className={
                        sortType === 'alphaDesc'
                            ? sortItemActiveClass
                            : 'sortItem'
                    }
                    onClick={() => handleGenericSort('alphaDesc')}
                >
                    <BsSortAlphaDownAlt />
                </div>
            </div>
            {archivedWordsCount > 0 && (
                <div
                    className={
                        currentSortType === 'archived'
                            ? sortTypeClass
                            : 'sortType'
                    }
                >
                    <div
                        className={
                            currentSortType === 'archived'
                                ? sortItemActiveClass
                                : 'sortItem'
                        }
                    >
                        <VscArchive
                            onClick={() => handleFilter(nextArchivedSortType)}
                        />
                    </div>
                    {currentSortType === 'archived' && (
                        <span className='archivedCount'>
                            {archivedWordsCount}
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export default WordSort;
