import 'firebase/auth';
import 'firebase/firestore';
import { toast } from 'react-toastify';
import {
    ref,
    uploadBytesResumable,
    uploadBytes,
    getDownloadURL,
    deleteObject,
    UploadTaskSnapshot,
    UploadResult
} from 'firebase/storage';
import { gql } from '@apollo/client';

import { storage } from 'index';

/**
 * GET USER
 * @param {*} userID
 */
export const QUERY_GET_USER = gql`
    query getUserQuery($userID: ID!) {
        firestoreGetUser(userID: $userID) {
            userID
            firstName
            lastName
            email
            bio
            username
            photoURL
            emailVerified
            dateCreated
            profileImage {
                imageURL
                name
                size
                type
            }
            settings {
                defaultPage {
                    page
                }
                defaultVoice {
                    id
                    name
                }
                noteDisplayType {
                    type
                }
                wordPermissions {
                    id
                }
                noteDefaultView {
                    view
                }
                language {
                    code
                    language
                }
                speechSpeed {
                    speed
                }
            }
        }
    }
`;

export const MUTATION_UPDATE_USER_INFO = gql`
    mutation updateUserInfoQuery($userID: ID!, $userInfo: UserInfoInput!) {
        firestoreUpdateUserInfo(userID: $userID, userInfo: $userInfo)
    }
`;

export const MUTATION_UPDATE_USER_SETTINGS = gql`
    mutation updateUserSettingsQuery(
        $userID: ID!
        $userSettings: UserSettingsInput!
    ) {
        updateUserSettings(userID: $userID, userSettings: $userSettings)
    }
`;

export async function apiUpdateUserProfileImage(
    userID: string,
    newImageFile: any,
    oldImageFileName = ''
) {
    return new Promise((resolve, reject) => {
        const storageRef = ref(storage);
        const userRef = ref(storageRef, userID);
        const userAccountProfileImageRef = ref(
            userRef,
            `account/profileImage/${newImageFile.name}`
        );

        // console.log('Accounts ref:', userAccountProfileImageRef);

        try {
            // uploadBytes(userAccountProfileImageRef, newImageFile)
            //     .then((snapshot: UploadResult) => {
            //         console.log('Uploaded a blob or file!');
            //         getDownloadURL(snapshot.ref)
            //             .then((downloadURL) => {
            //                 // resolve(downloadURL);
            //                 console.log(downloadURL);
            //                 return downloadURL;
            //             })
            //             .then((url) => {
            //                 if (oldImageFileName !== '') {
            //                     const oldAccountProfileImageRef = ref(
            //                         userRef,
            //                         `account/profileImage/${oldImageFileName}`
            //                     );

            //                     deleteObject(oldAccountProfileImageRef)
            //                         .then(() => {
            //                             resolve(url);
            //                         })
            //                         .catch((err) => {
            //                             reject(err);
            //                         });
            //                 } else {
            //                     resolve(url);
            //                 }
            //             })
            //             .catch((err) => {
            //                 console.log(err);
            //                 toast.error('Error uploading image');
            //                 reject(err);
            //             });
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //         toast.error('Error uploading image');
            //         reject(err);
            //     });

            const uploadTask = uploadBytesResumable(
                userAccountProfileImageRef,
                newImageFile
            );

            uploadTask.on(
                'state_changed',
                (snapshot: UploadTaskSnapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            // console.log('Upload is paused');
                            break;
                        case 'running':
                            // console.log('Upload is running');
                            break;
                    }
                },
                (err) => {
                    reject(err);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref)
                        .then((downloadURL) => {
                            // resolve(downloadURL);
                            // console.log(downloadURL);
                            return downloadURL;
                        })
                        .then((url) => {
                            if (oldImageFileName !== '') {
                                const oldAccountProfileImageRef = ref(
                                    userRef,
                                    `account/profileImage/${oldImageFileName}`
                                );

                                deleteObject(oldAccountProfileImageRef)
                                    .then(() => {
                                        resolve(url);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            } else {
                                resolve(url);
                            }
                        })
                        .catch((err) => {
                            // console.log(err);
                            toast.error('Error uploading image');
                            reject(err);
                        });
                }
            );
        } catch (err) {
            reject(err);
        }
    });
}

export async function apiDeleteUserProfileImage(
    userID: string,
    oldImageFileName = ''
) {
    return new Promise((resolve, reject) => {
        const storageRef = ref(storage);
        const userRef = ref(storageRef, userID);
        const userAccountRef = ref(userRef, 'account');

        const oldAccountProfileImageRef =
            oldImageFileName !== ''
                ? ref(userAccountRef, `profileImage/${oldImageFileName}`)
                : ref(userAccountRef, 'profileImage/');

        try {
            deleteObject(oldAccountProfileImageRef)
                .then(() => {
                    resolve(true);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (err) {
            reject(err);
        }
    });
}
