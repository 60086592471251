/* eslint-disable no-useless-escape */
/* eslint-disable no-useless-catch */
import { setUserInfo, thunkUpdateSettings } from 'redux/slices/users';
import { QUERY_GET_USER_CUSTOM_TOKEN } from 'api/auth';
// import { authorizeChromeExtension } from 'extensions/chrome/chromeExtension';

import { client } from 'index';

import * as ROUTES from 'constants/routes';

import { User } from 'types/user';

export function handleSuccessfulAuth(
    userInfo: User,
    history: any,
    dispatch: React.Dispatch<any>
) {
    dispatch(setUserInfo(userInfo));

    if (userInfo.settings !== null) {
        dispatch(thunkUpdateSettings(userInfo.settings));
    }

    if (userInfo.settings !== null && userInfo.settings.defaultPage !== null) {
        history.push(userInfo.settings.defaultPage.page);
    } else {
        history.push(ROUTES.WORDS);
    }
}

export async function handleCustomTokenGeneration(userID: string) {
    try {
        const { data, error } = await client.query<{
            firebaseGetCustomToken: string;
        }>({ query: QUERY_GET_USER_CUSTOM_TOKEN, variables: { userID } });

        if (error) {
            throw 'Error getting custom token';
        }

        if (data && data.firebaseGetCustomToken) {
            const customToken = data.firebaseGetCustomToken;

            // authorizeChromeExtension(customToken);
        }
    } catch (err) {
        throw err;
    }
}

export const isValidEmail = (email: string) =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );
