import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import classNames from 'classnames';

import { detectLink } from 'helpers/helpers';
import { thunkUpdateUserWordVariation } from 'redux/slices/words';
import { thunkDeleteUserWordReference } from 'redux/slices/references';

import EditWordReference from 'views/Words/EditWordReference';
import DateTime from 'shared/components/DateTime';
import MoreOptions from 'shared/components/MoreOptions';
import WordVariationIdentifier from 'views/Words/WordVariationIdentifier';

import { DropdownOption } from 'shared/components/Dropdown/Dropdown';
import { Reference } from 'types/reference';
import { Word } from 'types/word';

type Props = {
    reference: Reference;
    word: Word;
};

const WordReferencesItem = ({ reference, word }: Props) => {
    const dispatch = useDispatch();
    const [showReferenceOptions, setShowReferenceOptions] =
        useState<boolean>(false);
    const [showEditReference, setShowEditReference] = useState<boolean>(false);
    const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(false);
    const [showOptionsButton, setShowOptionsButton] = useState<boolean>(false);
    const referenceDetailsClass = classNames('referenceDetails', {
        referenceDetails__blurred: showReferenceOptions
    });
    const handleClose = () => {
        setShowEditReference(false);
        setShowReferenceOptions(false);
    };
    const handleShowMoreClick = () => {
        setShowReferenceOptions(!showReferenceOptions);
    };
    const handleDelete = async () => {
        setIsOptionsLoading(true);

        // TODO: Do you still need this check
        // if ('variationID' in reference) {
        //     const newVariation = {
        //         ...reference,
        //         reference: ''
        //     };

        //     await dispatch(thunkUpdateUserWordVariation(newVariation));
        // } else if ('referenceID' in reference) {
        await dispatch(thunkDeleteUserWordReference(reference, word));
        // }
        setIsOptionsLoading(false);
    };
    const handleEdit = () => {
        setShowEditReference(!showEditReference);
        setShowOptionsButton(false);
    };
    const handleMouseEnter = () => {
        if (!showEditReference) {
            setShowOptionsButton(true);
        }
    };
    const handleMouseLeave = () => {
        setShowOptionsButton(false);
        setShowReferenceOptions(false);
    };
    const options: DropdownOption[] = [
        {
            name: 'Edit',
            icon: <FiEdit className='icon actionIcon__small' />,
            onClick: handleEdit
        },
        {
            name: 'Delete',
            icon: <FiTrash2 className='icon actionIcon__small dangerIcon' />,
            onClick: handleDelete
        }
    ];

    return (
        <li
            className='wordReferencesListItem'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className='reference'>
                {showEditReference ? (
                    <EditWordReference
                        reference={reference}
                        onClose={handleClose}
                    />
                ) : (
                    <p className={referenceDetailsClass}>
                        <DateTime type='timeOnly' date={reference.dateAdded} />
                        &nbsp;
                        <WordVariationIdentifier reference={reference} />
                        {detectLink(reference.reference)}
                    </p>
                )}
            </div>
            {showOptionsButton && (
                <MoreOptions
                    options={options}
                    onClick={handleShowMoreClick}
                    isLoading={isOptionsLoading}
                    showDropdown={showReferenceOptions}
                    buttonSize='small'
                />
            )}
        </li>
    );
};

export default WordReferencesItem;
