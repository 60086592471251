import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FiTrash2,
    FiEdit,
    FiCheck,
    FiX,
    FiCornerUpLeft,
    FiLock,
    FiMaximize,
    FiMinimize,
    FiUnlock,
    FiXCircle
} from 'react-icons/fi';
import { VscArchive } from 'react-icons/vsc';
import ReactQuill from 'react-quill';

import { RiPushpin2Line } from 'react-icons/ri';
import { IoMdRemoveCircleOutline } from 'react-icons/io';
import classNames from 'classnames';

import {
    thunkDeleteNote,
    setEditActive,
    thunkUpdateUserNote,
    selectEditActive
} from 'redux/slices/notes';
import { thunkShowSideBar } from 'redux/slices/app';

import Button from 'shared/components/Button';
import SmallLoader from 'shared/components/SmallLoader';
import MoreOptions from 'shared/components/MoreOptions';
import DateTime from 'shared/components/DateTime';
import Modal from 'shared/components/Modal';

import { DropdownOption } from 'shared/components/Dropdown/Dropdown';
import { Note } from 'types/note';

import './NotesListItem.scss';
import 'react-quill/dist/quill.bubble.css';

type LockNoteProps = {
    isPasswordMode?: boolean;
};

const LockNote = ({ isPasswordMode }: LockNoteProps) => {
    return (
        <div className='lockContainer'>
            {isPasswordMode ? (
                <div>Something</div>
            ) : (
                <span className='pill'>
                    {/* <FiLock />s */}
                    Locked note
                </span>
            )}
        </div>
    );
};

type NotesListItemProps = {
    note: Note;
    noteDisplayType?: string;
};

const NotesListItem = ({ note, noteDisplayType }: NotesListItemProps) => {
    const dispatch = useDispatch();
    const editActive = useSelector(selectEditActive);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(false);
    const [isPasswordMode, setIsPasswordMode] = useState<boolean>(false);
    const [isMaximized, setIsMaximized] = useState<boolean>(false);
    const [showNoteContent, setShowNoteContent] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    // setIsPasswordMode(false);
    // const [temporaryUnlock, setTemporaryUnlock] = useState<boolean>(false);
    const noteItemClass = classNames('noteItem', {
        noteItem__optionsOpen: showOptions,
        noteItem__grid: noteDisplayType === 'grid'
    });

    const noteItemContainerClass = classNames('noteItemContainer', {
        noteItemContainer__maximized: isMaximized
    });

    const noteItemContentClass = classNames('noteItemContent', {
        noteItemContent__grid:
            noteDisplayType === 'grid' && showNoteContent === false
    });

    const handleNoteHeaderClick = () => {
        if (noteDisplayType === 'grid') {
            setShowNoteContent(!showNoteContent);
        }
    };
    const handleOptionsClose = () => {
        setShowOptions(false);
    };

    const handleShowMoreClick = () => {
        setShowOptions(!showOptions);
    };

    const handleMaximizeClick = () => {
        setIsMaximized(!isMaximized);
        setShowOptions(false);
    };

    // const handleLockClick = () => {
    //   setIsPasswordMode(true);
    // };

    const handleArchive = async () => {
        setIsOptionsLoading(true);
        await dispatch(
            thunkUpdateUserNote(note.noteID, { archived: true } as Note)
        );
        setIsOptionsLoading(false);
    };

    const handleUnarchive = async () => {
        setIsOptionsLoading(true);
        await dispatch(
            thunkUpdateUserNote(note.noteID, { archived: false } as Note)
        );
        setIsOptionsLoading(false);
    };

    const handleLock = async () => {
        setIsOptionsLoading(true);
        await dispatch(
            thunkUpdateUserNote(note.noteID, { locked: true } as Note)
        );
        setIsOptionsLoading(false);
    };

    const handleUnlock = async () => {
        setIsOptionsLoading(true);
        await dispatch(
            thunkUpdateUserNote(note.noteID, { locked: false } as Note)
        );
        setIsOptionsLoading(false);
    };

    const handleDeleteClick = async () => {
        setIsOptionsLoading(true);
        await dispatch(thunkDeleteNote(note.noteID));
        setIsOptionsLoading(false);
    };

    const handleEdit = () => {
        setIsMaximized(false);
        setShowOptions(false);
        dispatch(setEditActive({ active: true, note: note }));
        dispatch(thunkShowSideBar(null, 'editNote'));
    };
    const handlePin = async () => {
        setIsOptionsLoading(true);
        await dispatch(
            thunkUpdateUserNote(note.noteID, { pinned: true } as Note)
        );
        setIsOptionsLoading(false);
    };
    const handleUnpin = async () => {
        setIsOptionsLoading(true);
        await dispatch(
            thunkUpdateUserNote(note.noteID, { pinned: false } as Note)
        );
        setIsOptionsLoading(false);
    };
    const handleModalClose = () => {
        setIsOptionsLoading(false);
        setIsModalOpen(false);
    };
    const options: DropdownOption[] = [
        {
            name: note.pinned ? 'Unpin' : 'Pin',
            icon: note.pinned ? (
                <IoMdRemoveCircleOutline className='icon' />
            ) : (
                <RiPushpin2Line className='icon' />
            ),
            onClick: note.pinned ? handleUnpin : handlePin
        },
        {
            name: isMaximized ? 'Minimize' : 'Maximize',
            icon: isMaximized ? (
                <FiMinimize className='icon' />
            ) : (
                <FiMaximize className='icon' />
            ),
            onClick: handleMaximizeClick,
            disabled: note.locked ? true : false
        },
        {
            name: 'Edit',
            icon: <FiEdit className='icon' />,
            onClick: handleEdit,
            disabled: note.locked ? true : false
        },
        {
            name: note.locked ? 'Unlock' : 'Lock',
            icon: note.locked ? (
                <FiUnlock className='icon' />
            ) : (
                <FiLock className='icon' />
            ),
            onClick: note.locked ? handleUnlock : handleLock,
            disabled: isMaximized ? true : false
        },
        {
            name: note.archived ? 'Unarchive' : 'Archive',
            icon: note.archived ? (
                <FiCornerUpLeft className='icon' />
            ) : (
                <VscArchive className='icon' />
            ),
            onClick: note.archived ? handleUnarchive : handleArchive
        },
        {
            name: 'Delete',
            icon: <FiTrash2 className='icon dangerIcon' />,
            onClick: () => {
                setIsMaximized(false);
                setIsModalOpen(true);
                setShowOptions(false);
            },
            isDanger: true
        }
    ];

    const formats = useMemo(
        () => [
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link'
        ],
        []
    );

    useEffect(() => {
        setIsPasswordMode(false);
    }, []);

    return (
        <>
            <div className={noteItemContainerClass}>
                <div className={noteItemClass}>
                    <div
                        className='noteItemHeader'
                        onClick={handleNoteHeaderClick}
                    >
                        <div className='noteItemTime'>
                            <h3>{note.title}</h3>
                            <div>
                                {note.archived && (
                                    <span>
                                        <VscArchive className='icon identifierIcon' />
                                    </span>
                                )}
                                {note.pinned && (
                                    <span>
                                        <RiPushpin2Line className='icon identifierIcon' />
                                    </span>
                                )}
                            </div>
                            <div>
                                <DateTime
                                    type='timeAgo'
                                    date={note.dateAdded}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={noteItemContentClass}>
                        {note.locked ? (
                            <LockNote isPasswordMode={isPasswordMode} />
                        ) : (
                            <ReactQuill
                                value={note.content}
                                theme='bubble'
                                style={{
                                    color: '#f5f5f5'
                                }}
                                formats={formats}
                                readOnly
                            />
                        )}
                    </div>
                </div>
                {!isMaximized ? (
                    <MoreOptions
                        onClick={handleShowMoreClick}
                        isLoading={isOptionsLoading}
                        showDropdown={showOptions}
                        options={options}
                        selfAlign={true}
                        onClose={handleOptionsClose}
                        buttonSize='small'
                    />
                ) : (
                    <div className='noteClose'>
                        <FiXCircle
                            className='icon actionIcon'
                            onClick={handleMaximizeClick}
                        />
                    </div>
                )}
            </div>
            <Modal
                show={isModalOpen}
                onClose={handleModalClose}
                title='Delete Note'
            >
                <h4>Are you sure you want to delete this note?</h4>
                <p>
                    Once deleted, you won&apos;t be able to retrieve it and the
                    note will have to be re-created.
                    <br />
                </p>
                {!note.archived && (
                    <p>
                        You can also <a onClick={handleArchive}>archive</a> this
                        note.{' '}
                    </p>
                )}
                <div className='modalFooter'>
                    <Button
                        buttonCategory='icon'
                        buttonDisabled={isOptionsLoading}
                        onClick={handleDeleteClick}
                    >
                        {isOptionsLoading ? (
                            <SmallLoader />
                        ) : (
                            <FiCheck className='icon buttonIcon' />
                        )}
                    </Button>
                    <Button
                        buttonCategory='iconAlternate'
                        onClick={handleModalClose}
                        buttonDisabled={isOptionsLoading}
                    >
                        <FiX className='icon buttonIcon' />
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default NotesListItem;
