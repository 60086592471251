import { configureStore } from '@reduxjs/toolkit';
import wordsReducer, { WordsState } from './slices/words';
import referencesReducer, { ReferencesState } from './slices/references';
import notesReducer, { NotesState } from './slices/notes';
import usersReducer, { UsersState } from './slices/users';
import collectionsReducer, { CollectionsState } from './slices/collections';
import activityReducer, { ActivityState } from './slices/activity';
import appReducer, { AppState } from './slices/app';
import communityReducer from './slices/community';

export type State = {
    app: AppState;
    collections: CollectionsState;
    words: WordsState;
    notes: NotesState;
    activity: ActivityState;
    references: ReferencesState;
    users: UsersState;
};

export default configureStore({
    reducer: {
        app: appReducer,
        words: wordsReducer,
        references: referencesReducer,
        notes: notesReducer,
        users: usersReducer,
        collections: collectionsReducer,
        activity: activityReducer,
        community: communityReducer
    },
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});
