import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiCheck } from 'react-icons/fi';
import { isEqual } from 'lodash';

import { thunkUpdateSettings, selectUserSettings } from 'redux/slices/users';
import { PAGES } from 'constants/appConstants';

import SectionHeader from 'shared/components/SectionHeader';
import Button from 'shared/components/Button';
import Picker from 'shared/components/Picker';

import { UserSettings } from 'types/user';
import { DefaultPageType, PageOptions } from 'types/settings';

import styles from './GeneralSettings.module.scss';

function GeneralSettings() {
    const dispatch = useDispatch();
    const { defaultPage } = useSelector(selectUserSettings);
    const [newSettings, setNewSettings] = useState({ defaultPage });
    const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

    // DEFAULT PAGE
    const defaultPageItems = PAGES.map((page) => {
        return {
            page: `/${page.toLowerCase()}` as PageOptions,
            display: page
        };
    });
    const handleDefaultPageChange = (value: DefaultPageType) => {
        setNewSettings({
            defaultPage: { page: value.page }
        });
    };

    // SAVE
    const isSaveDisabled = () => {
        return isEqual({ defaultPage }, newSettings) || isSaveLoading;
    };
    const handleSave = async () => {
        setIsSaveLoading(true);
        await dispatch(thunkUpdateSettings(newSettings as UserSettings, true));
        setIsSaveLoading(false);
    };

    useEffect(() => {
        setNewSettings({ defaultPage });
    }, [defaultPage]);

    return (
        <div className={styles.settingsContainer}>
            <SectionHeader
                componentTag='h3'
                title='General'
                renderRight={() => (
                    <Button
                        buttonDisabled={isSaveDisabled()}
                        buttonCategory='icon'
                        buttonSize='small'
                        isLoading={isSaveLoading}
                        onClick={handleSave}
                    >
                        <FiCheck className='icon buttonIcon' />
                    </Button>
                )}
            />
            <div
                className={styles.settingsItem}
                style={{ borderTop: '1px solid #1a2133', marginTop: '20px' }}
            >
                <div className={styles.settingsControls}>
                    <h4>Default page</h4>
                    <div>
                        <Picker
                            pickerItems={defaultPageItems}
                            selectedItem={newSettings.defaultPage}
                            idProperty='page'
                            onChange={handleDefaultPageChange}
                        />
                    </div>
                </div>
                <span className='notice notice__left'>
                    Determine what page you see when you log into the app.
                </span>
            </div>
        </div>
    );
}

export default React.memo(GeneralSettings);
