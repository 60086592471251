import React, { useEffect } from 'react';
import { FiXCircle } from 'react-icons/fi';

import ReactPortal from '../ReactPortal';
import SectionHeader from 'shared/components/SectionHeader';

import styles from './Modal.module.scss';

type Props = {
    title?: string;
    subTitle?: string;
    show?: boolean;
    children?: React.ReactNode;
    onClose?: () => void;
    isCloseDisabled?: boolean;
    width?: string;
};

const Modal = ({
    title,
    show,
    children,
    subTitle,
    onClose,
    isCloseDisabled,
    width
}: Props) => {
    const handleClose = () => {
        if (!isCloseDisabled) {
            onClose && onClose();
        }
    };

    useEffect(() => {
        const closeOnEscapeKey = (e: KeyboardEvent) =>
            e.key === 'Escape' ? onClose && onClose() : null;

        if (!isCloseDisabled) {
            document.body.addEventListener('keydown', closeOnEscapeKey);
        }

        return () => {
            document.body.removeEventListener('keydown', closeOnEscapeKey);
        };
    }, [onClose, isCloseDisabled]);

    if (!show) return null;

    return (
        <ReactPortal wrapperId='react-portal-modal-container'>
            <div className={styles.modalContainer} role='dialog'>
                <div className={styles.modal} style={{ width: width }}>
                    <div className={styles.modalHeader}>
                        <SectionHeader
                            componentTag='h3'
                            title={title}
                            subTitle={subTitle}
                            renderRight={() =>
                                !isCloseDisabled && (
                                    <FiXCircle
                                        className='icon actionIcon'
                                        onClick={handleClose}
                                    />
                                )
                            }
                        />
                    </div>
                    <div className={styles.modalBody}>{children}</div>
                </div>
            </div>
        </ReactPortal>
    );
};

export default Modal;
