import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoFileTrayFull } from 'react-icons/io5';
import { GiEmptyMetalBucketHandle } from 'react-icons/gi';

import { selectWords, thunkGetWords } from 'redux/slices/words';
import {
    selectCollections,
    thunkAddWordsToCollection
} from 'redux/slices/collections';
import { setShowSideBar } from 'redux/slices/app';

import Selector from 'shared/components/Selector';
import NoData from 'shared/components/NoData';
import Loader from 'shared/components/Loader';
import Button from 'shared/components/Button';

import { Word } from 'types/word';

type Props = {
    collectionID?: string;
};

const AddWordToCollection = ({ collectionID }: Props) => {
    const dispatch = useDispatch();
    const words = useSelector(selectWords);
    const collections = useSelector(selectCollections);
    const [selected, setSelected] = useState<Word[]>([]);
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const activeCollection = collections.find(
        (item) => item.collectionID === collectionID
    );
    const wordsToShow = words
        .filter((word) => {
            return !activeCollection?.words.includes(word.word);
        })
        .sort((a, b) => {
            const x = a.word;
            const y = b.word;

            if (x < y) return -1;
            if (x > y) return 1;

            return 0;
        });

    const handleOnSelected = (selected: Word[]) => {
        setSelected(selected);
    };

    const buttonDisabled = () => {
        return selected.length === 0 || isButtonLoading;
    };

    const handleSave = async () => {
        if (!buttonDisabled()) {
            setIsButtonLoading(true);

            if (activeCollection) {
                await dispatch(
                    thunkAddWordsToCollection(selected, activeCollection)
                );
            }

            setIsButtonLoading(false);
            dispatch(setShowSideBar({ show: false, type: null }));
        }
    };

    useEffect(() => {
        async function getWords() {
            await dispatch(thunkGetWords());
            setIsLoading(false);
        }

        getWords();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    return (
        <div className='addToCollectionContainer'>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {words.length === 0 ? (
                        <NoData
                            title='No words added'
                            subTitle="To add a word go to 'Words'"
                            icon={<GiEmptyMetalBucketHandle />}
                        />
                    ) : (
                        <>
                            {wordsToShow.length > 0 ? (
                                <>
                                    <div className='addToCollectionOverview'>
                                        <p>
                                            Select words to add to{' '}
                                            <span>
                                                {activeCollection?.name}
                                            </span>
                                        </p>
                                        <p>
                                            You can add{' '}
                                            <span>{wordsToShow.length} </span>{' '}
                                            {wordsToShow.length > 1
                                                ? 'words'
                                                : 'word'}{' '}
                                            to this collection
                                        </p>
                                    </div>
                                    <Selector
                                        words={wordsToShow}
                                        type='word'
                                        identifier='word'
                                        onSelected={handleOnSelected}
                                        allowSearch
                                        allowMultiple
                                    />
                                    <Button
                                        onClick={handleSave}
                                        buttonDisabled={buttonDisabled()}
                                        isLoading={isButtonLoading}
                                    >
                                        Save
                                    </Button>
                                </>
                            ) : (
                                <NoData
                                    subTitle='All your words are in this collection'
                                    icon={<IoFileTrayFull />}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default AddWordToCollection;
