export const AUTH = '/auth';
export const ACTIVITY = '/activity';
export const COMMUNITY = '/community';
export const WORDS = '/words';
export const NOTES = '/notes';
export const ACCOUNT = '/account';
export const COLLECTIONS = '/collections';
export const SEARCH = '/search';
export const LANDING = '/';
export const OTHER = '/*';
