import React from 'react';
import Linkify from 'react-linkify';
import { format } from 'date-fns';
import TimeAgo, { Unit, Suffix } from 'react-timeago';

export type DateFormatType =
    | 'dateOnly'
    | 'dateOnlyShort'
    | 'timeOnly'
    | 'dateAndTime'
    | 'dateAndTimeShort'
    | 'timeAgo'
    | 'timeAgoFull';

function timeAgoFormatter(value: number, unit: Unit, suffix: Suffix) {
    let newUnit;

    switch (unit) {
        case 'second':
            newUnit = 'just now';
            break;
        case 'minute':
            newUnit = 'm';
            break;
        case 'hour':
            newUnit = 'h';
            break;
        case 'day':
            newUnit = 'd';
            break;
        case 'week':
            newUnit = 'w';
            break;
        case 'month':
            newUnit = 'mo';
            break;
        case 'year':
            newUnit = 'y';
            break;
    }

    return unit === 'second' ? newUnit : `${value}${newUnit} ${suffix}`;
}

export function formatDate(date: string, type: DateFormatType) {
    let formattedDate;
    let showYear = false;
    const presentYear = new Date().getFullYear();
    const dateYear = new Date(date).getFullYear();

    if (presentYear !== dateYear) showYear = true;

    if (type === 'dateOnly') {
        formattedDate = showYear
            ? format(new Date(date), 'eee dd MMM yyyy')
            : format(new Date(date), 'eee dd MMM');
    } else if (type === 'dateOnlyShort') {
        formattedDate = format(new Date(date), 'dd/MM/yy');
    } else if (type === 'timeOnly') {
        formattedDate = format(new Date(date), 'hh:mmaaa');
    } else if (type === 'dateAndTime') {
        formattedDate = showYear
            ? format(new Date(date), 'eee dd MMM yyyy, hh:mmaaa')
            : format(new Date(date), 'eee dd MMM, hh:mmaaa');
    } else if (type === 'dateAndTimeShort') {
        formattedDate = format(new Date(date), 'dd/MM/yy, hh:mmaaa');
    } else if (type === 'timeAgo') {
        formattedDate = <TimeAgo date={date} formatter={timeAgoFormatter} />;
    } else if (type === 'timeAgoFull') {
        formattedDate = <TimeAgo date={date} />;
    }

    return formattedDate;
}

export function detectLink(text: string) {
    const componentDecorator = (
        decoratedHref: string,
        decoratedText: string,
        key: number
    ) => (
        <a
            target='blank'
            href={decoratedHref}
            key={key}
            rel='noopener noreferrer'
        >
            {decoratedText}
        </a>
    );

    return <Linkify componentDecorator={componentDecorator}>{text}</Linkify>;
}
