import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from 'shared/components/Button';

import { firebaseSendPasswordResetEmail } from 'api/email';
import { isValidEmail } from 'helpers/authHelper';

import { firebaseErrors } from 'constants/auth.constants';

import { AuthError } from 'types/auth';

import './ForgotPassword.scss';

const ForgotPassword = () => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [apiError, setApiError] = useState('');
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors, isValid }
    } = useForm<{ email: string }>({
        mode: 'onChange'
    });
    const onSubmit = async (data: any) => {
        const { email } = data;
        setButtonLoading(true);

        try {
            await firebaseSendPasswordResetEmail(email);
            setApiError('');
            reset({ email: '' });
            toast.success('Reset link sent');
        } catch (err: any) {
            setApiError(firebaseErrors[err as keyof AuthError] || err);

            reset({ email: '' });
        } finally {
            setButtonLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Forgot Password</h2>
            <div>
                <input
                    {...register('email', {
                        required: {
                            value: true,
                            message: 'Email is required'
                        },
                        validate: (value) => isValidEmail(value)
                    })}
                    type='email'
                    placeholder='Email'
                ></input>
            </div>
            {errors.email && (
                <div className='errorMessage'>{errors.email.message}</div>
            )}
            {apiError !== '' && <div className='errorMessage'>{apiError}</div>}
            <Button
                buttonDisabled={!isValid || buttonLoading}
                buttonType='submit'
                isLoading={buttonLoading}
            >
                Reset Password
            </Button>
        </form>
    );
};

export default ForgotPassword;
