import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectWords,
  thunkGetWords,
  selectWordsLoading,
} from 'redux/slices/words';
import { thunkPageReset } from 'redux/slices/app';

import WordList from './WordList';
import Header from 'components/Header';

import './Words.scss';

const Words = () => {
  const dispatch = useDispatch();
  const words = useSelector(selectWords);
  const isLoading = useSelector(selectWordsLoading);

  useEffect(() => {
    dispatch(thunkPageReset('words'));
    async function getWords() {
      await dispatch(thunkGetWords());
    }

    getWords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <div className='wordsContainer'>
        <div className='words'>
          <div className='wordsDetails'>
            <WordList words={words} isLoading={isLoading} section='words' />
          </div>
        </div>
      </div>
    </>
  );
};

export default Words;
