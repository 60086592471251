// Resources
// https://www.wisdomgeek.com/development/web-development/react/detecting-click-outside-component-using-react-hooks/
// https://dev.to/reflexgravity/use-lodash-debouce-inside-a-functional-component-in-react-4g5j

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiXCircle } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { debounce } from 'lodash';

// import Button from 'shared/components/Button';
import SearchList from './SearchList';
import SearchResults from './SearchResults';

import {
    thunkSearchWord,
    thunkSearchWordList,
    selectSearchedWord,
    thunkGetWords
} from 'redux/slices/words';
import { selectShowSideBar, thunkPageReset } from 'redux/slices/app';

import './Search.scss';

const Search = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const searchedWord = useSelector(selectSearchedWord);
    const sideBar = useSelector(selectShowSideBar);
    const wordSearchRef = useRef<HTMLDivElement | null>(null);
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const [searchInput, setSearchInput] = useState('');
    const [showVariations, setShowVariations] = useState(() => {
        if (sideBar.data && sideBar.data.showVariations) {
            return sideBar.data.showVariations;
        }

        return false;
    });

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && searchInput.trim() !== '') {
            handleSearchClick(searchInput.trim());
        }
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchInput(value);
        handleDebounceSearch(value);
    };

    const handleSearchClick = (value: string) => {
        dispatch(thunkSearchWord(value));
        setShowVariations(true);
    };

    const handleClose = () => {
        history.goBack();
    };

    const handleExpand = () => {
        setShowVariations(!showVariations);
    };

    const handleDebounceSearch = useCallback(
        debounce(async (value) => {
            if (value.trim() !== '') {
                dispatch(thunkSearchWordList(value.trim()));
            }
        }, 500),
        []
    );

    useEffect(() => {
        return () => {
            handleDebounceSearch.cancel();
        };
    }, [handleDebounceSearch]);

    useEffect(() => {
        dispatch(thunkPageReset('search'));

        if (searchInputRef.current !== null) {
            searchInputRef.current.focus();
        }

        async function getWords() {
            await dispatch(thunkGetWords());
        }

        getWords();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='wordSearchContainer' ref={wordSearchRef}>
            <div className='wordSearchHeader'>
                <div className='wordSearch'>
                    <input
                        type='text'
                        placeholder='Search'
                        value={searchInput}
                        onKeyPress={handleKeyPress}
                        onChange={handleOnChange}
                        ref={searchInputRef}
                    ></input>
                    <AiOutlineSearch className='icon buttonIcon' />
                </div>
                <FiXCircle className='icon actionIcon' onClick={handleClose} />
            </div>
            <div className='searchItems webSearch'>
                <SearchList
                    searchInput={searchInput}
                    onSearchClick={handleSearchClick}
                />
                <SearchResults
                    searchedWord={searchedWord.word}
                    isLoading={searchedWord.isLoading}
                />
            </div>
            <div className='searchItems mobileSearch'>
                {!showVariations && (
                    <SearchList
                        searchInput={searchInput}
                        onSearchClick={handleSearchClick}
                    />
                )}
                <div className='expandContainer'>
                    <p onClick={handleExpand}>
                        {!showVariations
                            ? 'Show variations'
                            : 'Hide variations'}
                        <br />
                        {!showVariations ? (
                            <IoIosArrowDown className='icon' />
                        ) : (
                            <IoIosArrowUp className='icon' />
                        )}
                    </p>
                </div>
                {showVariations && (
                    <SearchResults
                        searchedWord={searchedWord.word}
                        isLoading={searchedWord.isLoading}
                    />
                )}
            </div>
        </div>
    );
};

export default Search;
