import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { FiXCircle } from 'react-icons/fi';
import { VscArchive } from 'react-icons/vsc';
import { AiFillStar } from 'react-icons/ai';

import WordDetails from 'views/Words/WordDetails';
import SectionHeader from 'shared/components/SectionHeader';
import AddWord from 'views/Words/AddWord';
import WordOptions from 'views/Words/WordOptions';
import NewCollection from 'views/Collections/NewCollection';
import EditCollection from 'views/Collections/EditCollection';
import AddToCollection from 'views/Collections/AddToCollection';
import AddWordToCollection from 'views/Collections/AddWordToCollection/AddWordToCollection';
import NewNote from 'views/Notes/NewNote';

import { selectActiveWord, setActiveWord } from 'redux/slices/words';
import { setShowSideBar, ShowSideBar } from 'redux/slices/app';
import {
    selectNotes,
    selectEditActive,
    setEditActive
} from 'redux/slices/notes';
import { selectUserInfo } from 'redux/slices/users';

import { Word } from 'types/word';

import styles from './SideBar.module.scss';

type Props = {
    sideBar: ShowSideBar;
};

const SideBar = ({ sideBar }: Props) => {
    const dispatch = useDispatch();
    const userInfo = useSelector(selectUserInfo);
    const notes = useSelector(selectNotes);
    const { note: noteToEdit } = useSelector(selectEditActive);
    const activeWord = useSelector(selectActiveWord);

    const { show, type, header, data } = sideBar;

    const sideBarRef = useRef<HTMLDivElement | null>(null);
    const sideBarClass = classNames(styles.sideBarContainer, {
        [styles.sideBarContainer__open]: show
    });
    const handleClose = () => {
        if (type === 'wordDetails') {
            dispatch(setActiveWord(null));
        }

        if (type === 'addNote') {
            dispatch(
                setEditActive({
                    active: false,
                    note: null
                })
            );
        }

        dispatch(setShowSideBar({ show: false, type: null }));
    };
    const renderSideBarBody = () => {
        let body;

        switch (type) {
            case 'wordDetails':
                body = <WordDetails />;
                break;
            case 'addWord':
                body = <AddWord />;
                break;
            case 'addNote':
                body = <NewNote userInfo={userInfo} notes={notes} />;
                break;
            case 'editNote':
                body = (
                    <NewNote
                        userInfo={userInfo}
                        notes={notes}
                        isEditMode
                        noteToEdit={noteToEdit}
                    />
                );
                break;
            case 'newCollection':
                body = <NewCollection />;
                break;
            case 'editCollection':
                body = <EditCollection collection={data} />;
                break;
            case 'addToCollection':
                body = activeWord && (
                    <AddToCollection activeWord={activeWord} />
                );
                break;
            case 'addWordToCollection':
                body = <AddWordToCollection collectionID={data} />;
                break;
            case 'activity':
                body = <AddWord />;
                break;
            default:
                break;
        }

        return body;
    };
    const headerRenderLeft = () => {
        if (type === 'wordDetails') {
            return (
                <WordOptions
                    word={activeWord as Word}
                    showSearch={true}
                    showAddToCollection={true}
                    showDelete={true}
                    showArchive={true}
                    showMoreOptions={true}
                />
            );
        } else {
            return null;
        }
    };
    const headerSubtitle = useCallback(() => {
        if (type === 'wordDetails' && activeWord !== null) {
            const content = [];

            if (activeWord.archived)
                content.push(<VscArchive className='icon identifierIcon' />);
            if (activeWord.favorite)
                content.push(
                    <AiFillStar
                        className='icon actionIcon__small'
                        style={{ fill: '#ffc683' }}
                    />
                );

            return (
                <span
                    style={{
                        display: 'flex',
                        marginTop: '3px'
                    }}
                >
                    {content.map((item, index) => (
                        <span key={index}>{item}</span>
                    ))}
                </span>
            );
        }
    }, [type, activeWord]);
    const sectionHeaderType = type === 'wordDetails' ? 'word' : '';

    // TODO: Fix any
    const handleClickOutside = (e: any) => {
        if (sideBarRef.current && sideBarRef.current.contains(e.target)) {
            // inside click
            return;
        }

        // outside click
        let isReactPortal = false;

        if (e.composedPath) {
            isReactPortal = e
                .composedPath()
                .some(
                    (item: HTMLElement) =>
                        item.id && item.id.includes('react-portal')
                );
        }

        // preventing closing side bar when react portal is clicked
        if (!isReactPortal) {
            // TODO: This does not fire
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={sideBarClass} ref={sideBarRef}>
            <div className={styles.sideBarHeader}>
                <SectionHeader
                    componentTag='h3'
                    subTitle={headerSubtitle()}
                    type={sectionHeaderType}
                    title={header}
                    renderLeft={headerRenderLeft}
                    renderRight={() =>
                        sideBar.enableClose && (
                            <FiXCircle
                                className='icon actionIcon'
                                onClick={handleClose}
                            />
                        )
                    }
                />
            </div>
            <div className={styles.sideBarContent}>{renderSideBarBody()}</div>
        </div>
    );
};

export default SideBar;
