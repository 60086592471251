import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { selectWords, thunkSearchWord } from 'redux/slices/words';
import { thunkShowSideBar, setShowSideBar } from 'redux/slices/app';

import * as ROUTES from 'constants/routes';

import { Word } from 'types/word';

import './Pill.scss';

type Props = {
    item: string;
    type?: string;
    onClick?: () => void;
};

const Pill = ({ item, type, onClick }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const words = useSelector(selectWords);
    const handleOpenSideBar = (e: React.MouseEvent) => {
        e.preventDefault();
        const word = {
            word: item
        } as Word;

        if (type === 'word') {
            dispatch(thunkShowSideBar(word, 'wordDetails'));
        }

        onClick && onClick();
    };
    const handleSearchWord = () => {
        history.push(ROUTES.SEARCH);
        dispatch(thunkSearchWord(item));
        dispatch(
            setShowSideBar({
                show: false,
                type: null,
                data: { showVariations: true }
            })
        );

        onClick && onClick();
    };

    if (type === 'word') {
        const wordIndex = words.findIndex((word) => word.word === item);

        return (
            <span
                className='pill wordPill'
                onClick={
                    wordIndex >= 0
                        ? (e) => handleOpenSideBar(e)
                        : handleSearchWord
                }
            >
                {item}
            </span>
        );
    }

    return <span className='pill'>{item}</span>;
};

export default Pill;
