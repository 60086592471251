import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { toast } from 'react-toastify';

import Button from 'shared/components/Button';
import ForgotPassword from '../ForgotPassword';

import { client } from 'index';

import { isValidEmail } from 'helpers/authHelper';
import { setAuthUser } from 'redux/slices/users';
import { firebaseSignInWithEmailAndPassword } from 'api/auth';
import { QUERY_GET_USER } from 'api/users';
import { handleSuccessfulAuth } from 'helpers/authHelper';
import { User, AuthUser } from 'types/user';

import './SignIn.scss';

type FormValues = {
    email: string;
    password: string;
};

const SignIn = () => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [apiError, setApiError] = useState('');
    const [showForgotPassword, setShowForgotPassword] =
        useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid, isDirty }
    } = useForm<FormValues>({ mode: 'onChange' });
    const dispatch = useDispatch();
    const history = useHistory();
    const onSubmit = async (data: FormValues) => {
        setApiError('');
        const { email, password } = data;
        setButtonLoading(true);

        try {
            const authUser = await firebaseSignInWithEmailAndPassword(
                email,
                password
            );

            dispatch(setAuthUser(authUser));

            const { data, error } = await client.query<{
                firestoreGetUser: User;
            }>({
                query: QUERY_GET_USER,
                variables: { userID: authUser.uid }
            });

            if (error) {
                toast.error('Authentication error');
            }

            if (data && data.firestoreGetUser) {
                const userInfo = data.firestoreGetUser;

                handleSuccessfulAuth(userInfo, history, dispatch);

                setApiError('');
            }
        } catch (err) {
            toast.error('Authentication error');

            reset({ email: '', password: '' });
        } finally {
            setButtonLoading(false);
        }
    };

    useEffect(() => {
        setButtonLoading(false);
    }, []);

    useEffect(() => {
        isDirty && setApiError('');
    }, [isDirty]);

    return (
        <div className='signInContainer'>
            {!showForgotPassword ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h2>Welcome back!</h2>
                    <div>
                        <input
                            {...register('email', {
                                required: {
                                    value: true,
                                    message: 'Email is required'
                                },
                                validate: (value) => isValidEmail(value)
                            })}
                            type='email'
                            placeholder='Email'
                        ></input>
                    </div>
                    {errors.email && (
                        <div className='errorMessage'>
                            {errors.email.message}
                        </div>
                    )}
                    <div>
                        <input
                            {...register('password', {
                                required: {
                                    value: true,
                                    message: 'Password is required'
                                },
                                minLength: {
                                    value: 6,
                                    message:
                                        'Password must be at least 6 characters'
                                },
                                maxLength: {
                                    value: 20,
                                    message:
                                        'Password cannot be more than 20 characters'
                                }
                            })}
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Password'
                        ></input>
                        <span className='showPasswordIcon'>
                            {showPassword ? (
                                <FiEyeOff
                                    onClick={() => setShowPassword(false)}
                                />
                            ) : (
                                <FiEye onClick={() => setShowPassword(true)} />
                            )}
                        </span>
                    </div>
                    {errors.password && (
                        <div className='errorMessage'>
                            {errors.password.message}
                        </div>
                    )}
                    {apiError !== '' && (
                        <div className='errorMessage'>{apiError}</div>
                    )}
                    <Button
                        buttonDisabled={!isValid || buttonLoading}
                        buttonType='submit'
                        isLoading={buttonLoading}
                    >
                        Log In
                    </Button>
                </form>
            ) : (
                <ForgotPassword />
            )}
            {!showForgotPassword ? (
                <p>
                    Forgot Password?&nbsp;
                    <a
                        onClick={() =>
                            setShowForgotPassword(!showForgotPassword)
                        }
                    >
                        Reset Password
                    </a>
                </p>
            ) : (
                <p>
                    Remember password?&nbsp;
                    <a
                        onClick={() =>
                            setShowForgotPassword(!showForgotPassword)
                        }
                    >
                        Sign In
                    </a>
                </p>
            )}
        </div>
    );
};

export default SignIn;
