import React from 'react';

import { formatDate } from 'helpers/helpers';

import Tooltip from '../Tooltip';

import { DateFormatType } from 'helpers/helpers';

import styles from './DateTime.module.scss';

type Props = {
    date: string;
    type: DateFormatType;
};

const DateTime = ({ date, type }: Props) => {
    if (type === 'dateAndTime') {
        return (
            <span className={styles.dateAdded}>{formatDate(date, type)}</span>
        );
    }

    return (
        <Tooltip
            content={formatDate(date, 'dateAndTime')}
            placement='bottom'
            arrow
        >
            <span className={styles.dateAdded}>{formatDate(date, type)}</span>
        </Tooltip>
    );
};

export default DateTime;
