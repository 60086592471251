import React from 'react';

import Pill from 'shared/components/Pill';
import Modal from 'shared/components/Modal';

import { Stat } from '../ActivityStats';

type Props = {
    isModalOpen?: boolean;
    onClose?: () => void;
    favorites: Stat;
};

export default function FavoritesModal({
    isModalOpen,
    onClose,
    favorites
}: Props) {
    const favoriteWords = favorites.words;
    const onPillClick = () => {
        onClose && onClose();
    };

    return (
        <Modal
            show={isModalOpen}
            onClose={onClose}
            title='Favorites'
            subTitle={`${favorites.value} words`}
        >
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {favoriteWords?.map((word) => (
                    <Pill
                        key={word.word}
                        item={word.word}
                        type='word'
                        onClick={onPillClick}
                    />
                ))}
            </div>
        </Modal>
    );
}
