/* eslint-disable no-async-promise-executor */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { client } from 'index';

import {
    apiDeleteUserCollectionImage,
    QUERY_GET_USER_COLLECTIONS,
    MUTATION_ADD_USER_COLLECTION,
    MUTATION_DELETE_USER_COLLECTION,
    MUTATION_UPDATE_USER_COLLECTION
} from 'api/collections';

import { setShowSideBar } from './app';

import { Collection } from 'types/collection';
import { Word } from 'types/word';

export type CollectionsState = {
    collections: Collection[];
    isLoading: boolean;
    activeCollection?: Collection | null;
};

type CollectionsSlice = {
    collections: CollectionsState;
};

const initialState: CollectionsState = {
    collections: [],
    isLoading: true,
    activeCollection: null
};

export const collectionsSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        setCollection: (state, action) => {
            state.collections.unshift(action.payload);
        },
        setCollections: (state, action) => {
            state.collections = action.payload;
        },
        setCollectionWords: (state, action) => {
            const {
                payload: { collectionIndex, words }
            } = action;

            state.collections[collectionIndex].words = [
                ...words,
                ...state.collections[collectionIndex].words
            ];
        },
        setActiveCollection: (state, action) => {
            state.activeCollection = action.payload;
        },
        setIsCollectionsLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    }
});

export const {
    setCollection,
    setCollections,
    setCollectionWords,
    setActiveCollection,
    setIsCollectionsLoading
} = collectionsSlice.actions;

export const thunkNewCollection =
    (newCollection: Collection) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            }
        } = state;

        try {
            const { data, errors } = await client.mutate<{
                addUserCollection: boolean;
            }>({
                mutation: MUTATION_ADD_USER_COLLECTION,
                variables: { userID, collection: newCollection }
            });

            if (errors) {
                toast.error('Error creating collection');
            }

            if (data && data.addUserCollection) {
                await dispatch(setCollection(newCollection));
                toast.success('Collection created');
            } else {
                toast.error('Error creating collection');
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkGetCollections =
    () => async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            },
            collections: { collections: stateCollections }
        } = state;

        try {
            if (stateCollections.length === 0) {
                const { data, error } = await client.query<{
                    getUserCollections: Collection[];
                }>({
                    query: QUERY_GET_USER_COLLECTIONS,
                    variables: { userID }
                });

                if (error) {
                    toast.error('Error getting collections');
                }

                if (data && data.getUserCollections) {
                    dispatch(setCollections(data.getUserCollections));
                }
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        } finally {
            dispatch(setIsCollectionsLoading(false));
        }
    };

export const thunkSetActiveCollection =
    (collectionID: string) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            collections: { collections: stateCollections }
        } = state;
        const activeCollection = stateCollections.find(
            (item: Collection) => item.collectionID === collectionID
        );

        dispatch(setActiveCollection(activeCollection));
    };

export const thunkUpdateCollection =
    (updatedCollection: Collection) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            },
            collections: { collections: stateCollections, activeCollection }
        } = state;

        try {
            const collectionToUpdateIndex = stateCollections.findIndex(
                (item: Collection) =>
                    item.collectionID === updatedCollection.collectionID
            );

            if (collectionToUpdateIndex >= 0) {
                const { data, errors } = await client.mutate<{
                    updateUserCollection: boolean;
                }>({
                    mutation: MUTATION_UPDATE_USER_COLLECTION,
                    variables: { userID, updatedCollection }
                });

                if (errors) {
                    toast.error('Error updating collection');
                }

                if (data && data.updateUserCollection) {
                    const newCollections = stateCollections.slice();
                    newCollections.splice(
                        collectionToUpdateIndex,
                        1,
                        updatedCollection
                    );

                    dispatch(setCollections(newCollections));

                    if (
                        activeCollection.collectionID ===
                        updatedCollection.collectionID
                    ) {
                        dispatch(setActiveCollection(updatedCollection));
                    }

                    toast.success('Collection saved');
                }
            } else {
                toast.error('Error updating collection');
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkAddWordToCollections =
    (collections: Collection[], word: string) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            },
            collections: { collections: stateCollections }
        } = state;

        const addWordPromise = async (collection: Collection) => {
            return new Promise(async (resolve, reject) => {
                const collectionToAddToIndex = stateCollections.findIndex(
                    (item: Collection) =>
                        item.collectionID === collection.collectionID
                );
                const alreadyHasWord = collection.words.some(
                    (item: string) => item === word
                );

                if (collectionToAddToIndex >= 0 && !alreadyHasWord) {
                    let updatedCollection = collection;
                    updatedCollection = {
                        ...collection,
                        words: [word, ...collection.words]
                    };

                    try {
                        const { data, errors } = await client.mutate<{
                            updateUserCollection: boolean;
                        }>({
                            mutation: MUTATION_UPDATE_USER_COLLECTION,
                            variables: { userID, updatedCollection }
                        });

                        if (errors) {
                            reject({
                                word,
                                added: false,
                                collectionName: collection.name
                            });
                        }

                        if (data && data.updateUserCollection) {
                            dispatch(
                                setCollectionWords({
                                    collectionIndex: collectionToAddToIndex,
                                    words: [word]
                                })
                            );
                            dispatch(
                                thunkSetActiveCollection(
                                    stateCollections[collectionToAddToIndex]
                                        .collectionID
                                )
                            );

                            resolve({
                                word,
                                added: true,
                                collectionName: collection.name
                            });
                        }
                    } catch (err) {
                        // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                        throw new Error(err);
                    }
                }
            });
        };

        const performAdds = async (): Promise<
            { word: string; added: boolean; collectioName: string }[]
        > => {
            return new Promise(async (resolve, reject) => {
                const allAdditions: any = [];
                collections.forEach((collection: any) => {
                    allAdditions.push(addWordPromise(collection));
                });

                try {
                    await Promise.all(allAdditions)
                        .then((all) => {
                            resolve(all);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                } catch (err) {
                    reject(err);
                }
            });
        };

        try {
            const added = await performAdds();

            if (added.every((item) => item.added === true)) {
                toast.success('Word added');
            } else {
                const unsuccessfulAdds = added
                    .filter((item) => item.added === false)
                    .map((item) => item.word)
                    .join(', ');
                toast.error(`Error adding these words: ${unsuccessfulAdds}`);
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkAddWordsToCollection =
    (words: Word[], collection: Collection) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            users: {
                userInfo: { userID }
            },
            collections: { collections: stateCollections }
        } = state;

        try {
            const collectionToAddToIndex = stateCollections.findIndex(
                (item: Collection) =>
                    item.collectionID === collection.collectionID
            );
            const wordsToAdd = words.map((word: Word) => word.word);

            if (collectionToAddToIndex >= 0) {
                let updatedCollection = collection;
                updatedCollection = {
                    ...collection,
                    words: [...wordsToAdd, ...collection.words]
                };

                const { data, errors } = await client.mutate<{
                    updateUserCollection: boolean;
                }>({
                    mutation: MUTATION_UPDATE_USER_COLLECTION,
                    variables: { userID, updatedCollection }
                });

                if (errors) {
                    toast.error('Error adding word');
                }

                if (data && data.updateUserCollection) {
                    dispatch(
                        setCollectionWords({
                            collectionIndex: collectionToAddToIndex,
                            words: wordsToAdd
                        })
                    );
                    dispatch(
                        thunkSetActiveCollection(
                            stateCollections[collectionToAddToIndex]
                                .collectionID
                        )
                    );
                    toast.success('Collection updated');
                }
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkDeleteCollection =
    (deletedCollection: Collection) =>
    async (dispatch: React.Dispatch<any>, getState: any) => {
        const state = getState();
        const {
            app: { showSideBar },
            users: {
                userInfo: { userID }
            },
            collections: { collections: stateCollections }
        } = state;

        try {
            const collectionToDeleteToIndex = stateCollections.findIndex(
                (item: Collection) =>
                    item.collectionID === deletedCollection.collectionID
            );

            if (collectionToDeleteToIndex >= 0) {
                const { data, errors } = await client.mutate<{
                    deleteUserCollection: boolean;
                }>({
                    mutation: MUTATION_DELETE_USER_COLLECTION,
                    variables: { userID, deletedCollection }
                });

                if (errors) {
                    toast.error('Error deleting collection');
                }

                if (data && data.deleteUserCollection) {
                    await apiDeleteUserCollectionImage(
                        userID,
                        deletedCollection.collectionID,
                        deletedCollection.collectionImage.name
                    );
                    const updatedCollections = stateCollections.filter(
                        (item: Collection) =>
                            item.collectionID !== deletedCollection.collectionID
                    );
                    dispatch(setCollections(updatedCollections));

                    if (
                        showSideBar.show &&
                        showSideBar.data.collectionID ===
                            deletedCollection.collectionID
                    ) {
                        dispatch(setShowSideBar({ show: false, type: null }));
                    }

                    toast.success('Collection deleted');
                }
            }
        } catch (err) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            toast.error(err);
        }
    };

export const thunkResetCollectionsSlice =
    () => (dispatch: React.Dispatch<any>) => {
        dispatch(setCollections([]));
        dispatch(setIsCollectionsLoading(true));
    };

// SELECTORS
export const selectCollectionsLoading = (state: CollectionsSlice): boolean =>
    state.collections.isLoading;
export const selectCollections = (state: CollectionsSlice): Collection[] =>
    state.collections.collections;
export const selectActiveCollection = (state: any): Collection | null =>
    state.collections.activeCollection;

export default collectionsSlice.reducer;
