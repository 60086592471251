import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import { Placement } from 'tippy.js';
import classNames from 'classnames';

import styles from './Tooltip.module.scss';

type Props = {
    content?: string | React.ReactNode;
    placement?: Placement;
    children?: React.ReactNode;
    type?: string;
    arrow?: boolean;
};

const Tooltip = ({
    content,
    placement = 'top',
    children,
    type,
    arrow = true
}: Props) => {
    const tooltipOffset: [number, number] =
        placement === 'top' ? [0, 10] : [0, 1];
    const tooltipClass = classNames(styles.tooltip, {
        [styles.tooltip__nav]: type === 'nav'
    });

    return (
        <Tippy
            content={content}
            placement={placement}
            animation='scale'
            arrow={arrow}
            allowHTML
            offset={tooltipOffset}
            className={tooltipClass}
        >
            {type === 'nav' ? (
                <div style={{ fontSize: '15px !important' }}>{children}</div>
            ) : (
                <>{children}</>
            )}
        </Tippy>
    );
};

export default Tooltip;
