import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FiX, FiCheck } from 'react-icons/fi';

import { thunkUpdateUserWordVariation } from 'redux/slices/words';
import { thunkUpdateUserWordReference } from 'redux/slices/references';
import { wordPermissionsHelper } from 'helpers/wordsHelper';

import Button from 'shared/components/Button';
import WordPermissionsToggle from '../WordPermissionsToggle';

import { Reference } from 'types/reference';

import '../AddWordReference/AddWordReference.scss';

type Props = {
    reference: Reference;
    onClose: () => void;
};

const EditWordReference = ({ reference, onClose }: Props) => {
    const dispatch = useDispatch();
    const [referenceText, setReferenceText] = useState('');
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [isPublic, setIsPublic] = useState<boolean>(reference.isPublic);
    const wordPermissions = wordPermissionsHelper(reference);
    const buttonDisabled = () => {
        return (
            referenceText.trim() === '' ||
            (reference.reference === referenceText &&
                isPublic === reference.isPublic)
        );
    };

    const handleAddClick = async () => {
        setButtonLoading(true);
        // if ('variationID' in reference) {
        //     const newVariation = {
        //         ...reference,
        //         reference: referenceText,
        //         isPublic: isPublic
        //     };

        //     await dispatch(thunkUpdateUserWordVariation(newVariation));
        // } else if ('referenceID' in reference) {
        const newReference = {
            ...reference,
            reference: referenceText,
            isPublic: isPublic
        };
        await dispatch(thunkUpdateUserWordReference(newReference));
        // }

        onClose();
        setButtonLoading(false);
    };

    const handleCancel = () => {
        onClose();
        setReferenceText('');
    };

    const handleWordPermissionsToggle = (permission: boolean) => {
        setIsPublic(permission);
    };

    useEffect(() => {
        setReferenceText(reference.reference);
    }, [reference]);

    return (
        <div className='addReferenceContainer'>
            <div>
                <textarea
                    placeholder='Did you remeber this experience differently?'
                    rows={5}
                    onChange={(e) => setReferenceText(e.target.value)}
                    value={referenceText}
                />
                <div className='addReferenceControls'>
                    <WordPermissionsToggle
                        onToggle={handleWordPermissionsToggle}
                        wordPermissions={wordPermissions}
                        // isDisabled={referenceText.trim().length <= 0}
                        isDisabled
                    />
                    <div className='addReferenceControlsButtons'>
                        <Button
                            onClick={handleAddClick}
                            isLoading={buttonLoading}
                            buttonDisabled={buttonDisabled()}
                            buttonCategory='icon'
                            buttonSize='small'
                        >
                            <FiCheck className='icon buttonIcon' />
                        </Button>
                        <Button
                            onClick={handleCancel}
                            buttonCategory='iconAlternate'
                            buttonSize='small'
                        >
                            <FiX className='icon buttonIcon' />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditWordReference;
