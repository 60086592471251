import React, { useState, useEffect, ReactPortal } from 'react';
import classNames from 'classnames';

import './Tabs.scss';

export type TabItem = {
    name: string;
    display?: string;
    onClick?: () => void;
    Component?: React.ReactNode;
    word?: string;
};

type Props = {
    tabItems: TabItem[];
};

const Tabs = ({ tabItems }: Props) => {
    const [activeTab, setActiveTab] = useState(tabItems[0]);
    const linkClass = (component: React.ReactNode) =>
        classNames('link', {
            link__active: activeTab.name === component
        });

    useEffect(() => {
        setActiveTab(tabItems[0]);
    }, [tabItems]);

    return (
        <div className='tabsContainer'>
            <nav className='tabsNav'>
                {tabItems
                    .filter((item) => !!item.name.length)
                    .map((item) => (
                        <span
                            key={item.name}
                            className={linkClass(item.name)}
                            onClick={() => {
                                item.onClick && item.onClick();
                                setActiveTab(item);
                            }}
                        >
                            {item?.display}
                        </span>
                    ))}
            </nav>
            <div className='tabsContent'>{activeTab?.Component}</div>
        </div>
    );
};

export default Tabs;
