import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoFileTrayFull } from 'react-icons/io5';
import { GiEmptyMetalBucketHandle } from 'react-icons/gi';

import {
    selectCollections,
    thunkGetCollections,
    thunkAddWordToCollections
} from 'redux/slices/collections';
import { selectWords } from 'redux/slices/words';

import Selector from 'shared/components/Selector';
import NoData from 'shared/components/NoData';
import Button from 'shared/components/Button';

import Loader from 'shared/components/Loader';

import { Collection } from 'types/collection';
import { Word } from 'types/word';

import './AddToCollection.scss';

type Props = {
    activeWord: Word;
};

const AddToCollection = ({ activeWord }: Props) => {
    const dispatch = useDispatch();
    const collections = useSelector(selectCollections);
    const words = useSelector(selectWords);
    const [selected, setSelected] = useState<Collection[]>([]);
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const collectionsToShow = collections
        .filter((collection: Collection) => {
            const alreadyHasWord = collection.words.some(
                (item: string) => item === activeWord.word
            );
            return !alreadyHasWord && collection;
        })
        .sort((a, b) => {
            const x = a.name;
            const y = b.name;
            if (x < y) return -1;
            if (x > y) return 1;

            return 0;
        });

    const handleOnSelected = (selected: Collection[]) => {
        setSelected(selected);
    };

    const buttonDisabled = () => {
        return selected.length === 0 || isButtonLoading;
    };

    const handleSave = async () => {
        if (!buttonDisabled()) {
            setIsButtonLoading(true);

            if (activeWord.word && selected.length) {
                await dispatch(
                    thunkAddWordToCollections(selected, activeWord.word)
                );
            }

            setIsButtonLoading(false);
        }
    };

    useEffect(() => {
        async function getCollections() {
            await dispatch(thunkGetCollections());
            setIsLoading(false);
        }

        getCollections();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='addToCollectionContainer'>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {collections.length === 0 ? (
                        <NoData
                            title='No collections added'
                            subTitle="To create a collection go to 'Collections'"
                            icon={<GiEmptyMetalBucketHandle />}
                        />
                    ) : (
                        <>
                            {collectionsToShow.length > 0 ? (
                                <>
                                    <div className='addToCollectionOverview'>
                                        <p>
                                            Select a collection to add{' '}
                                            <span>{activeWord.word}</span> to
                                        </p>
                                        <p>
                                            You can add this word to{' '}
                                            <span>
                                                {collectionsToShow.length}{' '}
                                            </span>
                                            {collectionsToShow.length > 1
                                                ? 'collections'
                                                : 'collection'}
                                        </p>
                                    </div>
                                    <Selector
                                        items={collectionsToShow}
                                        word={activeWord.word}
                                        type='collection'
                                        words={words}
                                        identifier='collectionID'
                                        onSelected={handleOnSelected}
                                        allowMultiple={false}
                                    />
                                    <Button
                                        onClick={handleSave}
                                        buttonDisabled={buttonDisabled()}
                                        isLoading={isButtonLoading}
                                    >
                                        Save
                                    </Button>
                                </>
                            ) : (
                                <NoData
                                    subTitle='This word is in all your collections'
                                    icon={<IoFileTrayFull />}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default AddToCollection;
