import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { onAuthStateChanged } from 'firebase/auth';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer } from 'react-toastify';
import { FaProductHunt } from 'react-icons/fa';

import { initSentry } from 'sentry';

import { auth, client } from 'index';

import * as ROUTES from 'constants/routes';

import {
    setAuthUser,
    setUserInfo,
    selectAuthUser,
    selectUserInfo,
    resetUsersSlice,
    thunkUpdateSettings
} from 'redux/slices/users';
import { selectShowSideBar } from 'redux/slices/app';
import { thunkResetWordsSlice } from 'redux/slices/words';
import { thunkResetActivitySlice } from 'redux/slices/activity';
import { thunkResetNotesSlice } from 'redux/slices/notes';
import { thunkResetCollectionsSlice } from 'redux/slices/collections';
import { QUERY_GET_USER } from 'api/users';
import { handleCustomTokenGeneration } from 'helpers/authHelper';
// import { unauthorizeChromeExtension } from 'extensions/chrome/chromeExtension';

import Auth from 'views/Authentication/Auth/Auth';
import Activity from 'views/Activity';
import Account from 'views/Account';
import Words from 'views/Words';
import Notes from 'views/Notes';
import Collections from 'views/Collections';
import Search from 'views/Search';
import ProtectedRoute from 'views/Authentication/ProtectedRoute';
import MobileNavigation from 'components/MobileNavigation';
import Navigation from 'components/Navigation';
import SideBar from 'components/SideBar';

import Loader from 'shared/components/Loader';
import ScrollToTop from 'shared/components/ScrollToTop';
import NotVerified from 'shared/components/NotVerified';
import Button from 'shared/components/Button';
import Modal from 'shared/components/Modal';

import { User } from 'types/user';

import './App.scss';

const App = () => {
    const dispatch = useDispatch();

    const authUser = useSelector(selectAuthUser);
    const userInfo = useSelector(selectUserInfo);
    const sideBar = useSelector(selectShowSideBar);

    const isMobile = useMediaQuery({ query: '(max-width: 750px)' });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showRoot, setShowRoot] = useState<boolean>(true);

    const handleProductHuntClick = () => {
        const links = [
            'https://www.producthunt.com/posts/rewwind',
            'https://www.producthunt.com/posts/rewwind-2'
        ];

        links.forEach((link) => window.open(link, '_blank', 'noreferrer'));
    };

    initSentry(userInfo);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
            setIsLoading(true);

            if (authUser) {
                try {
                    const { data, error } = await client.query<{
                        firestoreGetUser: User;
                    }>({
                        query: QUERY_GET_USER,
                        variables: { userID: authUser.uid }
                    });

                    if (error) {
                        // toast.error('Authentication error');
                    }

                    if (data && data.firestoreGetUser) {
                        setIsModalOpen(!authUser.emailVerified);
                        const userInfo = data.firestoreGetUser;

                        dispatch(setUserInfo(userInfo));
                        dispatch(setAuthUser(authUser));

                        if (userInfo.settings !== null) {
                            await dispatch(
                                thunkUpdateSettings(userInfo.settings)
                            );
                        }

                        setIsLoading(false);

                        handleCustomTokenGeneration(authUser.uid);
                    }
                } catch (err) {
                    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                    toast.error(err);
                }
            } else {
                dispatch(resetUsersSlice());
                dispatch(thunkResetWordsSlice());
                dispatch(thunkResetActivitySlice());
                dispatch(thunkResetNotesSlice());
                dispatch(thunkResetCollectionsSlice());
                setIsLoading(false);
                setShowRoot(false);

                // TODO: Fix this
                // unauthorizeChromeExtension();
            }
        });

        return () => {
            unsubscribe();
            setIsLoading(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     if (authUser && userInfo) {
    //         setShowRoot(true);

    //         return;
    //     }

    //     setShowRoot(false);
    // }, [authUser, userInfo]);

    if (isLoading) {
        return <Loader appLoader={true} />;
    }

    return (
        <Router>
            <ScrollToTop />
            <Route exact path={ROUTES.AUTH}>
                <Auth />
            </Route>
            {/* {showRoot && ( */}
            <>
                {sideBar.show && <div className='rootBackdrop'></div>}
                {authUser && userInfo && (
                    <>
                        <MobileNavigation />
                        <Navigation />
                    </>
                )}
                <main className='rootBody'>
                    <div className='routes'>
                        <div className='routesBody'>
                            <Switch>
                                <Route exact path={ROUTES.LANDING}>
                                    <ProtectedRoute isLoading={isLoading}>
                                        {authUser && userInfo ? (
                                            <Redirect
                                                to={{
                                                    pathname:
                                                        userInfo.settings &&
                                                        userInfo.settings
                                                            .defaultPage
                                                            ? userInfo.settings
                                                                  .defaultPage
                                                                  .page
                                                            : ROUTES.WORDS
                                                }}
                                            />
                                        ) : (
                                            <Redirect to={ROUTES.WORDS} />
                                        )}
                                    </ProtectedRoute>
                                </Route>
                                <Route path={ROUTES.ACTIVITY}>
                                    <ProtectedRoute isLoading={isLoading}>
                                        <Activity />
                                    </ProtectedRoute>
                                </Route>
                                <Route path={ROUTES.WORDS}>
                                    <ProtectedRoute isLoading={isLoading}>
                                        <Words />
                                    </ProtectedRoute>
                                </Route>
                                <Route path={ROUTES.COLLECTIONS}>
                                    <ProtectedRoute isLoading={isLoading}>
                                        <Collections />
                                    </ProtectedRoute>
                                </Route>
                                <Route path={ROUTES.NOTES}>
                                    <ProtectedRoute isLoading={isLoading}>
                                        <Notes />
                                    </ProtectedRoute>
                                </Route>
                                <Route path={ROUTES.ACCOUNT}>
                                    <ProtectedRoute isLoading={isLoading}>
                                        <Account />
                                    </ProtectedRoute>
                                </Route>
                                <Route path={ROUTES.SEARCH}>
                                    <ProtectedRoute isLoading={isLoading}>
                                        <Search />
                                    </ProtectedRoute>
                                </Route>
                                <Route path='*'>
                                    <ProtectedRoute isLoading={isLoading}>
                                        {authUser && userInfo ? (
                                            <Redirect
                                                to={{
                                                    pathname:
                                                        userInfo.settings &&
                                                        userInfo.settings
                                                            .defaultPage
                                                            ? userInfo.settings
                                                                  .defaultPage
                                                                  .page
                                                            : ROUTES.WORDS
                                                }}
                                            />
                                        ) : (
                                            <Redirect to={ROUTES.WORDS} />
                                        )}
                                    </ProtectedRoute>
                                </Route>
                            </Switch>

                            <div className='buyMeCoffee'>
                                <Button
                                    buttonCategory='iconAlternate'
                                    onClick={() =>
                                        window.open(
                                            'https://www.buymeacoffee.com/rewwind.co'
                                        )
                                    }
                                >
                                    <span className='emoji'>
                                        &#128077;&#127998;
                                    </span>
                                </Button>
                                <Button
                                    buttonCategory='iconAlternate'
                                    onClick={handleProductHuntClick}
                                >
                                    <FaProductHunt className='productHuntIcon' />
                                </Button>
                            </div>
                        </div>
                    </div>
                </main>
                <SideBar sideBar={sideBar} />
                <ToastContainer
                    position={isMobile ? 'bottom-left' : 'bottom-center'}
                    autoClose={3000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                />
                <Modal
                    show={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    title='Verify email'
                >
                    {userInfo && <NotVerified userInfo={userInfo} />}
                </Modal>
            </>
            {/* )} */}
        </Router>
    );
};

export default App;
