import React, { useState } from 'react';

import styles from './Slider.module.scss';

type Props = {
    min?: number;
    max?: number;
    value?: number;
    onChange?: (value: number) => void;
};

export default function Slider({ min, max, value, onChange }: Props) {
    const [val, setVal] = useState(value);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = parseInt(e.target.value, 10);
        setVal(newVal);
        onChange && onChange(newVal);
    };

    return (
        <div className={styles.sliderContainer}>
            <input
                type='range'
                min={min}
                max={max}
                value={val}
                className={styles.slider}
                onChange={(e) => handleChange(e)}
            ></input>
            <span>{val}</span>
        </div>
    );
}
