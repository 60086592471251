import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    getAdditionalUserInfo,
    GoogleAuthProvider,
    TwitterAuthProvider,
    signInWithPopup,
    UserCredential
} from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { gql } from '@apollo/client';

import { db, auth } from '../index';
import { firebaseSendEmailVerificationLink } from './email';
import { handleCustomTokenGeneration } from '../helpers/authHelper';
// import { unauthorizeChromeExtension } from '../extensions/chrome/chromeExtension';

import { AuthError } from 'firebase/auth';
import { AuthUser } from 'types/user';

export const QUERY_GET_USER_CUSTOM_TOKEN = gql`
    query getUserCustomTokenQuery($userID: ID!) {
        firebaseGetCustomToken(userID: $userID)
    }
`;

/**
 * Create new user with email and password
 * @param {String} email - New user's email
 * @param {String} password - New user's password
 * @param {String} firstName - New user's first name
 * @param {String} lastName - New user's last name
 * @returns {Promise} - Resolves with new user object
 */
export async function firebaseCreateUserWithEmailAndPassword(
    email: string,
    password: string,
    firstName: string,
    lastName: string
): Promise<AuthUser> {
    return new Promise((resolve, reject) => {
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (data) => {
                try {
                    await setDoc(doc(db, 'users', data.user.uid), {
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        dateCreated: new Date().toISOString(),
                        userID: data.user.uid,
                        username: '',
                        photoURL: '',
                        bio: '',
                        emailVerified: data.user.emailVerified
                    });

                    if (!data.user.emailVerified) {
                        await firebaseSendEmailVerificationLink();
                    }

                    handleCustomTokenGeneration(data.user.uid);
                    resolve(data.user);
                } catch (error) {
                    reject(error);
                }
            })
            .catch((error) => {
                reject(error.code);
            });
    });
}

export async function firebaseSignInWithEmailAndPassword(
    email: string,
    password: string
): Promise<AuthUser> {
    return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(auth, email, password)
            .then(async (data) => {
                handleCustomTokenGeneration(data.user.uid);
                resolve(data.user);
            })
            .catch((error) => {
                reject(error.message);
            });
    });
}

/**
 * Create new user with Google auth otherwise, signs exsiting user in
 * @returns {Promise} - Resolves with new user object
 */
export async function firebaseSignInWithGoogle(): Promise<UserCredential> {
    return new Promise((resolve, reject) => {
        const provider = new GoogleAuthProvider();

        signInWithPopup(auth, provider)
            .then(async (data: UserCredential) => {
                const additionalUserInfo = getAdditionalUserInfo(data);

                if (additionalUserInfo !== null) {
                    const { isNewUser, profile } = additionalUserInfo;

                    if (isNewUser === true) {
                        try {
                            await setDoc(doc(db, 'users', data.user.uid), {
                                firstName: profile?.given_name,
                                lastName: profile?.family_name,
                                email: profile?.email,
                                dateCreated: new Date().toISOString(),
                                userID: data.user.uid,
                                username: '',
                                photoURL: '',
                                bio: '',
                                emailVerified: data.user.emailVerified
                            });

                            handleCustomTokenGeneration(data.user.uid);
                            resolve(data);
                        } catch (error) {
                            reject(error);
                        }
                    } else {
                        handleCustomTokenGeneration(data.user.uid);
                        resolve(data);
                    }
                }
            })
            .catch((error: AuthError) => {
                reject(error.message);
            });
    });
}

/**
 * Create new user with Twitter auth otherwise, signs exsiting user in
 * @returns {Promise} - Resolves with new user object
 */
export async function firebaseSignInWithTwitter(): Promise<UserCredential> {
    return new Promise((resolve, reject) => {
        const provider = new TwitterAuthProvider();

        signInWithPopup(auth, provider)
            .then(async (data: UserCredential) => {
                const additionalUserInfo = getAdditionalUserInfo(data);

                if (additionalUserInfo !== null) {
                    const { isNewUser, profile } = additionalUserInfo;

                    if (isNewUser === true) {
                        try {
                            await setDoc(doc(db, 'users', data.user.uid), {
                                firstName: profile?.screen_name,
                                lastName: '',
                                email: '',
                                dateCreated: new Date().toISOString(),
                                userID: data.user.uid,
                                username: '',
                                photoURL: profile?.profile_image_url_https,
                                bio: '',
                                emailVerified: data.user.emailVerified
                            });

                            handleCustomTokenGeneration(data.user.uid);
                            resolve(data);
                        } catch (error) {
                            reject(error);
                        }
                    } else {
                        handleCustomTokenGeneration(data.user.uid);
                        resolve(data);
                    }
                }
            })
            .catch((error: AuthError) => {
                reject(error.message);
            });
    });
}

export async function firebaseSignOut() {
    return new Promise((resolve, reject) => {
        signOut(auth)
            .then(() => {
                // TODO: Fix this
                // unauthorizeChromeExtension();
                resolve(true);
            })
            .catch((error: AuthError) => {
                reject(error.message);
            });
    });
}

export const MUTATION_UPDATE_USER_PASSWORD = gql`
    mutation updateUserPasswordQuery($userID: ID!, $password: String!) {
        firebaseUpdateUserPassword(userID: $userID, password: $password)
    }
`;
