import React from 'react';

import './NoData.scss';

type Props = {
  title?: string;
  subTitle?: string;
  icon?: React.ReactNode;
};

const NoData = ({ title, subTitle, icon }: Props) => {
  return (
    <div className='noDataContainer'>
      {icon}
      <h1>{title}</h1>
      <p>{subTitle}</p>
    </div>
  );
};

export default NoData;
