import React, { useState, useEffect, useMemo } from 'react';
import uniqid from 'uniqid';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { AiOutlineSave } from 'react-icons/ai';
// import { FiXCircle, FiMaximize, FiMinimize } from 'react-icons/fi';
import ReactQuill from 'react-quill';
import { isEqual } from 'lodash';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import {
    thunkAddNote,
    setEditActive,
    thunkUpdateUserNote
} from 'redux/slices/notes';
import { selectUserVerified, selectUserSettings } from 'redux/slices/users';
import { setShowSideBar } from 'redux/slices/app';

import Button from 'shared/components/Button';
import NotVerified from 'shared/components/NotVerified';
import SmallLoader from 'shared/components/SmallLoader';

import { Note } from 'types/note';
import { User } from 'types/user';

import './NewNote.scss';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

type Props = {
    userInfo: User | null;
    notes: Note[];
    isEditMode?: boolean;
    noteToEdit?: Note | null;
};

type FormValues = {
    title: string;
    content: string;
};

interface SaveButtonProps {
    isLoading: boolean;
    isDisabled: boolean;
}

const SaveButton = ({
    isLoading,
    isDisabled
}: SaveButtonProps): JSX.Element => {
    return (
        <div className='saveButtonControls'>
            <Button
                buttonType='submit'
                buttonCategory='icon'
                buttonDisabled={isDisabled}
            >
                {isLoading ? (
                    <SmallLoader />
                ) : (
                    <AiOutlineSave className='icon buttonIcon' />
                )}
            </Button>
        </div>
    );
};

const NewNote = ({ userInfo, notes, isEditMode, noteToEdit }: Props) => {
    const dispatch = useDispatch();
    const userVerifed = useSelector(selectUserVerified);

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [isMaximized, setIsMaximized] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { isValid }
    } = useForm<FormValues>({
        mode: 'onChange'
    });
    const watchedTitle = watch('title');
    const watchedContent = watch('content');

    const modules = useMemo(
        () => ({
            toolbar: [
                [{ header: '1' }, { header: '2' }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { indent: '-1' },
                    { indent: '+1' }
                ],
                ['link', 'clean']
            ]
        }),
        []
    );

    const formats = useMemo(
        () => [
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link'
        ],
        []
    );

    const onSubmit = async () => {
        setButtonLoading(true);

        if (isEditMode && noteToEdit && noteToEdit !== null) {
            const updatedNote: Note = {
                ...noteToEdit,
                title: watchedTitle,
                content: watchedContent,
                wordCount: watchedContent.replace(/ /g, '').length,
                dateAdded: new Date().toISOString()
            };

            // Can't compare not objects becauce dateAdded has changed
            if (
                !isEqual(noteToEdit.title, updatedNote.title) ||
                !isEqual(noteToEdit.content, updatedNote.content)
            ) {
                await dispatch(
                    thunkUpdateUserNote(noteToEdit.noteID, {
                        ...updatedNote
                    })
                );
            }

            setButtonLoading(false);
            reset({ title: '', content: '' });
            dispatch(setEditActive({ active: false, note: null }));
            dispatch(setShowSideBar({ show: false, type: null }));

            return;
        }

        const newNoteID = uniqid(
            `${userInfo?.userID}-`,
            `-n${notes.length + 1}`
        );

        const newNote: Note = {
            noteID: newNoteID,
            title: watchedTitle,
            content: watchedContent,
            wordCount: watchedContent.replace(/ /g, '').length,
            dateAdded: new Date().toISOString(),
            archived: false,
            pinned: false,
            locked: false
        };

        // setTimeout(() => {
        await dispatch(thunkAddNote(newNote));
        setButtonLoading(false);
        reset({ title: '', content: '' });
        // }, 500);
    };

    // const handleMaximizeClick = () => {
    //     isMaximized ? setIsMaximized(false) : setIsMaximized(true);
    // };

    // const handleNoteClose = () => {
    //     onClose();
    //     setIsMaximized(false);
    // };

    useEffect(() => {
        register('content', {
            required: {
                value: true,
                message: 'Some content is required'
            },
            validate: (value) => {
                if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
                    return false;
                }

                return true;
            }
        });
    }, [register]);

    useEffect(() => {
        if (isEditMode && noteToEdit && noteToEdit !== null) {
            setValue('title', noteToEdit.title);
            setValue('content', noteToEdit.content);

            return;
        }

        setValue('title', '', { shouldValidate: false });
        setValue('content', '', { shouldValidate: false });
    }, [isEditMode, noteToEdit]);

    // useEffect(() => {
    //     if (!isMaximized) {
    //         document.body.style.overflow = 'auto';
    //     } else {
    //         document.body.style.overflow = 'hidden';
    //     }

    //     return () => {
    //         document.body.style.overflow = 'auto';
    //     };
    // }, [isMaximized]);

    return (
        <div className='noteContainer'>
            {!userVerifed ? (
                <NotVerified userInfo={userInfo} isLinkOnly={false} />
            ) : (
                <>
                    <form
                        className='addNoteForm'
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className='formHeader'>
                            <div className='formControls'>
                                <SaveButton
                                    isLoading={buttonLoading}
                                    isDisabled={!isValid}
                                />
                                {/* {!isMobile && (
                                    <div className='subControls'>
                                        <Button
                                            buttonCategory='icon'
                                            buttonSize='small'
                                            onClick={handleMaximizeClick}
                                        >
                                            {isMaximized ? (
                                                <FiMinimize />
                                            ) : (
                                                <FiMaximize />
                                            )}
                                        </Button>
                                    </div>
                                )} */}
                            </div>
                        </div>
                        <div className='noteTitle'>
                            <input
                                {...register('title', {
                                    required: {
                                        value: true,
                                        message: 'A title is required'
                                    }
                                })}
                                type='text'
                                placeholder='Title'
                                onChange={(e) => {
                                    setValue('title', e.target.value, {
                                        shouldValidate: true
                                    });
                                }}
                                disabled={buttonLoading}
                                autoComplete='off'
                            ></input>
                        </div>
                        {/* {errors.title && (
                            <div className='errorMessage'>
                                {errors.title.message}
                            </div>
                        )} */}
                        <div className='noteContent'>
                            <ReactQuill
                                theme='snow'
                                style={{
                                    color: '#f5f5f5'
                                }}
                                placeholder='Start typing'
                                value={watchedContent}
                                onChange={(value) => {
                                    setValue('content', value, {
                                        shouldValidate: true
                                    });
                                }}
                                bounds={'.noteContent'}
                                modules={modules}
                                formats={formats}
                            />
                            {/* {errors.content && (
                                <div className='errorMessage'>
                                    {errors.content.message}
                                </div>
                            )} */}
                            {/* {errors.content &&
                                errors.content.type === 'validate' && (
                                    <div className='errorMessage'>
                                        Some content is required
                                    </div>
                                )} */}
                        </div>
                    </form>
                </>
            )}
        </div>
    );
};

export default NewNote;
