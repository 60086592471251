import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GiStabbedNote } from 'react-icons/gi';

import {
    selectNotesLoading,
    selectNotes,
    thunkGetNotes
} from 'redux/slices/notes';
import { thunkPageReset } from 'redux/slices/app';

import NotesList from './NotesList';
import Loader from 'shared/components/Loader';
import NoData from 'shared/components/NoData';
import Header from 'components/Header';

import './Notes.scss';

const Notes = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectNotesLoading);
    const notes = useSelector(selectNotes);

    useEffect(() => {
        dispatch(thunkPageReset('notes'));
        dispatch(thunkGetNotes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            {isLoading ? (
                <div className='notesLoaderContainer'>
                    <Loader />
                </div>
            ) : (
                <div className='notesWrapper'>
                    <div className='notesBody'>
                        {notes.length > 0 ? (
                            <NotesList notes={notes} />
                        ) : (
                            <NoData
                                title='No notes added'
                                subTitle='To get started, add a title and get typing!'
                                icon={<GiStabbedNote />}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Notes;
