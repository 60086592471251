import 'firebase/auth';
import 'firebase/firestore';
import { sendEmailVerification, sendPasswordResetEmail } from 'firebase/auth';
import { gql } from '@apollo/client';

import { auth } from 'index';

export async function firebaseSendEmailVerificationLink() {
    return new Promise((resolve, reject) => {
        if (auth.currentUser !== null) {
            sendEmailVerification(auth.currentUser)
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        }
    });
}

export async function firebaseSendPasswordResetEmail(email: string) {
    return new Promise((resolve, reject) => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                resolve(true);
            })
            .catch((error) => {
                reject(error.code);
            });
    });
}

export const MUTATION_UPDATE_USER_EMAIL = gql`
    mutation updateUserEmailQuery($userID: ID!, $email: String!) {
        firebaseUpdateUserEmail(userID: $userID, email: $email)
    }
`;
