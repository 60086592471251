import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import './Picker.scss';

type Props<T> = {
    pickerItems: T[];
    selectedItem: T;
    isDisabled?: boolean;
    idProperty?: string;
    displayProperty?: string;
    isEmoji?: boolean;
    onChange?: (item: T) => void;
    children?: React.ReactNode;
};

type PickerItemCommonProps = {
    onClick?: () => void;
    display: string;
};

const Picker = <
    T extends Record<string, unknown> & Partial<PickerItemCommonProps>
>({
    pickerItems,
    selectedItem,
    idProperty = 'id',
    displayProperty = 'display',
    isEmoji,
    onChange,
    isDisabled,
    children
}: Props<T>) => {
    const [activeTabPicker, setActivePicker] = useState<T>(selectedItem);
    const pickerContainerClass = classNames('pickerContainer', {
        pickerContainer__disabled: isDisabled
    });
    const pickerItemClass = (pickerItem: T) =>
        classNames('pickerItem', isEmoji && 'emoji', {
            pickerItem__active:
                activeTabPicker[idProperty] === pickerItem[idProperty]
        });
    const handleClick = (item: T) => {
        if (!isDisabled) {
            if (item[idProperty] !== activeTabPicker[idProperty]) {
                setActivePicker(item);
                item.onClick && item.onClick()!;
                onChange && onChange(item);
            }
        }
    };

    useEffect(() => {
        setActivePicker(selectedItem);
    }, [selectedItem]);

    return (
        <>
            <div className={pickerContainerClass}>
                {pickerItems.map((item) => (
                    <span
                        key={item[idProperty] as string}
                        className={pickerItemClass(item)}
                        onClick={() => handleClick(item)}
                    >
                        {item[displayProperty] as string}
                    </span>
                ))}
            </div>
            {children}
        </>
    );
};

export default Picker;
