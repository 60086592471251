import React from 'react';
import { FiUserPlus } from 'react-icons/fi';
import { GiLinkedRings } from 'react-icons/gi';
import { AiFillStar, AiOutlineNumber } from 'react-icons/ai';

import { Variation, Word } from 'types/word';
import { Reference } from 'types/reference';

import Tooltip from 'shared/components/Tooltip';

type Props = {
    variation?: Variation;
    reference?: Reference;
    word?: Word;
};

const WordVariationIdentifier = ({ variation, reference, word }: Props) => {
    return (
        <>
            {variation && (
                <>
                    <Tooltip content='Default variation' placement='top'>
                        <span>
                            {variation.defaultVariation && (
                                <AiOutlineNumber
                                    className='icon identifierIcon'
                                    title='Default variation'
                                />
                            )}
                        </span>
                    </Tooltip>
                    <Tooltip content='Manualy added' placement='top'>
                        <span>
                            {variation.addedManually && (
                                <FiUserPlus
                                    className='icon identifierIcon'
                                    title='Manually added'
                                />
                            )}
                        </span>
                    </Tooltip>
                </>
            )}
            {reference && (
                <Tooltip content='Visible by the community' placement='top'>
                    <span>
                        {reference.isPublic && (
                            <GiLinkedRings
                                className='icon identifierIcon'
                                title='Visible by the community'
                            />
                        )}
                    </span>
                </Tooltip>
            )}
            {word && (
                <Tooltip content='Favorite' placement='top'>
                    <span>
                        {word.favorite && (
                            <AiFillStar
                                className='icon actionIcon__small'
                                style={{ fill: '#ffc683', stroke: '#ffc683' }}
                            />
                        )}
                    </span>
                </Tooltip>
            )}
        </>
    );
};

export default WordVariationIdentifier;
